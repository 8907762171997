import { Component, OnInit } from '@angular/core';
import {ParamMap} from '@angular/router';
import {timer} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {OrderInfo, OrderItemInfo, SocketMessage} from 'src/app/models/models';
import {Commanda, Order} from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';


//declare function renderPaypalButton2(rest:RestService,order_id:number, paypal_button_selector:string,callback:any ):any;
declare function printHtml(bodyHtml:string,title:string):void;
declare function txt2html(string:string):string;

interface COrderInfo extends OrderInfo
{
	is_ready:boolean;
}

@Component({
	selector: 'app-view-commanda',
	templateUrl: './view-commanda.component.html',
	styleUrls: ['./view-commanda.component.css']
})

export class ViewCommandaComponent extends BaseComponent implements OnInit
{
	order_info_list:COrderInfo[] = [];
	seconds:Record<number,number> = {};
	//ready_by_order:Record<number,boolean> = {};
	commanda:Commanda | null = null;

	ngOnInit(): void
	{
		this.is_loading = true;
		console.log('Subscribing to socket');

		this.subs.sink = this.rest.updates.subscribe((message)=>
		{
			this.onNewUpdate(message);
		});

		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((paramMap:ParamMap)=>
			{
				return this.rest.commanda.get( paramMap.get('id') );
			})
		)
		.subscribe((commanda)=>
		{
			this.commanda = commanda;
			this.reloadData();
		});

		this.subs.sink = timer(1000,1000).subscribe(()=>this.updateTimes());

		this.subs.sink = timer(60000, 60000 ).subscribe(()=>{
			//console.log('Solicitado Por Minuto');
			this.reloadData();
		});
	}

	onNewUpdate(message:SocketMessage)
	{
		if( this.commanda == null )
			return;

		if( message.type == 'order' )
		{
			if( this.commanda.print_preferences == 'ONLY_DISPLAY' )
				return;

			if( message.id )
			{
				this.rest.order_info.get( message.id )
				.subscribe((order_info:OrderInfo)=>
				{
					if( this.commanda.print_preferences == 'FULL_PRINT' )
					{
						this.printAllItems(order_info);
						return;
					}

					let items = order_info.items.filter((oii)=>{
						if( this.commanda.print_preferences == 'PRINT_PARTIAL' || this.commanda.print_preferences == 'PRINT_ONLY_NEW_ITEMS' )
							return this.isOrderItemSameCommanda( oii );
						return true;
					});

					let ids = items.map((oii)=>this.commanda.id+'-'+oii.order_item.order_id+'-'+oii.order_item.id);

					this.rest.getPrintedItems(ids)
						.then((printed:Record<string,string>[])=>
						{
							let printed_ids = printed.map((p:Record<string,string>)=>p.id);

							if( printed.length < items.length )
							{
								let not_printed_items = items.filter((oii)=>{
									let key = this.commanda.id+'-'+oii.order_item.order_id+'-'+oii.order_item.id;
									return !printed_ids.includes( key );
								});

								if( this.commanda.print_preferences == 'PRINT_ONLY_NEW_ITEMS' )
								{
									this.printNewItemsInCommanda( order_info, not_printed_items );
								}
								else
								{
									this.printNewItemsInCommanda( order_info, items );
								}
							}
						})
						.catch((error)=>{

							this.showError('Ocurrio un error al obtener los items impresos');
							console.log(error);
						});
				});
			}
		}
		else if( this.commanda )
		{
			this.reloadData();
		}
	}

	printItemsButton( order_info:OrderInfo)
	{
		if( this.commanda.print_preferences == 'PRINT_PARTIAL' || this.commanda.print_preferences == 'PRINT_ONLY_NEW_ITEMS' )
		{
			this.printDeliveredItems( order_info );
		}
		else
		{
			this.printAllItems( order_info );
		}
	}

	reloadData()
	{
		if( this.commanda == null )
			return;

		let today = new Date();
		today.setHours(0);
		today.setMinutes(0);

		let str:string		= today.toISOString();
		let utcDate:Date	= new Date( str );

		let search_actives:SearchObject<Order> = this.getEmptySearch();
		search_actives.csv.status = ['ACTIVE','CLOSED'];
		search_actives.limit = 99999;
		search_actives.ge.system_activated = utcDate;

		console.log( this.commanda.order_display_preferences );

		if( this.commanda.order_display_preferences == 'COMMANDA_TYPE_ORDERS' )
		{
			search_actives.search_extra = { commanda_id: this.commanda.id };
		}
		else
		{
			search_actives.eq.store_id = this.commanda.store_id;
		}

		this.subs.sink = this.rest.order_info.search( search_actives )
		.subscribe((response)=>
		{
			let to_add_items:COrderInfo[] = [];

			response.data
				.map(i=>{return {...i,is_ready:false}})
				.forEach((order_info:COrderInfo)=>
				{
					this.rest.createStructuredItems(order_info);
					this.setOrderCommandaTypes(order_info);
					order_info.items.forEach((oi)=>{ this.seconds[oi.order_item.id] = 0 });
					order_info.is_ready = this.isOrderReady( order_info );

					let index = this.order_info_list
						.findIndex((oi:OrderInfo)=>oi.order.id == order_info.order.id);

					order_info.items.sort((a,b)=> this.orderItemInfoComparator(a, b));

					if( index == -1 )
					{
						to_add_items.push( order_info );
						this.playSound();
						return;
					}

					let old = this.order_info_list.splice( index, 1, order_info ).pop();

					if( !old )
					{
						return;
					}

					let counter = (p:number,oii:OrderItemInfo)=>{
						return p+oii.order_item.qty;
					};

					let qty_old = old.items.reduce( counter, 0);
					let qty_new = order_info.items.reduce( counter, 0 );

					if( qty_new != qty_old )
					{
						this.playSound();
					}
				});

			this.order_info_list.push(...to_add_items );


			this.order_info_list.forEach((oi:OrderInfo)=>{
				oi.items.forEach((order_item_info)=>
				{
					if( order_item_info.order_item.system_preparation_started )
					{
						if( order_item_info.order_item.system_preparation_ended )
						{
							let seconds =
								(
									order_item_info.order_item.system_preparation_ended.getTime()
									-order_item_info.order_item.system_preparation_started.getTime()
								)/1000;

							this.seconds[ order_item_info.order_item.id ] = seconds;
						}
					}
				});
			});


			this.sortOrders();
		},(error)=>this.showError(error));
	}

	orderItemInfoComparator(a:OrderItemInfo,b:OrderItemInfo)
	{
		if( a.order_item.item_group == b.order_item.item_group )
		{
			if( !a.order_item.item_option_id && b.order_item.item_option_id )
			{
				return -1;
			}

			if( a.order_item.item_option_id && !b.order_item.item_option_id )
			{
				return 1;
			}
		}

		return a.order_item.item_group > b.order_item.item_group ? 1 : -1;
	}

	isOrderReady(order_info:OrderInfo):boolean
	{
		//Old
		//if( oi.order_item.delivery_status == 'PENDING' && oi.item.commanda_type_id == this.commanda.commanda_type_id )
		return !order_info.items.some((oii:OrderItemInfo)=>
		{
			if( oii.order_item.delivery_status == 'DELIVERED' )
				return false;

			if( !this.isOrderItemSameCommanda( oii ) )
				return false;

			return oii.order_item.preparation_status == 'PENDING' || oii.order_item.preparation_status == 'IN_PREPARATION';
		});
	}

	isOrderItemSameCommanda(oii:OrderItemInfo)
	{
		return oii.item.commanda_type_id == this.commanda.commanda_type_id ||
			oii.order_item.commanda_id == this.commanda.id;
	}

	undelivery(order_info:OrderInfo)
	{
		if( this.commanda == null )
			return;

		let order_item_ids = order_info.items
			.filter((oii:OrderItemInfo)=>this.isOrderItemSameCommanda(oii))
			.map((oi)=>oi.order_item.id);

		this.subs.sink = this.rest.updateOrderItemsPreparationStatus( order_item_ids, 'PENDING')
			.subscribe(()=>
			{
				this.rest.sendNotification('order', order_info.order.id);
				this.reloadData();
			},(error)=>
			{
				this.showError(error);
			});
	}

	markOrderPrepartionStatusAsReady(order_info:OrderInfo)
	{
		if( this.commanda == null )
			return;

		let order_item_ids = order_info.items
			.filter((oii:OrderItemInfo)=>this.isOrderItemSameCommanda(oii))
			.map((oi)=>oi.order_item.id);

		this.subs.sink = this.rest.updateOrderItemsPreparationStatus( order_item_ids, 'READY')
			.subscribe(()=>
			{
				this.rest.sendNotification('order', order_info.order.id);
				this.reloadData();
			},(error)=>
			{
				this.showError(error);
			});

		////TODO
		//this.rest.updateOrderPreparationStatus( order_info.order.id, 'READY', this.commanda.commanda_type_id)
		//.toPromise()
		//.then((response)=>
		//{
		//	this.rest.sendNotification('order', order_info.order.id);
		//	this.reloadData(this.commanda_id);
		//})
		//.catch((error)=>this.showError(error));
	}

	updateTimes()
	{
		let d1 = new Date();

		this.order_info_list.forEach((order_info)=>
		{
			if( this.isOrderReady( order_info ) )
				return;

			order_info.items.forEach((order_item_info:OrderItemInfo)=>
			{
				if( order_item_info.order_item.commanda_id == null )
						return;

				if( order_item_info.order_item.preparation_status == 'READY' )
				{
					return;
				}

				if( order_item_info.order_item.system_preparation_started )
				{
					if( order_item_info.order_item.system_preparation_ended )
					{
						let seconds =
							(
								order_item_info.order_item.system_preparation_ended.getTime()
								-order_item_info.order_item.system_preparation_started.getTime()
							)/1000;

						this.seconds[ order_item_info.order_item.id ] = seconds;
					}
					else
					{
						let t = order_item_info.order_item.system_preparation_started.getTime();
						let seconds:number = (d1.getTime()-t)/1000;
						this.seconds[ order_item_info.order_item.id ] = seconds;
					}
				}
			})
		})
	}

	status_value:Record<'PENDING'|'SENT'|'DELIVERED'|'CANCELLED'|'READY_TO_PICKUP',number> = {
		'PENDING':0,
		'READY_TO_PICKUP':1,
		'SENT':1,
		'DELIVERED':1,
		'CANCELLED':3
	};

	compareOrderInfo(oi1:OrderInfo,oi2:OrderInfo):number
	{
		if( this.isOrderReady(oi1) && !this.isOrderReady(oi2) )
		{
			return 1;
		}

		if( !this.isOrderReady(oi1) && this.isOrderReady(oi2) )
			return -1;

		if( this.isOrderReady(oi1) )
		{
			return oi1.order.id < oi2.order.id ? 1 : -1;
		}

		return oi1.order.id > oi2.order.id ? 1 : -1;
	}

	sortOrders():void
	{
		this.order_info_list.sort((a:OrderInfo,b:OrderInfo)=>
		{
			let is_ready_a = this.isOrderReady(a);
			let is_ready_b = this.isOrderReady(b);

			if(is_ready_a && !is_ready_b )
			{
				return 1;
			}

			if( !is_ready_a && is_ready_b )
				return -1;

			//Si estan listo los dos, ordenar por id Desc
			if( is_ready_a && is_ready_b )
				return a.order.id < b.order.id ? 1 : -1;

			let oldesa	= this.getOldesPendingItemTime(a.items);
			let oldesb	= this.getOldesPendingItemTime(b.items);

		//	let a_order = this.status_value[ a.order.delivery_status ];
		//	let b_order = this.status_value[ b.order.delivery_status ];

		//	if( a_order != b_order )
		//		return a_order > b_order ? 1: -1;

			//return a.order.id > b.order.id ? -1 : 1;
			if( oldesa == null && oldesb == null )
			{
				let newest_a = this.getLatestOrderedItem( a.items );
				let newest_b = this.getLatestOrderedItem( b.items );

				return newest_a > newest_b ? 1 : -1;
			}

			if( oldesa == null )
				return 1;

			if( oldesb == null )
				return -1;

			return oldesb < oldesa ? 1 : -1;
		});
	}


	getLatestOrderedItem(items:OrderItemInfo[])
	{
		let asystem_preparation_ended:Date | null = null;

		items.forEach((oii:OrderItemInfo)=>
		{
			if( asystem_preparation_ended== null )
			{
				asystem_preparation_ended = oii.order_item.system_preparation_ended;
			}
			else if( asystem_preparation_ended < oii.order_item.system_preparation_ended )
			{
				asystem_preparation_ended= oii.order_item.system_preparation_ended;
			}
		});

		return asystem_preparation_ended;
	}

	getOldesPendingItemTime(items:OrderItemInfo[]):Date | null
	{
		let asystem_preparation_started:Date | null = null;

		items.forEach((oii:OrderItemInfo)=>
		{
			if( oii.order_item.delivery_status == 'DELIVERED' )
				return;

			if( asystem_preparation_started == null )
			{
				asystem_preparation_started = oii.order_item.system_preparation_started || null ;
			}
			else if(oii?.order_item?.system_preparation_started && asystem_preparation_started > oii.order_item.system_preparation_started )
			{
				asystem_preparation_started = oii.order_item.system_preparation_started;
			}
		});

		return asystem_preparation_started;
	}

	toggleCommandaStatus(oii:OrderItemInfo)
	{
		if( oii.order_item.commanda_status == 'DISMISSED' )
		{
			this.subs.sink = this.rest.updateCommandaSetOrderItemsAsPending([oii.order_item.id])
			.subscribe((_response)=>
			{
				oii.order_item.commanda_status = 'PENDING';
			},(_error)=>
			{
				this.showError('Ocurrio un error al actualizar');
			})
		}
		else
		{
			this.subs.sink = this.rest.updateCommandaSetOrderItemsAsDismissed([oii.order_item.id])
			.subscribe((_response)=>
			{
				oii.order_item.commanda_status = 'DISMISSED';
			},(_error)=>
			{
				this.showError('Ocurrio un error al actualizar');
			})
		}
	}

	reloadInfo()
	{
		this.reloadData();
	}

	isOrderItemGroupMain(oii:OrderItemInfo, item_group:number):boolean
	{
		if( oii.order_item.item_option_id )
			return false;
		return oii.order_item.item_group == item_group;
	}

	printDeliveredItems(order_info:OrderInfo)
	{
		let items = order_info.items.filter((oii:OrderItemInfo)=>
		{
			return this.isOrderItemSameCommanda( oii ) && oii.order_item.delivery_status == 'PENDING';
		})

		this.printNewItemsInCommanda(order_info,items);
	}

	printNewItemsInCommanda(order_info:OrderInfo, items:OrderItemInfo[])
	{
		this.rest.createStructuredItems(order_info);

		let oii_html:string = `<h1>Order #${order_info.order.id}</h1>
			<h2>${order_info.order.client_name}</h2>`;

		for(let oii of order_info.structured_items)
		{
			if( !items.some((oii2)=>oii.order_item.id == oii2.order_item.id) )
				continue;

			let ie = '';
			if( oii.order_item.exceptions )
			{
				ie = `<div style="border-top:1px solid #AAAAAA">
					<pre>${txt2html(oii.order_item.exceptions)}</pre>
				</div>`;
			}

			let note = '';

			let name = (oii?.category?.name) ? oii.category.name+' - ':'';

			if( this.rest.local_preferences.display_categories_on_items == 'NO' )
			{
				name = oii.item.name;
			}
			else
			{
				name += oii.item.name;
			}

			let sss = '';

			for(let oi of oii.childs)
			{
				let option = oii.options.find((o)=>o.item_option.id == oi.order_item.item_option_id);

				sss += `
					<div>
					● ${oi.order_item.item_option_qty} -
						${txt2html(option.item_option.name+' '+oi.item.name)}</td>
					</div>`;

			}

			oii_html += `
			<tr>
				<td style="vertical-align:top">
					${oii.order_item.qty}
				</td>
				<td>
					${txt2html(name)}
					${note}
					${ie}
					${sss}
				</td>
			</tr>`;
		}

		console.log('Abriendo socket aqui', oii_html);
		printHtml('<table style="width:100%">'+oii_html+'</table>','Titulo');
	}

	printAllItems(order_info:OrderInfo, force:boolean	= false )
	{
		let promise = force
			? Promise.resolve( false )
			: this.rest.isOrderPrinted(this.commanda.id+'-'+order_info.order.id);

		promise.then((is_printed:boolean)=>
		{
			if( is_printed )
				return;

			this.rest.createStructuredItems(order_info);

			let oii_html:string = `<h1>Order #${order_info.order.id}</h1>
				<h2>${order_info.order.client_name}</h2>`;

			for(let oii of order_info.structured_items)
			{
				if(oii.item.commanda_type_id == this.commanda.commanda_type_id)
				{
					let ie = '';
					if( oii.order_item.exceptions )
					{
						ie = `<div style="border-top:1px solid #000000;">
							<pre>${txt2html(oii.order_item.exceptions)}</pre>
						</div>`;
					}
	
					let note = '';
	
					if( oii.order_item.note )
					{
						note = `<div style="border-top:1px solid #000000;"><pre>${txt2html(oii.order_item.note)}</pre></div>`;
					}
	
					let name = (oii?.category?.name) ? oii.category.name+' - ':'';
	
					if( this.rest.local_preferences.display_categories_on_items == 'NO' )
					{
						name = oii.item.name;
					}
					else
					{
						name += oii.item.name;
					}
					let i=0;
					let border_class = '';
	
					let sss = '';
	
					for(let oi of oii.childs)
					{
						i++;
	
						if( i == oii.childs.length - 1 )
						{
							border_class = 'big_border';
						}
	
	
						let option = oii.options.find((o)=>o.item_option.id == oi.order_item.item_option_id);
	
						sss += `
						<div class="${border_class}">
							• ${oi.order_item.item_option_qty} -
							${txt2html(option.item_option.name+' '+oi.item.name)}
						</div>`;
					}
	
					oii_html += `
					<tr>
						<td style="vertical-align:top;border-bottom:1px solid black;border-top:1px solid">
							${oii.order_item.qty}
						</td>
						<td style="border-bottom:1px solid black; border-top: 1px solid black;">
							${txt2html(name)}
							${note}
							${ie}
							<div>${sss}</div>
						</td>
					</tr>`;
				}
			}
			console.log('Abriendo socket aqui');
			printHtml('<table style="width:100%;border-collapse:collapse;">'+oii_html+'</table>','Titulo');
		})
		.catch((err)=>
		{
			console.error(err);
		});
	}

	playSound()
	{
		var audio = new Audio('/assets/notification.mp3');
		audio.play();
	}

	setDrawFlag(order_info:OrderInfo)
	{
		this.rest.createStructuredItems(order_info);
	}

	setOrderCommandaTypes(order_info:OrderInfo)
	{
		for(let oii of	order_info.items )
		{
			oii.commanda_type_id = oii.item.commanda_type_id;
		}
	}
}

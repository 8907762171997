import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import { Store } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

interface IncomeReportSearch
{
	store_id:number | null;
	created:Date | null;
	start_str: string | null;
}

@Component({
	selector: 'app-income-type-report',
	templateUrl: './income-type-report.component.html',
	styleUrls: ['./income-type-report.component.css']
})
export class IncomeTypeReportComponent extends BaseComponent implements OnInit
{

	records:Record<string,string|number>[] = [];
	//search:SearchObject<IncomeReportSearch> = this.getEmptySearch();
	report_search:SearchObject<IncomeReportSearch> = this.getEmptySearch();
	start:string	= '';
	end:string = '';
		store_list: Store[] = [];
	type = {
		'CASH':'Efectivo',
		'CREDIT_CARD':'Tarjeta Credito',
		'DEBIT_CARD':'Tarjeta Debito',
		'TRANSFER':'Transferencia',
		'CHECK':'Cheque',
		'RETURN_DISCOUNT':'DESCUENTO',
	};

	ngOnInit(): void
	{
		this.path = '/report-income-by-type';
		this.getQueryParamObservable().pipe
		(
			mergeMap((response)=>
			{
				let query_params = response[0];
				let fields = ['store_id','created'];

				this.report_search = this.getSearch( query_params, fields , []);

				if( !this.report_search.ge.created)
				{
					let start = new Date();
					start.setDate( 1 );
					start.setHours(0,0,0,0);
					this.report_search.ge.created = start;
				}

				if( !this.report_search.le.created )
				{
					let end = new Date();
					end.setDate(1);
					end.setMonth(end.getMonth()+1);
					end.setDate( end.getDate()-1 );
					end.setHours(23,59,59,999);
					this.report_search.le.created = end;
				}

				this.report_search.search_extra['ares'] = this.rest.has_ares ? '1': null;

				this.start = Utils.getLocalMysqlStringFromDate( this.report_search.ge.created );
				this.end = Utils.getLocalMysqlStringFromDate( this.report_search.le.created );

				this.is_loading = true;

				let payload = {
						'created>~': this.report_search.ge.created ,
						'created<~': this.report_search.le.created,
						'store_id': null,
				};

				if( this.report_search.eq.store_id )
					payload.store_id = this.report_search.eq.store_id;

				payload['ares'] = this.rest.has_ares ? 1 : null;

				return forkJoin
				({
					report: this.rest.getReporte('getIncomeByType', payload ),
					store: this.rest.getStores()
				})
			})
		)
		.subscribe((response)=>
		{
			this.is_loading = false;
			this.store_list = response.store.data;
			this.records = response.report.map((i)=>{
				let store = this.store_list.find((s)=>s.id == i.store_id);
				i.store_name = store ? store.name : '';
				console.log( store );
				//i['tipo'] = i.type in this.type ? this.type[i.type] : 'OTRO';
				return i;
			});

		},(error)=>this.showError(error));
	}
	print()
	{
		this.rest.hideMenu();
		setTimeout(()=>window.print(),500);
	}
}

<div class="container-fluid">
	<div class="row align-items-end my-3">
		<div class="col">
			<h1 class="text-capitalize">Cliente</h1>
		</div>
		<div class="col-auto">
			<!-- button type="button" class="mr-2 btn btn-secondary lift" (click)="show_import=true">Importar</button-->
			<button type="button" class="mx-1 btn btn-secondary" *ngIf="rest.current_permission.reports" (click)="exportFile()">Exportar</button>
			<button type="button" class="btn btn-secondary" (click)="show_new_client=true">Agregar Cliente</button>
		</div>
	</div>

	<form (submit)="search(user_search)" name="search_client" *ngIf="!this.user_search.csv['id']">
		<div class="p-3 card my-3">
			<div class="row">
				<div class="col-6 col-md-3 form-group">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="user_search.lk.name" class="form-control">
				</div>

				<div class="col-6 col-md-3 form-group">
					<label class="">Facturación</label>
					<select name="con_rfc" [(ngModel)]="user_search.search_extra.con_rfc" class="form-control">
						<option value="" >Todos</option>
						<option value="0">Sin RFC</option>
						<option value="1">Con RFC</option>
					</select>
				</div>

				<div class="col-6 col-md-3 form-group">
					<label class="">Tipo de consumo</label>
					<select name="price_type_id" [(ngModel)]="user_search.eq.price_type_id" class="form-control">
						<option value="">Todos</option>
						<option *ngFor="let pt of price_type_list" [value]="pt.id" [selected]="pt.id==user_search.eq.price_type_id">{{pt.name}}</option>
					</select>
				</div>

				<div class="col-6 col-md-3 form-group">
					<label class="">Sucursal Origen</label>
					<select name="created_by_store_id" [ngModel]="user_search.eq.created_by_store_id" (ngModelChange)="updateCreatedStore($event)" class="form-control">
						<option value="">Todos</option>
						<option *ngFor="let store of store_list" [value]="store.id">
							{{store.name}}
						</option>
					</select>
				</div>

				<div class="col-6 col-md-3 form-group">
					<label>&nbsp;</label>
					<div>
						<button type="submit" class="w-100 btn btn-primary">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path _ngcontent-oxd-c76="" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"></path></svg>
							Buscar
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>

	<div class="card p-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="d-none d-lg-flex row align-items-center border-bottom fw-bold">
			<div class="col-4">
				<div class="row align-items-center">
					<div class="col-8 self-align-center px-2 pointer" (click)="clickOnHeader('name')">
						Nombre
					</div>
					<div class="col-4 text-end text-start p-0 pointer" (click)="clickOnHeader('balance')">
						Adeudo
					</div>
				</div>
			</div>
			<div class="col-6 d-lg-block col-lg-4">
				<div class="row">
					<div class="col-12 col-lg-4 text-end pointer" (click)="clickOnHeader('installment_expired_qty')">
						Vencidos
					</div>
					<div class="col-12 col-lg-4 text-end pointer" (click)="clickOnHeader('installment_expired_amount')">
						Saldo Vencido
					</div>
					<div class="col-12 col-lg-4 text-end">
						Fecha Vencido
					</div>
				</div>
			</div>
			<div class="col-6 d-lg-block col-lg-3 p-0">
				<div class="row">
					<div class="col-12 col-lg-6 text-lg-end pointer" (click)="clickOnHeader('updated')">
						Actualizado
					</div>
					<div class="col-12 col-lg-6 text-end">
						Agente
					</div>
				</div>
			</div>
			<div class="col-1 text-end">Acciones</div>
		</div>

		<div *ngFor="let user of user_list" class="row align-items-center border-bottom">
			<div class="col-8 col-lg-4">
				<div class="row align-items-center">
					<div class="col-12 col-lg-8 self-align-center px-0 px-md-2">
						<ng-container *ngIf="user.image_id">
							<img [src]="rest.getImagePath(user.image_id)" style="max-width:30px;max-height:30px;margin-right:20px">
						</ng-container>
						<ng-container *ngIf="!user.image_id">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 19.2c-2.5 0-4.71-1.28-6-3.2c.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2M12 5a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-3A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10c0-5.53-4.5-10-10-10Z"/></svg>
						</ng-container>
						<a [routerLink]="['/view-client',user.id]" class="fw-bold">{{user.name}}</a>
					</div>
					<div class="col-12 col-lg-4 text-lg-end text-start p-0">
						<span class="d-inline d-lg-none fw-bold">Adeudo</span>
						{{user.balance | currency:'$'}}
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-4 text-start text-lg-end">
				<div class="row">
					<div class="col-4">
						<span class="d-inline d-lg-none fw-bold">Pagos vencidos:</span>
						<span [ngClass]="{'text-danger':user.installment_expired_qty > 0}">
							{{user.installment_expired_qty}}
						</span>
					</div>
					<div class="col-4">
						<span class="d-inline d-lg-none fw-bold">Saldo vencido:</span>
						<span [ngClass]="{'text-danger':user.installment_expired_amount > 0}">
							{{user.installment_expired_amount | currency:'$'}}
						</span>
					</div>
					<div class="col-4">
						<span class="d-inline d-lg-none fw-bold">Fecha Vencido:</span>
						<span *ngIf="user.installment_expired_since" [ngClass]="{'text-danger':user.installment_expired_since != null}">
							{{user.installment_expired_since | shortDate:'date'}}
						</span>
					</div>
				</div>
			</div>
			<div class="col-12 d-lg-block col-lg-3 p-0">
				<div class="row">
					<div class="col-12 col-lg-6 text-lg-end ">
						<span class="d-inline d-lg-none fw-bold">Actualizado:</span>
						{{user.updated | shortDate:'date'}}
					</div>
					<div class="col-12 col-lg-6 text-lg-end">
						<span class="d-inline d-lg-none fw-bold">Agente:</span>
						{{user.agent_name}}
					</div>
				</div>
			</div>
			<div class="d-lg-none col-12 col-lg-5 text-end py-1">
				<a [routerLink]="['/edit-client', user.id]" class="btn btn-primary me-1">
					<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="m19.799 5.165l-2.375-1.83a1.997 1.997 0 0 0-.521-.237A2.035 2.035 0 0 0 16.336 3H9.5l.801 5h6.035c.164 0 .369-.037.566-.098s.387-.145.521-.236l2.375-1.832c.135-.091.202-.212.202-.334s-.067-.243-.201-.335zM8.5 1h-1a.5.5 0 0 0-.5.5V5H3.664c-.166 0-.37.037-.567.099c-.198.06-.387.143-.521.236L.201 7.165C.066 7.256 0 7.378 0 7.5c0 .121.066.242.201.335l2.375 1.832c.134.091.323.175.521.235c.197.061.401.098.567.098H7v8.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-17a.5.5 0 0 0-.5-.5z"/></svg>
					<span class="d-none d-lg-inline">&nbsp;Editar</span>
				</a>
				<button class="btn btn-primary me-1" *ngIf="user.balance < 0" (click)="addPayment(user.id)">
					<svg xmlns="http://www.w3.org/2000/svg" width="1.13em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 576 512"><path fill="currentColor" d="M251.1 207.1c0-11 9-20 20-20h16c11.9 0 20.9 9 20.9 20v68h4c11 0 20 9 20 20c0 11.9-9 20-20 20h-48.9c-11 0-20-8.1-20-20c0-11 9-20 20-20h4v-47.5c-8.2-1.9-16-9.9-16-20.5zM48.66 79.13C128.4 100.9 208.2 80.59 288 60.25c87-22.17 174-44.35 261-11.87c16.9 6.31 27 23.24 27 41.28V399.5c0 23.9-25.6 39.7-48.7 33.4c-79.7-21.8-159.5-1.5-239.3 18.8c-87.9 22.2-174.9 44.4-261.03 11.9C10.06 457.3 0 440.4 0 422.3V112.5c0-23.91 25.61-39.67 48.66-33.37zM127.1 416c0-35.3-27.75-64-64-64v64h64zm-64-192.9c36.25 0 64-27.8 64-64h-64v64zM512 352v-64.9c-35.3 0-64 29.6-64 64.9h64zm0-256.9h-64c0 36.2 28.7 64 64 64v-64zm-224.9 48c-53 0-96 51-96 112c0 62.8 43 112.9 96 112.9c53.9 0 96.9-50.1 96.9-112.9c0-61-43-112-96.9-112z"/></svg>
					<span class="d-none d-lg-inline">&nbsp;Abonar</span>
				</button>
				<a [routerLink]="['/list-user-orders']" *ngIf="user.balance < 0" [queryParams]="{'eq.client_user_id':user.id}" class="btn btn-primary me-1">
					<svg xmlns="http://www.w3.org/2000/svg" width="0.75em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 512"><path fill="currentColor" d="M336 64h-53.88C268.9 26.8 233.7 0 192 0s-76.9 26.8-90.1 64H48C21.5 64 0 85.48 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.48 48-48V112c0-26.52-21.5-48-48-48zM96 392c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.75 24 24s-10.7 24-24 24zm0-96c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.8 24 24s-10.7 24-24 24zm96-232c17.67 0 32 14.33 32 32s-14.33 32-32 32s-32-14.3-32-32c0-17.67 14.3-32 32-32zm112 320H176c-8.8 0-16-7.2-16-16s7.2-16 16-16h128c8.801 0 16 7.199 16 16c0 8.8-7.2 16-16 16zm0-96H176c-8.8 0-16-7.2-16-16s7.2-16 16-16h128c8.8 0 16 7.2 16 16s-7.2 16-16 16z"/></svg>
					<span class="d-none d-lg-inline">&nbsp;Pagar</span>
				</a>
				<a [routerLink]="['/list-order']" [queryParams]="{'eq.client_user_id':user.id}" class="btn btn-primary me-1">
					<svg xmlns="http://www.w3.org/2000/svg" width="0.75em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 512"><path fill="currentColor" d="M336 64h-53.88C268.9 26.8 233.7 0 192 0s-76.9 26.8-90.1 64H48C21.5 64 0 85.48 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.48 48-48V112c0-26.52-21.5-48-48-48zM96 392c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.75 24 24s-10.7 24-24 24zm0-96c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.8 24 24s-10.7 24-24 24zm96-232c17.67 0 32 14.33 32 32s-14.33 32-32 32s-32-14.3-32-32c0-17.67 14.3-32 32-32zm112 320H176c-8.8 0-16-7.2-16-16s7.2-16 16-16h128c8.801 0 16 7.199 16 16c0 8.8-7.2 16-16 16zm0-96H176c-8.8 0-16-7.2-16-16s7.2-16 16-16h128c8.8 0 16 7.2 16 16s-7.2 16-16 16z"/></svg>
					<span class="d-none d-lg-inline">&nbsp;Órdenes</span>
				</a>
				<a [routerLink]="['/list-address']" [queryParams]="{'eq.user_id':user.id}" class="btn btn-primary me-1">
					<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="m19.799 5.165l-2.375-1.83a1.997 1.997 0 0 0-.521-.237A2.035 2.035 0 0 0 16.336 3H9.5l.801 5h6.035c.164 0 .369-.037.566-.098s.387-.145.521-.236l2.375-1.832c.135-.091.202-.212.202-.334s-.067-.243-.201-.335zM8.5 1h-1a.5.5 0 0 0-.5.5V5H3.664c-.166 0-.37.037-.567.099c-.198.06-.387.143-.521.236L.201 7.165C.066 7.256 0 7.378 0 7.5c0 .121.066.242.201.335l2.375 1.832c.134.091.323.175.521.235c.197.061.401.098.567.098H7v8.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-17a.5.5 0 0 0-.5-.5z"/></svg>
					<span class="d-none d-lg-inline">&nbsp;Direcciones</span>
				</a>
				<button type="button" class="btn btn-primary" *ngIf="rest.current_permission.add_user" (click)="confirmRemoveClient(user)">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M21.5 6a1 1 0 0 1-.883.993L20.5 7h-.845l-1.231 12.52A2.75 2.75 0 0 1 15.687 22H8.313a2.75 2.75 0 0 1-2.737-2.48L4.345 7H3.5a1 1 0 0 1 0-2h5a3.5 3.5 0 1 1 7 0h5a1 1 0 0 1 1 1zm-7.25 3.25a.75.75 0 0 0-.743.648L13.5 10v7l.007.102a.75.75 0 0 0 1.486 0L15 17v-7l-.007-.102a.75.75 0 0 0-.743-.648zm-4.5 0a.75.75 0 0 0-.743.648L9 10v7l.007.102a.75.75 0 0 0 1.486 0L10.5 17v-7l-.007-.102a.75.75 0 0 0-.743-.648zM12 3.5A1.5 1.5 0 0 0 10.5 5h3A1.5 1.5 0 0 0 12 3.5z" fill="currentColor"/></g></svg>
				</button>
			</div>
			<!-- dropdown button menu on lg media -->
			<div class="d-none d-lg-block col-1 text-end">
			<div class="dropdown">
				<button class="btn btn-primary dropdown-toggle p-1" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
					Acciones
				</button>
				<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
					<li><a class="dropdown-item" [routerLink]="['/edit-client', user.id]">Editar</a></li>
					<li><button class="dropdown-item" *ngIf="user.balance < 0" (click)="addPayment(user.id)">Abonar</button></li>
					<li><a class="dropdown-item" [routerLink]="['/list-user-orders']" *ngIf="user.balance < 0" [queryParams]="{'eq.client_user_id':user.id}">Pagar</a></li>
					<li><a class="dropdown-item" [routerLink]="['/list-order']" [queryParams]="{'eq.client_user_id':user.id}">Órdenes</a></li>
					<li><a class="dropdown-item" [routerLink]="['/list-address']" [queryParams]="{'eq.user_id':user.id}">Direcciones</a></li>
					<li><button class="dropdown-item" *ngIf="rest.current_permission.add_user" (click)="confirmRemoveClient(user)">Eliminar</button></li>
				</ul>
			</div>
			</div>
		</div>
	</div>
	<app-pagination [path]="'/list-client'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
<!-- app-modal [(show)]="show_import">
	<div class="card p-3">
		<h2>Import excel</h2>
		<div class="from-group mb-3">
			<label>Excel data</label>
			<input name="file" type="file" (change)="onFileChanged($event)" accept="*/*" class="form-control">
		</div>
		<div class="row mt-3">
			<div class="col-6">
				<button type="button" class="btn btn-secondary w-100" (click)="show_import=false">Cancel</button>
			</div>
			<div class="col-6">
				<button type="button" class="btn btn-primary w-100" (click)="uploadFile()" [disabled]="file==null">Upload</button>
			</div>
		</div>
	</div>
</app-modal -->
<app-modal [show]="show_new_client" [closable]="false">
	<app-add-new-client (newClient)="onNewClient()"></app-add-new-client>
</app-modal>
<app-modal [show]="show_add_payment_to_client" [closable]="false" [biggest_posible]="true">
	<ng-container *ngIf="show_add_payment_to_client">
		<app-add-client-payment [currency_rate_list]="currency_rate_list" [order_info_list]="client_order_info_list" (onPayment)="onPayment($event)"></app-add-client-payment>
	</ng-container>
</app-modal>

import { Component, OnInit } from '@angular/core';
import {ParamMap} from '@angular/router';
import {mergeMap} from 'rxjs/operators';
import {OrderInfo, OrderItemInfo, SplitOrderRequest, SplitOrderRequestItem} from 'src/app/models/models';
import {Order} from 'src/app/models/RestModels';
import {BaseComponent} from '../base/base.component';
import { GetEmpty } from 'src/app/classes/Empties';

@Component({
	selector: 'app-split-order',
	templateUrl: './split-order.component.html',
	styleUrls: ['./split-order.component.css']
})

export class SplitOrderComponent extends BaseComponent implements OnInit
{
	order_info: OrderInfo | null;
	order_item_qty_dictionary:Record<number,number> = {};
	show_receive_payment:boolean = false;
	partial_amount:number = 0;

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap
		.pipe
		(
			mergeMap((paramMap:ParamMap)=>
			{
				this.setTitle('Partir Orden '+paramMap.get('id'));
				return this.rest.order_info.get(paramMap.get('id'));
			})
		)
		.subscribe((order_info:OrderInfo)=>
		{
			this.order_item_qty_dictionary = { };
			this.order_info = order_info;
		},(error)=>this.showError(error));
	}

	copyOrder(oi:OrderInfo):OrderInfo
	{
		let items:OrderItemInfo[] = [];

		let version_created = this.rest.getVersion();

		let order:Order = {
			cashier_user_id: oi.order.cashier_user_id,
			store_id: oi.order.store_id,
			cancellation_timestamp: null,
			currency_id: oi.order.currency_id,
			closed_timestamp: oi.order.closed_timestamp,
			sat_exchange_rate: oi.order.sat_exchange_rate,
			delivery_schedule: null,
			facturacion_timestamp: null,
			total: 0,
			tag: '',
			table_id: null,
			discount: 0,
			sat_domicilio_fiscal_receptor:'',
			sat_regimen_capital_receptor: '',
			cancellation_reason: '',
			sat_regimen_fiscal_receptor: '',
			discount_calculated: 0,
			sat_ieps: 0,
			sat_isr: 0,
			service_type: oi.order.service_type,
			system_activated: oi.order.system_activated,
			cancelled_by_user_id: null,
			period_id: null,
			subtotal: 0,
			created: oi.order.created,
			sync_id: this.rest.getSyncId(),
			price_type_id: oi.order.price_type_id,
			tax: 0,
			tax_percent: oi.order.tax_percent,
			version_created,
			version_updated: version_created
		};

		let offers = oi.offers.map((offer)=>{
			return {...offer};
		});

		let empty:OrderInfo = {
				items,
				order,
				cashier: this.rest.current_user,
				client: null,
				delivery_user: null,
				structured_items: [],
				purchase: null,
				store: oi.store,
				offers: offers
		};
		return empty;
	}

	splitOrder()
	{
		let split_order_request:SplitOrderRequest ={
			order_id: this.order_info.order.id,
			items: [] as SplitOrderRequestItem[]
		};

		let item_group_qty:Record<number,number> = {};

		this.order_info.items.forEach((oii:OrderItemInfo)=>
		{
			if(oii.order_item.id in this.order_item_qty_dictionary )
			{
				item_group_qty[ oii.order_item.item_group ] = this.order_item_qty_dictionary[ oii.order_item.id ];
			}
		});

		let split_items:OrderItemInfo[] = this.order_info.items.filter((oii:OrderItemInfo)=>
		{
			return oii.order_item.item_group in item_group_qty;
		});

		split_items.forEach((oii:OrderItemInfo)=>{
			let qty = item_group_qty[ oii.order_item.item_group ];

			split_order_request.items.push
			({
				order_item_id: oii.order_item.id,
				qty: oii.order_item.item_option_id ? qty * oii.order_item.item_option_qty : qty
			});
		});

		this.subs.sink = this.rest.splitOrder( split_order_request )
		.subscribe((new_order_id)=>
		{
			this.showSuccess('La orden se separó correctamente');
			this.router.navigate(['/pos/',new_order_id]);
		},(error)=>this.showError(error));
	}

	addSplitPayment()
	{
		let item_group_qty:Record<number,number> = {};

		this.order_info.items.forEach((oii:OrderItemInfo)=>
		{
			if(oii.order_item.id in this.order_item_qty_dictionary )
			{
				item_group_qty[ oii.order_item.item_group ] = this.order_item_qty_dictionary[ oii.order_item.id ];
			}
		});

		let partial_amount:number = 0;

		this.order_info.items.forEach((oii:OrderItemInfo)=>
		{
			if( oii.order_item.item_group in item_group_qty )
			{
				let qty_to_pay = item_group_qty[ oii.order_item.item_group ];

				if( oii.order_item.item_option_id )
				{
					let qty = qty_to_pay*oii.order_item.item_option_qty;
					partial_amount += oii.order_item.total/oii.order_item.qty*qty;
				}
				else
				{
					partial_amount += oii.order_item.total/oii.order_item.qty*qty_to_pay;
				}
			}
		});

		this.partial_amount = partial_amount;
		this.show_receive_payment = true;
	}
}

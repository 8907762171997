<div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-10">
        <h1 class="my-3">Ventas por Área de Producción</h1>
        <h3>Área de Producción: {{ production_area_id === 'null' ? 'Sin área asignada' : production_area_id }}</h3>
        <p>Rango de Fechas: {{ start }} - {{ end }}</p>
      </div>
      <div class="col-2 d-print-none text-end">
        <button type="button" class="btn btn-secondary" (click)="export()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="m2.859 2.878l12.57-1.796a.5.5 0 0 1 .571.495v20.847a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.868a1 1 0 0 1 .859-.99ZM17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3Zm-6.8 9L13 8h-2.4L9 10.286L7.4 8H5l2.8 4L5 16h2.4L9 13.714L10.6 16H13l-2.8-4Z"/>
          </svg>
        </button>
        <button type="button" class="btn btn-secondary" (click)="print()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M19 8H5c-1.66 0-3 1.34-3 3v4c0 1.1.9 2 2 2h2v2c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-2h2c1.1 0 2-.9 2-2v-4c0-1.66-1.34-3-3-3zm-4 11H9c-.55 0-1-.45-1-1v-4h8v4c0 .55-.45 1-1 1zm4-7c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1zm-2-9H7c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1z"/>
          </svg>
        </button>
      </div>
    </div>
  
    <app-loading [is_loading]="is_loading"></app-loading>
  
    <div class="card my-3" *ngIf="sales_data.length > 0">
      <div class="row fw-bold">
        <div class="col-6">Artículo</div>
        <div class="col-3 text-end">Cantidad Vendida</div>
        <div class="col-3 text-end">Total</div>
      </div>
      <div class="row border-bottom" *ngFor="let item of sales_data">
        <div class="col-6">{{item.category_name}} - {{ item.article_name }}</div>
        <div class="col-3 text-end">{{ item.total_quantity_sold | number }}</div>
        <div class="col-3 text-end">{{ item.total_price | currency }}</div>
      </div>
      <div class="row fw-bold">
        <div class="col-6">Total</div>
        <div class="col-3 text-end">{{ total_qty | number }}</div>
        <div class="col-3 text-end">{{ total_sales | currency }}</div>
      </div>
    </div>
  
    <div *ngIf="!is_loading && sales_data.length === 0">
      <p>No se encontraron datos para este rango de fechas y área de producción.</p>
    </div>
  </div>
  
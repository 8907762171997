import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';
import { ItemInfo } from 'src/app/models/models';

@Component({
  selector: 'app-view-sales-production-area',
  templateUrl: './view-sales-production-area.component.html',
  styleUrls: ['./view-sales-production-area.component.css']
})
export class ViewSalesProductionAreaComponent extends BaseComponent implements OnInit 
{
  production_area_id: string = '';
  start: string = '';
  end: string = '';
  sales_data: any[] = [];
  is_loading: boolean = false;
  total_sales: number;
  total_qty: number;

  ngOnInit() 
  {
    this.route.params.subscribe((params) => 
    {
      this.production_area_id = params['id']
    });

    this.route.queryParams.subscribe((queryParams) => 
    {
      this.start = queryParams['start'];
      this.end = queryParams['end'];

      if (this.production_area_id) 
      {
        this.is_loading = true;
        console.log('Start:', this.start);
        console.log('End:', this.end);
        console.log('Production Area ID:', this.production_area_id);
        this.rest.getReportByFileName('getItemSalesByProductionArea', { production_area_id: this.production_area_id, start: this.start, end: this.end })
          .subscribe((data) => 
          {
            this.sales_data = data;
            this.is_loading = false;
            this.total_sales = this.sales_data.reduce((a, b) => a + (b.total_price || 0), 0);
            this.total_qty = this.sales_data.reduce((a, b) => a + (b.total_quantity_sold || 0), 0);

          },
          (error) => 
          {
            this.showError('Error al cargar los datos');
            this.is_loading = false;
          }
        );
      }
    });
  }

    print()
    {
      this.rest.hideMenu();
      setTimeout(() => window.print(), 500);
    }
    
    export()
    {
      const rows = this.sales_data.map((item) => ({
        'Articulo': item.article_name,
        'Cantidad Vendida': item.total_quantity_sold,
        'Monto Total': item.total_price
      }));
  
      const headers = ['Articulo', 'Cantidad Vendida', 'Monto Total'];
      ExcelUtils.array2xlsx(rows, 'VendidosPorAreaProduccion.xlsx', headers);
    }
}
<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-8">
			<h1 class="my-3">Inventarios Total</h1>
		</div>
		<div class="col-2">
			<button type="button" class="btn btn-secondary w-100" (click)="exportFile()">
				<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path fill="currentColor" d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z"/></svg>
				<span class="d-none d-md-inline">&nbsp;Exportar</span>
			</button>
		</div>
		<div class="col-2">
			<button type="button" class="btn btn-secondary w-100" (click)="print()">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 28 28"><g fill="none"><path d="M7.004 5.765V7H6a4 4 0 0 0-4 4v7a3 3 0 0 0 3 3h2v1a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-1h2a3 3 0 0 0 3-3v-7a4 4 0 0 0-4-4h-1V5.752a2.75 2.75 0 0 0-2.754-2.75l-8.496.013a2.75 2.75 0 0 0-2.746 2.75zM19.5 5.752V7H8.504V5.765c0-.69.558-1.249 1.248-1.25l8.496-.013a1.25 1.25 0 0 1 1.252 1.25zM10 15.5h8a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 8.5 22v-5a1.5 1.5 0 0 1 1.5-1.5z" fill="currentColor"/></g></svg>
				<span class="d-none d-md-inline">&nbsp;Imprimir</span>
			</button>
		</div>
	</div>
	<div class="card py-3 px-1 px-md-3 mb-3">
		<form (submit)="doASearch($event)" ngNativeValidate>
			<div class="row">
				<div class="col-6 col-md-3">
					<div class="form-group">
						<label for="">Artículo</label>
						<input name="search_str" class="form-control" [(ngModel)]="search_str" type="text" (ngModelChange)="doASearch($event)">
					</div>
				</div>

				<div class="col-6 col-md-3">
					<div class="form-group">
						<label>Sucursal</label>
						<select name="store" [(ngModel)]="store_id" class="form-control" (ngModelChange)="onStoreChange($event)">
							<option [ngValue]="null">Todas</option>
							<option *ngFor="let store of store_list" [value]="store.id">{{store.name}}</option>
						</select>
					</div>
				</div>

				<div class="col-6 col-md-3">
					<div class="form-group">
						<label>Disponibilidad</label>
						<select name="availability" [(ngModel)]="availability" (change)="onAvailabilityChange($event)" class="form-control">
							<option value="">Todos</option>
							<option value="ON_STOCK">Solo en Inventario</option>
						</select>
					</div>
				</div>

				<!--div class="col-6 col-md-3">
					<div class="form-group">
					<label></label>
					<div>
					<button type="submit" class="btn btn-primary w-100">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path _ngcontent-oxd-c76="" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"></path></svg>
					Buscar
					</button>
					</div>
					</div>
					</div-->
			</div>
		</form>
	</div>

	<div class="card py-3 px-1 px-md-3">
		<div class="row fw-bold border-bottom d-none d-md-flex">
			<div class="col-8 col-md-4" (click)="clickOnHeader('name')">Nombre</div>
			<div class="col-2 col-md-1">Codigo</div>
			<div class="col-3 col-md-2 text-end">Sucursal</div>
			<div class="col-1 col-md-2 text-end">Cantidad</div>
			<div class="col-2 col-md-3 text-end"></div>
		</div>
		<ng-container *ngFor="let ii of stock_item_info_list">
			<div class="row align-items-center border-bottom py-1">
				<div class="col-6 col-md-4">
					<a *ngIf="rest.current_permission.add_items" [routerLink]="['/edit-item',ii.item.id]">{{ii | itemName }}</a>
					<span *ngIf="!rest.current_permission.add_items">{{ii | itemName }}</span>
				</div>
				<div class="col-4 col-md-1 tex-md-end">{{ii.item.code}}</div>
				<div class="col-8 col-md-2 text-md-end">{{ii.store.name | titlecase}}</div>
				<div class="col-4 col-md-2 text-end">
					<span class="d-md-none">Cantidad: </span>
					<b class="bigger">{{ii.qty | number}}</b>
				</div>
				<div class="col-12 col-md-3 text-end ps-0 pe-1">
					<button type="button" class="btn btn-primary my-1" *ngIf="ii.item.has_serial_number=='NO' && rest.current_permission.add_stock" (click)="showAddStock(ii)">
						Agregar
					</button>
					<button type="button" class="btn btn-primary my-1 ms-1" *ngIf="ii.item.has_serial_number=='NO' && rest.current_permission.add_stock" (click)="showRemoveStock(ii)">
						Merma
					</button>
					<button type="button" class="btn btn-primary ms-1" *ngIf=" ii.qty && ii.item.has_serial_number=='YES'" (click)="showSerialNumbers(ii)">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>
						<span class="d-none d-lg-inline">Ver</span>
					</button>
				</div>
			</div>
		</ng-container>
	</div>
</div>
<app-modal [show]="show_add_stock">
	<div class="p-3" *ngIf="selected_item_info">
		<h2>Agregar Inventario {{selected_item_info.item.name}}</h2>
		<p>Se agregara inventario al ya existente</p>
		<app-loading [is_loading]="is_loading"></app-loading>
		<form name="show_add_stock" (submit)="addStock($event)" ngNativeValidate>
			<div class="row">
				<div class="col-6">
					<label>Cantidad</label>
					<input class="form-control" type="number" name="add_stock_qty" [min]="selected_item_info.item.partial_sale == 'YES' ? 0.00001:1" [step]="selected_item_info.item.partial_sale=='YES'?'any':1" [(ngModel)]="add_stock_qty" required>
				</div>
				<div class="col-6">
					<label>Sucursal</label>
					<div>{{selected_item_info.store.name}}</div>

					<!-- <select class="form-control" name="add_stock_store_id" [(ngModel)]="add_stock_store_id" *ngIf="rest.current_permission.global_add_stock" required>
						<option value="">Seleccionar</option>
						<option *ngFor="let s of store_list" [value]="s.id">{{s.name}}</option>
					</select> -->
				</div>
				<div class="col-6">
					<label>Comentario</label>
					<input class="form-control" type="text" name="add_stock_comment" [(ngModel)]="add_stock_comment">
				</div>
			</div>
			<div class="row">
				<div class="col-6 mt-3">
					<button type="button" class="btn btn-secondary w-100" (click)="show_add_stock = false">Cerrar</button>
				</div>
				<div class="col-6 mt-3">
					<button type="submit" class="btn btn-primary w-100" [disabled]="is_loading">Agregar</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>
<app-modal [show]="show_remove_stock">
	<div class="p-3" *ngIf="selected_item_info">
		<h2>Agregar Merma {{selected_item_info.item.name}}</h2>
		<p>Se removera inventario al ya existente y ser registrara en merma</p>
		<app-loading [is_loading]="is_loading"></app-loading>
		<form name="show_remove_stock" (submit)="addMerma($event)" ngNativeValidate>
			<div class="row">
				<div class="col-6">
					<label>Cantidad</label>
					<input class="form-control" type="number" name="remove_stock_qty" [min]="selected_item_info.item.partial_sale == 'YES' ? 0.00001:1" [(ngModel)]="remove_stock_qty" required>
				</div>
				<div class="col-6">
					<label>Sucursal</label>
					<div>{{selected_item_info.store.name}}</div>

					<!-- <select class="form-control" name="add_stock_store_id" [(ngModel)]="add_stock_store_id" *ngIf="rest.current_permission.global_add_stock" required>
						<option value="">Seleccionar</option>
						<option *ngFor="let s of store_list" [value]="s.id">{{s.name}}</option>
					</select> -->
				</div>
				<div class="col-6">
					<label>Comentario</label>
					<input class="form-control" type="text" name="remove_stock_comment" [(ngModel)]="remove_stock_comment">
				</div>
			</div>
			<div class="row">
				<div class="col-6 mt-3">
					<button type="button" class="btn btn-secondary w-100" (click)="show_remove_stock = false">Cerrar</button>
				</div>
				<div class="col-6 mt-3">
					<button type="submit" class="btn btn-primary w-100" [disabled]="is_loading">Agregar</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>

<app-modal [(show)]="show_serials">
	<ng-container *ngIf="selected_item_info">
		<h2>#Serie para {{selected_item_info | itemName}}</h2>
		<table class="w-100">
			<thead>
				<tr>
					<th>#</th>
					<th>Descripcion</th>
					<th>Datos Adicionales</th>
				</tr>
			</thead>
			<tbody>
			<tr *ngFor="let si of serials_to_show">
				<td	class="font-monospace">{{si.serial.serial_number}}</td>
				<td>
					<div [innerHtml]="si.serial.description"></div>
				</td>
				<td>
					<div [innerHtml]="si.serial.additional_data"></div>
				</td>
			</tr>
			</tbody>
		</table>
	</ng-container>
</app-modal>

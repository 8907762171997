import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { Utils } from 'src/app/classes/Utils';
import { OrderInfo, PaymentInfo } from 'src/app/models/models';
import { Fund, Order, Payment, Price_Type, Store, Store_Sale_Report } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

interface Category_Resume
{
	category_name:string;
	qty:number;
	total:number;
}

interface Tax_Resume
{
	name: string;
	qty: number;
	total: number;
}

interface CustomOrderInfo extends OrderInfo
{
	article_discount:number;
	checked:boolean;
	paid_cash:boolean;
	paid_card:boolean;
	paid_transfer:boolean;
	paid_check:boolean;
	paid_other:boolean;
	price_type_name:string;
	disabled:boolean;
	credito:boolean;
	paid_at_closing: number;
	credit_at_closing: number;
}

interface PaymentsResume
{
	amount: number;
	//payment_type: string;
	transaction_type: string;
	currency_id: string;
	name: string;
	mxn_amount: number;
	count: number; //Cantidad de pagos
}

interface BoxPaymentEntries
{
	box_amount: number;
	payment_entries: PaymentEntry[];
}

interface Resume
{
	name:string;
	qty:number;
	total: number;
}

interface PaymentEntry
{
	currency_id:string;
	amount:number;
	payment_method: string;
	name:string;
}

@Component
({
	selector: 'app-create-sales-report',
	templateUrl: './create-sales-report.component.html',
	styleUrls: ['./create-sales-report.component.css']
})

export class CreateSalesReportComponent extends BaseComponent implements OnInit
{
	order_total:number = 0;
	total_facturacion: number = 0;
	order_search:SearchObject<Order> = this.getEmptySearch();
	corte_date:string = '';
	fecha_inicial: string = '';
	fecha_final: string ='';
	cashier_user_list: any[];
	price_type_list: Price_Type[] = [];
	order_info_list: CustomOrderInfo[] = [];
	store_list: Store[] = [];
	user_store: Store = GetEmpty.store();
	payment_info_list: PaymentInfo[] = [];
	all_checked: boolean = false;
	last_sort:string = '';
	is_sort_ascending:boolean = true;
	funds:Fund[] = [];
	custom_range: boolean= false;

	payment_resume_list:PaymentsResume[] = [];
	other_payment_resume_list:PaymentsResume[] = [];
	all_payments_resume_list:PaymentsResume[] = [];

	category_resume_list:Category_Resume[] = [];
	tax_resume:Tax_Resume[] = [];
	total_order_discount:number = 0;

	total_credit: number = 0;

	headers_classes = {
		'col-2'		: true,
		'col-md-2'	: true,
		'col-3'		: false,
		'col-md-3'	: false,
		'text-end'	: true
	};

	percentage_to_select: number = 100;
	other_payment_info_list: PaymentInfo[] = [];
	all_payment_info_list: PaymentInfo[] = [];
	total_credito: number = 0;
	original_payment_info_list: PaymentInfo[] = [];

	ngOnInit(): void
	{
		this.path = '/create-sales-report';

		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response)=>
			{
				this.total_order_discount = 0;
				let query_params = response[0];
				let param_maps = response[1];

				let fields = [
					'store_id','status','type',
					'paid_status','closed_timestamp'
				];

				let extra_keys:Array<string> = [
					'publico_general','advanced_search',
					'with_discounts','date','custom_range'
				];

				this.setTitle('Corte por sucursal');
				this.is_loading = true;
				this.current_page = 0;

				let order_search:SearchObject<Order> = this.getSearch(query_params, fields, extra_keys);
				order_search.eq.status ='CLOSED';
				order_search.limit = this.page_size;
				order_search.sort_order = ['id_DESC'];
				order_search.limit = 999999;

				if( !(order_search.eq.price_type_id ) )
					order_search.eq.price_type_id = null;

				if( order_search.search_extra.publico_general )
				{
					order_search.is_null = ['client_user_id']
					order_search.eq.price_type_id = 1;
				}
				else
				{
					order_search.is_null = [];
				}

				if( !query_params.has('eq.store_id') )
				{
					order_search.eq.store_id = this.rest.current_user.store_id;
				}

				if( !this.rest.current_permission.global_pos )
				{
					order_search.eq.store_id = this.rest.current_user.store_id;
				}

				this.custom_range = query_params.get('search_extra.custom_range') != null;

				let date_start = new Date();
				date_start.setHours( 0, 0, 0, 0);

				if( !order_search.search_extra.date )
				{
					order_search.search_extra.date = Utils.getLocalMysqlStringFromDate( date_start ).substring(0,10);
				}
				else
				{
					date_start = Utils.getDateFromLocalMysqlString( order_search.search_extra.date as string);
				}

				//*/
				//XXX Esto funciona
				//Por lo pronto no tiene pero despues poisblemente

				if( this.custom_range )
				{
					if( !order_search.ge.closed_timestamp )
					{
						order_search.ge.closed_timestamp= date_start;
					}

					if( !order_search.le.closed_timestamp )
					{
						let end = new Date();
						end.setTime( order_search.ge.closed_timestamp.getTime() );
						end.setHours( order_search.ge.closed_timestamp.getHours()+23,59,59 );
						order_search.le.closed_timestamp = end;
					}

					let fecha_fin = new Date();
					fecha_fin.setTime( order_search.le.closed_timestamp.getTime() );
					fecha_fin.setSeconds( 0 );

					this.fecha_inicial = Utils.getLocalMysqlStringFromDate( order_search.ge.closed_timestamp ).replace(' ','T');
					this.fecha_final = Utils.getLocalMysqlStringFromDate( fecha_fin ).replace(' ','T');
				}
				else
				{
					this.fecha_inicial = Utils.getLocalMysqlStringFromDate( date_start ).substring(0,16);

					let end = new Date();
					end.setTime( date_start.getTime() );
					end.setHours( 23,59,59 );

					order_search.ge.closed_timestamp = date_start;
					order_search.le.closed_timestamp = end;
				}
				//*/

				this.order_search = order_search;

				let payment_search =
				{
					ge:{ created: this.order_search.ge.closed_timestamp },
					le:{ created: this.order_search.le.closed_timestamp },
					eq:{ status: "ACTIVE", type:'income', store_id : null },
					limit: 9999999,
					search_extra:{}
				} as SearchObject<Payment>;


				if( this.order_search.eq.store_id )
				{
					payment_search.eq.store_id = this.order_search.eq.store_id;
				}

				if( this.rest.has_ares )
				{
					payment_search.search_extra['ares'] = 1;
				}

				let store_obs = this.store_list.length > 0
					? of({data:this.store_list, total: this.store_list.length})
					: this.rest.store.search({limit:999999, eq:{sales_enabled:1}, sort_order:['name_ASC']});


				return forkJoin
				({
					order_info: this.rest.order_info.search(this.order_search),
					payment_info: this.rest.payment_info.search(payment_search),
					store: store_obs
				})
			}),
			mergeMap((response)=>
			{
				let tmp_total = response.order_info.data.reduce((p,c)=>p+c.order.total,0);

				return forkJoin
				({
					order : of( response.order_info ),
					store : of( response.store ),
					price_type : this.rest.getPriceTypes(),
					users: this.rest.is_offline
						? of({data:[], total: 0})
						: this.rest.user.search({limit:9999, eq:{type:'USER'},sort_order:['name_ASC']}),
					order_total: this.rest.is_offline
						? of({total:tmp_total,data:[]})
						: this.rest.getOrderTotal(this.order_search),
					funds: this.rest.fund.search
					({
						ge:{ created: this.order_search.ge.closed_timestamp },
						le:{ created: this.order_search.le.closed_timestamp },
						limit: 99999
					}),
					payments: of( response.payment_info ),
				})
			}),
		)
		.subscribe((responses)=>
		{

			this.original_payment_info_list = responses.payments.data;

			this.headers_classes['col-md-2'] = !!(this.rest.has_ares );
			this.headers_classes['col-md-3'] = !this.rest.has_ares;

			this.is_loading = false;


			let order_ids = responses.order.data.map(i=>i.order.id);
			let filter = (payment_info:PaymentInfo)=>this.paymentInfoFilter(payment_info,order_ids);
			this.payment_info_list = responses.payments.data.filter( filter );

			this.other_payment_info_list = responses.payments.data.filter( i=>this.otherPaymentInfoFilter(i,order_ids) );
			this.all_checked = true;
			console.log('Other payments length', this.other_payment_info_list.length);

			this.all_payment_info_list = this.payment_info_list.concat( this.other_payment_info_list );
			this.all_payments_resume_list = this.getPaymentResume(this.all_payment_info_list);

			let category_map:Map<string,Category_Resume> = new Map();
			let tax_resume_map:Map<string,Tax_Resume> = new Map();

			let total_order_discount:number = 0;

			//Esto en teoria esta mal tenemos que sumar los Normales y
			//Restar los que regresan.

			for(let oi of responses.order.data)
			{
				for(let ii of oi.items )
				{
					if(!( ii.order_item.total && ii.order_item.type == 'NORMAL') )
					{
						continue;
					}

					let cr = ii.category == null
						? category_map.get('S/C')
						: category_map.get(ii.category.name );

					if( cr == null )
					{
						cr = {
							category_name: ii.category ? ii.category.name : 'S/C',
							qty: 0,
							total: 0
						};
						category_map.set( cr.category_name, cr );
					}

					cr.total += ii.order_item.total;
					cr.qty += ii.order_item.qty;

					let tr_name = ii.item.applicable_tax == 'EXEMPT'
						? 'Excento'
						: ''+oi.order.tax_percent;

					let tr:Tax_Resume | null = tax_resume_map.get( tr_name ) || null;

					if( tr == null )
					{
						tr = { name :tr_name, qty:0, total:0 };
						tax_resume_map.set( tr_name, tr);
					}

					tr.qty += ii.order_item.qty;
					tr.total += ii.order_item.tax;
				}

				this.total_order_discount += oi.order.discount;
			}

			//this.payment_resume_list = this.getPaymentResume(this.payment_info_list);
			//this.other_payment_resume_list = this.getPaymentResume(this.other_payment_info_list);

			this.store_list = responses.store.data;
			this.cashier_user_list = responses.users.data;
			this.price_type_list	= responses.price_type.data;
			//this.order_total		= responses.order_total.total;
			this.order_total		= responses.order.data.reduce((p,oi)=>p+oi.order.total-oi.order.discount,0);
			this.funds				= responses.funds.data;

			this.user_store = this.store_list.find((i)=>i.id==this.rest?.current_user.store_id) || GetEmpty.store();
			this.order_info_list = responses.order.data.map(oi=> this.getCustomOrderInfo(oi));

			let creditos_by_currency = new Map<string,number>();

			let total_credit = 0;

			let creditos = [];

			for(let oi of this.order_info_list)
			{
				total_credit += oi.credit_at_closing;

				if( oi.credit_at_closing )
				{
					creditos.push({ order_id: oi.order.id, credit_at_closing: oi.credit_at_closing });
				}

				if( oi.credit_at_closing < 0.001 )
				{
					continue;
				}

				let credito_currency = creditos_by_currency.get(oi.order.currency_id) || 0;
				creditos_by_currency.set(oi.order.currency_id,credito_currency+oi.credit_at_closing);
			}

			creditos.sort((a,b)=>a.order_id>b.order_id?1:-1);
			console.table(creditos);

			this.total_credit = total_credit;

			creditos_by_currency.forEach((credito,currency_id)=>
			{
				this.payment_resume_list.push(
				{
					amount: credito,
					mxn_amount: credito,
					transaction_type: 'CREDITO',
					currency_id,
					name: 'Crédito ' + currency_id,
					count: 0
				});
			});

			this.updateTotal();
			//this.setPages( this.order_search.page, responses.order.total );
		},(error)=>this.showError(error));
	}

	testPaymentMethod(method:string,payment_info:PaymentInfo,order_id:number)
	{
		return payment_info.movements.some((bm)=>
		{
			if( bm.bank_movement_orders.some(bmo=>bmo.order_id == order_id) )
			{
				return bm.bank_movement.transaction_type == method;
			}
			return false;
		});
	}

	getCustomOrderInfo(order_info:OrderInfo):CustomOrderInfo
	{
		let payments = this.payment_info_list.filter(payment=>
		{
			return payment.movements.some((bm)=>
			{
				return bm.bank_movement_orders.some(bmo=>bmo.order_id == order_info.order.id);
			});
		});


		const paid_at_closing = this.getAmountPaidForOrder( this.payment_info_list, order_info);
		const credit_at_closing = (order_info.order.total-order_info.order.discount)-paid_at_closing;

		let paid_cash = payments.some(pi=>this.testPaymentMethod('CASH',pi,order_info.order.id));
		let paid_card = payments.some(pi=>this.testPaymentMethod('CREDIT_CARD',pi,order_info.order.id)) ||
			payments.some(pi=>this.testPaymentMethod('DEBIT_CARD',pi,order_info.order.id));

		let paid_transfer = payments.some(pi=>this.testPaymentMethod('TRANSFER',pi,order_info.order.id));
		let paid_check = payments.some(pi=>this.testPaymentMethod('CHECK',pi,order_info.order.id));
		let paid_other = payments.some(pi=>this.testPaymentMethod('PAYPAL',pi,order_info.order.id));
		let credito = order_info.order.amount_paid+0.001 < order_info.order.total-order_info.order.discount;
		let disabled = credito || paid_card|| paid_transfer || order_info.order.facturado == 'YES';
		let checked	= true;

		let article_discount = order_info.items.reduce((prev,oi)=>
		{
			//If they are equal then there isn't a discount
			if( oi.order_item.original_unitary_price == oi.order_item.unitary_price_meta )
				return prev;

			//Discount must be with the tax included

			let tax = oi.order_item.tax == 0 ? 0 :order_info.order.tax_percent;

			//Tax exepmt
			if( tax == 0 )
				return prev+(oi.order_item.unitary_price_meta-oi.order_item.original_unitary_price)*oi.order_item.qty;

			let does_price_include_tax = oi.order_item.original_unitary_price > (oi.order_item.unitary_price+0.001);
			let price_with_tax = does_price_include_tax ? oi.order_item.unitary_price_meta : (oi.order_item.unitary_price_meta*( 1 + order_info.order.tax_percent ));
			let original_total = oi.order_item.qty* price_with_tax;

			return prev+(original_total-oi.order_item.total);
		},0);

		let price_type = this.price_type_list.find(pt=>pt.id == order_info.order.price_type_id );

		console.log('credito al cerrar', credit_at_closing);

		return {
			...order_info,
			article_discount,
			paid_cash,
			paid_card,
			paid_transfer,
			paid_check,
			paid_other,
			disabled,
			price_type_name: price_type?.name || '',
			checked,
			credito,
			paid_at_closing,
			credit_at_closing
		}
	}

	updateTotal()
	{
		this.total_facturacion = this.order_info_list.reduce((p,oi)=>
		{
			if( !oi.checked )
				return p;
			return p+oi.order.total-oi.order.discount
		},0);
		this.updateResumes();
	}

	clickedOnOrder(coi:CustomOrderInfo)
	{
		coi.checked= !coi.checked;
		this.all_checked= !this.order_info_list.some(oi=>!oi.checked);
		this.updateTotal();
	}

	toggleAll()
	{
		this.all_checked= !this.order_info_list.some(oi=>!oi.checked);

		this.all_checked = !this.all_checked;

		for(let oi of this.order_info_list)
		{
			if( oi.disabled )
			{
				continue;
			}

			oi.checked = this.all_checked;
		}

		this.updateTotal();
	}

	last_payment_sort:string[] = 'CASH,CARD,CHECK,TRANSFER,OTHER'.split(',');

	sortBy(type:string)
	{
		if( this.last_sort == type )
		{
			this.is_sort_ascending = !this.is_sort_ascending;
			if( this.last_payment_sort )
			{
				let last = this.last_payment_sort.shift();
				this.last_payment_sort.push( last );
			}
		}
		else
		{
			this.is_sort_ascending = true;
			this.last_sort = type;
		}

		if( this.last_sort == 'Total' )
		{
			this.order_info_list.sort((a,b)=>
			{
				let aa = a.order.total-a.order.discount;
				let bb = b.order.total-b.order.discount;
				if( this.is_sort_ascending )
				{
					return aa>bb ?1:-1;
				}
				return bb>aa ?1:-1;
			});
			return;
		}

		if( this.last_sort == 'Payment' )
		{
			let index_multiplier = [100000,10000,1000,100,10,1];

			let cash = index_multiplier[ this.last_payment_sort.indexOf('CASH') ];
			let check = index_multiplier[ this.last_payment_sort.indexOf('CHECK') ];
			let txt = index_multiplier[ this.last_payment_sort.indexOf('TRANSFER') ];
			let card = index_multiplier[ this.last_payment_sort.indexOf('CARD') ];
			let other = index_multiplier[ this.last_payment_sort.indexOf('OTHER') ];

			let sum_values = (a:CustomOrderInfo)=>{
				return ( a.paid_cash ? cash : 0 ) +
					( a.paid_check ? check : 0 )+
					( a.paid_card ? card : 0 )+
					( a.paid_transfer ? txt : 0 )+
					( a.paid_other ? other : 0 );
			};

			this.order_info_list.sort((a,b)=>
			{
				let asum = sum_values(a);
				let bsum = sum_values(b);

				return bsum > asum ? 1 : -1;
			});
			return;
		}

		if( this.last_sort == 'Client' )
		{
			this.order_info_list.sort((a,b)=>
			{
				if( this.is_sort_ascending )
				{
					return b.order.client_name < a.order.client_name ? 1:-1;
				}
				return a.order.client_name < b.order.client_name ? 1:-1;
			});
			return;
		}

		if( this.last_sort == 'Price_type' )
		{
			this.order_info_list.sort((a,b)=>
			{
				if( this.is_sort_ascending )
				{
					return b.price_type_name < a.price_type_name ? 1:-1;
				}
				return a.price_type_name < b.price_type_name ? 1:-1;
			});
			return;
		}

		if( this.last_sort == 'Id' )
		{
			this.order_info_list.sort((a,b)=>
			{
				if( this.is_sort_ascending )
				{
					return b.order.id < a.order.id ? 1:-1;
				}
				return a.order.id < b.order.id ? 1:-1;
			});
			return;
		}

		if( this.last_sort == 'Date' )
		{
			this.order_info_list.sort((a,b)=>
			{
				if( this.is_sort_ascending )
				{
					return a.order.created > b.order.created ? 1: -1;
				}
				return a.order.created < b.order.created ? 1: -1;
			});
			return;
		}

		if( this.last_sort == 'Selected' )
		{
			this.order_info_list.sort((a,b)=>
			{
				let aa = a.checked ? 1:0;
				let bb = b.checked ? 1:0;

				if( this.is_sort_ascending )
				{
					return aa>bb ?1:-1;
				}
				return aa<bb ?1:-1;
			});
			return;
		}

		if( this.last_sort == 'Cashier' )
		{
			this.order_info_list.sort((a,b)=>
			{
				let aa = a.order.total-a.order.discount;
				let bb = b.order.total-b.order.discount;
				if( this.is_sort_ascending )
				{
					return aa>bb ?1:-1;
				}
				return bb>aa ?1:-1;
			});
			return;
		}
	}

	save(evt:Event)
	{
		evt.preventDefault();
		evt.stopPropagation();

		let total =	this.total_facturacion;
		let filtered_orders = this.order_info_list.filter(i=>!this.rest.has_ares || i.checked );

		if( filtered_orders.length == 0 )
		{
			this.showError('Por favor seleccione al menos una orden');
			return;
		}

		this.subs.sink = this.confirmation.showConfirmAlert({},'Guardar Reporte', '¿Está seguro de que desea guardar el reporte?')
		.pipe
		(
			filter((response)=>response.accepted),
			mergeMap((_response)=>
			{
				let total =	this.total_facturacion;
				let filtered_orders = this.order_info_list.filter(i=>!this.rest.has_ares || i.checked );



				let ares = this.order_info_list.filter(i=>!i.checked && this.rest.has_ares);
				let discounts = filtered_orders.reduce((p,oi)=>p + oi.order.discount,0);
				let average_order_amount = total/filtered_orders.length;

				let end_timestamp = this.order_search.le.closed_timestamp|| new Date();

				let store_sale_report:Store_Sale_Report = {
					average_order_amount,
					amount_description: 'asdfasd fasdf kajs;l',
					created: this.order_search.ge.closed_timestamp,
					created_by_user_id: this.rest.current_user.id,
					date: this.order_search.search_extra.date as string,
					discounts,
					end_timestamp,
					expense_payments: 0,
					id: 0,
					income_payments: 0,
					localtime_end: Utils.getMysqlStringFromLocalDate( this.order_search.le.closed_timestamp ),
					localtime_start: Utils.getMysqlStringFromLocalDate( this.order_search.ge.closed_timestamp ),
					order_count: filtered_orders.length,
					order_ids:	filtered_orders.map(i=>i.order.id).join(','),
					ares_order_ids: ares.map(i=>i.order.id).join(','),
					start_timestamp: this.order_search.ge.closed_timestamp,
					store_consecutive: 0,
					store_id: this.order_search.eq.store_id,
					total_sales: filtered_orders.length,
					updated: new Date(),
					updated_by_user_id: this.rest.current_user.id
				};

				return this.rest.store_sale_report.create( Utils.transformDatesToString( store_sale_report) )
			})
		)
		.subscribe
		({
			next:(response)=>
			{
				this.router.navigate(['/view-sales-report',response.id]);
			},
			error:(error)=>
			{
				this.showError(error);
			}
		});
	}

	getName(currency_id:string, transaction_type:string):string
	{
		if( transaction_type == 'CASH' )
		{
			if( currency_id == 'USD' )
			{
				return 'Dolares';
			}

			return 'Efectivo';
		}

		if( transaction_type == 'CREDIT_CARD' )
		{
			return 'T. Credito';
		}

		if( transaction_type == 'DEBIT_CARD' )
		{
			return 'T. Debito';
		}

		if( transaction_type == 'CHECK' )
		{
			return 'Cheque';
		}

		if( transaction_type == 'TRANSFER' )
		{
			return 'Transferencia';
		}

		return transaction_type+' '+currency_id;
	}

	fechaIncialChange(fecha:string)
	{
		this.fecha_inicial = fecha;

		if( !fecha )
		{
			return;
		}

		this.order_search.ge.closed_timestamp = Utils.getDateFromLocalMysqlString(fecha);

		let end = new Date();
		end.setTime( this.order_search.ge.closed_timestamp.getTime() );
		end.setHours( this.order_search.ge.closed_timestamp.getHours()+24 );
		end.setSeconds( this.order_search.ge.closed_timestamp.getSeconds()-1 );

		this.order_search.le.closed_timestamp = new Date();
		this.order_search.le.closed_timestamp.setTime( end.getTime() );

		end.setSeconds( 0 );
		this.fecha_final = Utils.getLocalMysqlStringFromDate( end ).substring(0,16);
		console.log('Segtgint dates start', this.order_search.ge.closed_timestamp);
		console.log('EDN', end );
	}

	fechaFinalChange(fecha:string)
	{
		this.fecha_final = fecha;

		if( !fecha )
		{
			return;
		}

		this.order_search.le.closed_timestamp = Utils.getDateFromLocalMysqlString(fecha);
		this.order_search.le.closed_timestamp.setSeconds(59);
	}

	fechaPricipalChanges(value: string)
	{
		let d = Utils.getDateFromLocalMysqlString( value );
		console.log('Value is', value, d );
		let start = new Date();
		let end = new Date();

		if( d )
		{
			start.setTime( d.getTime() );
			start.setHours( 0, 0, 0, 0 );

			end.setTime( start.getTime() );
			end.setHours( 23, 59, 59, 0 );

			this.order_search.ge.closed_timestamp.setTime( start.getTime() );
			this.order_search.le.closed_timestamp.setTime( end.getTime() );
		}
		//2024-01-01 00:00
		this.fecha_inicial	= Utils.getLocalMysqlStringFromDate( this.order_search.ge.closed_timestamp ).substring(0,16);
		this.fecha_final	= Utils.getLocalMysqlStringFromDate( this.order_search.le.closed_timestamp ).substring(0,16);
	}

	onCustomRangeClick(evt: MouseEvent)
	{
		let input = evt.target as HTMLInputElement;
		this.custom_range = input.checked;
		this.order_search.search_extra['custom_range'] = input.checked ? 1 : 0;

		if( !this.custom_range )
		{
			let d = Utils.getDateFromLocalMysqlString( this.order_search.search_extra.date as string );
			let start = new Date();
			let end = new Date();

			if( d )
			{
				start.setTime( d.getTime() );
				start.setHours( 0, 0, 0, 0 );

				end.setTime( start.getTime() );
				end.setHours( 23, 59, 59, 0 );

				this.order_search.ge.closed_timestamp.setTime( start.getTime() );
				this.order_search.le.closed_timestamp.setTime( end.getTime() );
			}
			//2024-01-01 00:00
			this.fecha_inicial	= Utils.getLocalMysqlStringFromDate( this.order_search.ge.closed_timestamp ).substring(0,16);
			this.fecha_final	= Utils.getLocalMysqlStringFromDate( this.order_search.le.closed_timestamp ).substring(0,16);
		}
	}

	/*
	selectPercentage()
	{
		let selected = [];
		let trys = 0;
		let list_length = this.order_info_list.length;

		console.log('WTF WITH THIS');

		for(let oi of this.order_info_list)
		{
			if( oi.disabled )
				continue;

		//	let checked	=	!( order_info.order.facturado == 'YES');
			oi.checked = false;
		}

		while( trys <= list_length*2 )
		{
			console.log('IS IN');
			let x = Math.floor( Math.random() * list_length );

			console.log('Tryin here');

			trys++;

			if( selected.includes(x) || this.order_info_list[x].disabled )
			{
				console.log('Fails miserably');
				continue;
			}

			trys = 0;

			selected.push(x);
			let p = this.getSelectedPercentage(selected);

			if( p >= this.percentage_to_select )
			{
				break;
			}

			//Si se pasa de la cantidad de elementos
			//Entonces tronamos
		}
		console.log('IS OUT');

		for(let i of selected)
		{
			this.order_info_list[i].checked = true;
		}

		this.updateTotal();
	}
	*/


	selectPercentage()
	{
		let selected = [];
		let trys = 0;
		let list_length = this.order_info_list.length;

		let counter = 0;

		for(let oi of this.order_info_list)
		{
			if( !oi.disabled )
			{
				oi.checked = true;
				selected.push(counter);
			}
			counter++;
		}

		let order_ids_list = this.order_info_list.filter(oi=>!oi.disabled).map(oi=>oi.order.id);
		let map:Map<number,number> = new Map();

		order_ids_list.forEach((order_id,index)=>
		{
			map.set(order_id,Math.random());
		});

		order_ids_list.sort((a,b)=>
		{
			return map.get(a)-map.get(b);
		});

		let total = 0;

		for(let order_id of order_ids_list)
		{
			let index = this.order_info_list.findIndex(oi=>oi.order.id == order_id);

			if( index == -1 )
			{
				continue;
			}

			total += this.order_info_list[index].order.total;
		}

		let last_index = -1;
		let current_percent = 100;

		while( current_percent > this.percentage_to_select && order_ids_list.length > 0 )
		{
			let order_id = order_ids_list.pop() as number;
			last_index = this.order_info_list.findIndex(oi=>oi.order.id == order_id) as number;
			if( last_index == -1 )
			{
				console.log('No se encontro el id',order_id);
				break;
				continue;
			}
			this.order_info_list[last_index].checked = false;
			let oi = this.order_info_list[last_index];
			total -= (oi.order.total-oi.order.discount);
			current_percent = total/this.total_facturacion*100;
		}

		if( total > this.percentage_to_select && last_index != -1 )
		{
			this.order_info_list[last_index].checked = true;
			let order_info = this.order_info_list[last_index];
			total += (order_info.order.total-order_info.order.discount);
		}
		this.updateTotal();
	}

	getSelectedPercentage(indexes:number[]):number
	{
		let total = 0;
		let total_selected = 0;

		let index = 0;

		for(let oi of this.order_info_list)
		{
			if( !oi.paid_cash )
			{
				index++;
				console.log('paid cash','F'+JSON.stringify(oi.paid_cash));
				continue;
			}

			if( !oi.disabled )
			{
				total += oi.order.total-oi.order.discount;
			}

			if( indexes.includes(index) )
			{
				total_selected += oi.order.total-oi.order.discount;
			}
			index++;
		}

		return (total_selected/total*100);
	}

	getSelectedPercentageCash(indexes:number[]):number
	{
		let total = 0;
		let total_selected = 0;

		let index = 0;

		for(let oi of this.order_info_list)
		{
			if( !oi.paid_cash )
			{
				index++;
				console.log('paid cash','F'+JSON.stringify(oi.paid_cash));
				continue;
			}

			if( !oi.disabled )
				total += oi.order.total-oi.order.discount;

			if( indexes.includes(index) )
			{
				total_selected += oi.order.total-oi.order.discount;
			}
			index++;
		}

		return (total_selected/total*100);
	}

	getPaymentEntries(payment_info_list:PaymentInfo[]):BoxPaymentEntries
	{
		let payment_entries	= [
			{
				name:'Pesos',
				currency_id: 'MXN',
				payment_method: 'CASH',
				amount: 0
			}
			,{
				name:'Dolares',
				currency_id: 'USD',
				payment_method: 'CASH',
				amount: 0
			}
			,{
				name:'T.Credito',
				currency_id: 'MXN',
				payment_method: 'CREDIT_CARD',
				amount: 0
			}
			,{
				name:'T.Debito',
				currency_id: 'MXN',
				payment_method: 'DEBIT_CARD',
				amount: 0
			}
		];

		let box = 0;

		for(let pi of payment_info_list)
		{
			let user = pi.created_by_user.name;

			for(let mi of pi.movements)
			{
				for(let bmo of mi.bank_movement_orders)
				{
					let found_entry = payment_entries.find((e)=>
					{
						return e.currency_id == bmo.currency_id &&
							e.name == this.getTransactionName( mi.bank_movement.transaction_type, bmo.currency_id );
					});

					if(! found_entry )
					{
						found_entry = {
							currency_id:bmo.currency_id,
							amount: 0,
							payment_method: mi.bank_movement.transaction_type,
							name: this.getTransactionName( mi.bank_movement.transaction_type,bmo.currency_id )
						}
						payment_entries.push( found_entry );
					}

					found_entry.amount += bmo.currency_amount;

					box += bmo.amount;
				}
			}
		}

		return {
			box_amount: box,
			payment_entries: payment_entries
		};
	}

	getTransactionName(transaction_type:string, currency_id:string)
	{
		if( transaction_type == 'CASH' && currency_id == 'MXN' )
			return 'Pesos';

		if( transaction_type == 'CASH' && currency_id == 'USD' )
			return 'Dolares';

		if( transaction_type == 'CREDIT_CARD')
			return 'T.Credito';

		if( transaction_type == 'DEBIT_CARD' )
			return 'T.Debito';

		if( transaction_type == 'CHECK' )
			return 'Cheque';

		if( transaction_type == 'TRANSFER' )
			return 'Transferencia';

		if( transaction_type == 'PAYPAL' )
			return 'PAYPAL';

		return 'Otro';
	}

	getAmountPaidForOrder(payment_info_list:PaymentInfo[], order_info:OrderInfo):number
	{
		let amount = 0;

		for(let payment_info of payment_info_list)
		{
			for(let bm of payment_info.movements)
			{
				for(let bmo of bm.bank_movement_orders)
				{
					if( bmo.order_id == order_info.order.id )
					{
						amount += bmo.amount;
					}
				}
			}
		}
		return amount;
	}

	otherPaymentInfoFilter(payment_info:PaymentInfo, order_ids:number[]):boolean
	{
		for(let bm of payment_info.movements)
		{
			for(let bmo of bm.bank_movement_orders)
			{
				if( order_ids.includes( bmo.order_id ) )
				{
					return false;
				}
			}
		}
		return true;
	}

	paymentInfoFilter(payment_info:PaymentInfo, order_ids:number[]):boolean
	{
		for(let bm of payment_info.movements)
		{
			for(let bmo of bm.bank_movement_orders)
			{
				if( order_ids.includes( bmo.order_id ) )
				{
					return true;
				}
			}
		}

		//console.log('Pago filtraod', payment_info.payment.id);
		return false;
	}

	getPaymentResume(payment_info_list:PaymentInfo[]):PaymentsResume[]
	{
		let payment_resume_list:PaymentsResume[] = [];

		for(let pi of payment_info_list)
		{
			let found = false;
			for(let m of pi.movements)
			{
				let pr = payment_resume_list.find(i=>{
					return i.transaction_type == m.bank_movement.transaction_type &&
						i.currency_id == m.bank_movement.currency_id ;
				});

				let name = this.getName
				(
					m.bank_movement.currency_id,
					m.bank_movement.transaction_type
				);

				if( pr === undefined )
				{
					pr = {
						transaction_type : m.bank_movement.transaction_type,
						currency_id	: m.bank_movement.currency_id,
						name,
						amount: 0,
						mxn_amount: 0,
						count: 0
					}
					payment_resume_list.push( pr );
				}
				let mxn_amount = m.bank_movement_orders.reduce((p,i)=>p+i.amount,0);
				let amount = m.bank_movement_orders.reduce((p,i)=>p+i.currency_amount,0);

				pr.amount += amount;
				pr.mxn_amount += mxn_amount;

				if( !found )
				{
					pr.count++;
					found = true;
				}
			}
		}


		payment_resume_list.sort((a,b)=> a.name > b.name ? 1: -1);

		let total_count = payment_resume_list.reduce((p,i)=>p+i.count,0);

		let total = payment_resume_list.reduce((p,i)=>p+i.mxn_amount,0);
		payment_resume_list.push
		({
			name:'Total',
			amount: total,
			currency_id:'MXN',
			transaction_type:'TOTAL',
			mxn_amount: total,
			count: total_count
		});

		return payment_resume_list;
	}

	updateResumes()
	{
		//obter ids de ordenes marcadas
		let filter_order = (oi:CustomOrderInfo)=>oi.checked;
		let map_ids = (oi:OrderInfo)=>oi.order.id;
		let order_ids = this.order_info_list.filter( filter_order ).map( map_ids );

		// Filtra pagos que pertenecen a ordenes marcadas
		let filter = (payment_info:PaymentInfo)=>this.paymentInfoFilter(payment_info, order_ids);
		this.payment_info_list = this.original_payment_info_list.filter( filter );

		let total_payments_list = this.payment_info_list.concat( this.other_payment_info_list );
		this.payment_resume_list = this.getPaymentResume(this.payment_info_list);
		this.all_payments_resume_list = this.getPaymentResume(total_payments_list);
		this.other_payment_resume_list = this.getPaymentResume(this.other_payment_info_list);

		console.log('Cantidad de pagos totales', this.original_payment_info_list.length );
		console.log('Cantidad de pagos ventas ', this.payment_info_list.length );


		let total_credito = 0;

		console.log('AGORA SI MTF');
		for(let oi of this.order_info_list)
		{
			total_credito += oi.credit_at_closing;
		}

		console.log('Resumen de pagos');
		console.table(this.payment_resume_list);

		console.log('Resumen de otros pagos');
		console.table(this.other_payment_resume_list);

		console.log('Resumen de todos los pagos');
		console.table(this.all_payments_resume_list);

		this.total_credito = total_credito;
	}


}

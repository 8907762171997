import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Store, Returns } from 'src/app/models/RestModels';
import { ReturnsInfo } from 'src/app/models/models';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SearchObject } from 'src/app/services/Rest';
import { ParamMap } from '@angular/router';
import { Utils } from 'src/app/classes/Utils';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';

@Component({
	selector: 'app-list-returned-items',
	templateUrl: './list-returned-items.component.html',
	styleUrls: ['./list-returned-items.component.css']
})
export class ListReturnedItemsComponent extends BaseComponent implements OnInit 
{
	returns_info_list: ReturnsInfo[] = [];
	store_list: Store[] = [];
	store_dictionary: Record<string, Store> | Record<number, Store> = {};
	is_loading: boolean = false;
	return_search: SearchObject<Returns> = this.getEmptySearch();

	ngOnInit(): void 
	{
		this.path = '/list-returned-items';

		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((param_map: ParamMap) => 
			{
				this.setTitle('Reporte de artículos cancelados');
				this.is_loading = true;
				this.return_search = this.getSearch(param_map, ['store_id']);
				this.return_search.limit = this.page_size;

				if (this.return_search.sort_order.length == 0) 
				{
					this.return_search.sort_order = ['id_DESC'];
				}

				this.current_page = this.return_search.page;

				return forkJoin
				({
					param_map: of(param_map),
					stores: this.rest.store.search({ limit: 1000 }),
					returns_info: this.rest.returns_info.search(this.return_search)
				});
			}),
			mergeMap((response) => 
			{
				this.store_dictionary = Utils.createDictionary(response.stores.data, 'id');
				this.store_list = response.stores.data;
				let cashier_user_ids = response.returns_info.data.map(returns_info => returns_info.returns.cashier_user_id);

				return forkJoin
				({
					returns_info: of(response.returns_info),
					cashier_user: this.rest.user.search({ csv: { id: cashier_user_ids } })
				});
			}),
		)
		.subscribe((response) => 
		{
			response.returns_info.data.forEach(return_info => 
			{
				return_info.cashier_user = response.cashier_user.data.find(user => user.id == return_info.returns.cashier_user_id);
			});

			this.returns_info_list = response.returns_info.data;

			this.setPages(this.return_search.page, response.returns_info.total);
			this.is_loading = false;
		});
	}

	export()
	{
		let rows = [];
		for (let r of this.returns_info_list)
		{
			let cashier = r.cashier_user ? r.cashier_user.name : '';
	
			rows.push
			({
				'Id Devolución': r.returns.id,
				'Orden': r.returns.order_id,
				'Articulos': r.items.map(items => items.item.name).join(', '),
				'Cajero': cashier,
				'Sucursal': r.store.name,
				'Fecha': r.returns.created,
				'Total': r.returns.total
			});
		}
		let headers = 
		[
			'Id Devolución', 'Orden', 'Articulos', 'Cajero', 'Sucursal', 'Fecha', 'Total'
		];
		ExcelUtils.array2xlsx(rows, "Devoluciones.xlsx", headers);
	}

	print() 
	{
		this.rest.hideMenu();
		setTimeout(() => window.print(), 500);
	}
}
<div class="" style="display: flex; flex-direction: column; justify-content: space-between; min-height: 95vh;">
	<div class="">
		<ng-container *ngIf="order_info">
			<div class="row border-bottom">
				<div class="col-3 align-items-center mb-3">
					<img [src]="rest.getImagePath(order_info?.store?.ticket_image_id, rest.local_preferences.default_ticket_image_id)" style="width:100%;max-width:700px;">
				</div>
				<div class="col-6 align-items-center text-center pb-3">
					<h6 *ngIf="order_info?.store?.business_name" class="fw-bold">{{order_info?.store?.business_name | uppercase}}</h6>
					<div *ngIf="billing_data">
						<span>{{billing_data.address | uppercase}}</span>
						<ng-container *ngIf="order_info?.store?.address && order_info?.store?.city">, </ng-container>
						<span><br> {{billing_data.city | uppercase}}</span>
						<span *ngIf="billing_data.state">, {{order_info?.store?.state | uppercase}}</span>
						<span *ngIf="billing_data.zipcode">, CP:{{order_info?.store?.zipcode}}</span>
						<div *ngIf="order_info.store.name">
							Sucursal: {{order_info.store.name | titlecase}}
						</div>
					</div>
					<div *ngIf="order_info.store.phone">Teléfono: {{order_info.store.phone}}</div>
					<!--div *ngIf="order_info?.store?.email">Correo Electrónico: {{order_info?.store.email}}</div-->
					<!--div *ngIf="order_info?.store?.email">{{order_info?.store.email}}</div-->
				</div>
				<div class="col-3 align-items-center rounded">
					<div class="border">
						<div class="border-bottom px-2 py-1">
							Folio:<br>
							<b>No.{{ order_info.order?.id}}</b>
						</div>
						<div class="border-bottom px-2 py-1">
							Fecha de elaboración:<br>
							<b>{{order_info.order?.created | date: 'dd MMMM YYYY' }}</b>
						</div>
						<div class="px-2 py-1" *ngIf="forma_pago">
							Forma de Pago:<br>
							<b>{{forma_pago}}</b>
						</div>
						<div class="px-2 py-1" *ngIf="currency_rate">
							Tipo de cambio USD:<br>
							<b>{{currency_rate.rate | currency}} {{order_info.order.currency_id}}</b>
						</div>

					</div>
				</div>
			</div>
			<div class="py-3 px-1 px-md-3" *ngIf="order_info">
				<table>
					<ng-container *ngIf="show_billing_address">
						<tr>
							<td>Nombre:</td>
							<th>{{billing_address.name | uppercase}}</th>
						</tr>
						<tr>
							<td>RFC:</td>
							<th>{{billing_address.rfc | uppercase}}</th>
						</tr>
						<tr>
							<td>Correo:</td>
							<th>{{billing_address.email}}</th>
						</tr>
						<tr>
							<td>Dirección:</td>
							<th>
								{{billing_address.address}}
								{{billing_address.suburb ? (','+billing_address.suburb) : ''}}
								{{billing_address.city ? (', '+billing_address.city) : ''}}
								{{billing_address.state ? ('. '+billing_address.state) : ''}}
								{{billing_address.zipcode ? ('. CP:'+billing_address.zipcode) : ''}}
							</th>
						</tr>
					</ng-container>
					<ng-container *ngIf="!show_billing_address">
						<tr>
							<td>Nombre:</td>
							<th>
								{{order_info.order.sat_razon_social || order_info?.client?.name || order_info.order.client_name | uppercase}}
							</th>
						</tr>
						<tr *ngIf="order_info.order.address">
							<td>Dirección:</td>
							<td>{{order_info.order.address}}</td>
						</tr>
					</ng-container>
					<tr *ngIf="order_info.client?.phone">
						<td>Teléfono:</td>
						<th>{{order_info.client?.phone | lowercase }}</th>
					</tr>
				</table>
			</div>
			<div class="px-1 px-lg-3 py-3 my-3">
				<div class="row mb-3 align-items-center">
					<div class="col-6 fw-bold">Artículo</div>
					<div class="col-2 fw-bold text-end">Cantidad</div>
					<div class="col-2 fw-bold text-end">Precio</div>
					<div class="col-2 fw-bold text-end">Total</div>
				</div>
				<div class="row py-1 align-items-center border-top" *ngFor="let oii of order_info.items">
					<div class="col-6">{{oii.item.name | titlecase}}</div>
					<div class="col-2 text-end">{{oii.order_item.qty | number}}</div>
					<div class="col-2 text-end">
						{{oii.order_item.unitary_price | currency: '$'}}
					</div>
					<div class="col-2 text-end">
						{{oii.order_item.unitary_price * oii.order_item.qty| currency: '$'}}
					</div>
				</div>
				<div class="row align-items-center border-top">
					<div class="col-10">Subtotal</div>
					<div class="col-2 text-end fw-bold">{{ subtotal | currency: '$'}}</div>
				</div>
				<div class="row align-items-center border-top">
					<div class="col-10">IVA</div>
					<div class="col-2 text-end fw-bold">{{order_info.order.tax | currency: '$'}}</div>
				</div>
				<div class="row align-items-center border-top" *ngIf="order_info.order.sat_ieps">
					<div class="col-10">IEPS</div>
					<div class="col-2 text-end fw-bold">{{order_info.order.sat_ieps | currency: '$'}}</div>
				</div>

				<div class="row align-items-center border-top">
					<div class="col-10">Total</div>
					<div class="col-2 text-end fw-bold">{{order_info.order.total | currency: '$'}} {{order_info.order.currency_id}}</div>
				</div>
				<div class="row align-items-center border-top">
				<div class="col-12 text-end fw-bold">{{total_letra}} {{order_info.order.currency_id}} {{currency.name | uppercase}}</div>
				</div>
			</div>
		</ng-container>
		<hr>
	</div>

	<!--div class="text-center border-top" >
						{{ticketData.venta.cliente.nombre}}
						<br>{{ticketData.venta.cliente.direccion}}</div>
					<br-->

	<div class="container-fluid">
		<div class="row">
			<div class="col-12 mx-0 px-1 p-2 border-bottom mb-3" *ngIf="order_info.order.note">
				<b>NOTA:</b><span class=""> {{order_info.order.note}}</span>
			</div>
			<!--div class="col-5">
				<label>Autorizado Por:</label>
				<div class="black_border mt-1 p-2 w-100">
					{{order_info?.quote?.authorized_by}}&nbsp;
				</div>
				<!--label class="mt-3">Elaborado Por:</label>
				<div class="black_border mt-1 p-2 w-100">{{order_info?.person_in_charge?.name | titlecase}} &nbsp;</div>
			</div-->
			<div class="col-6 mt-3"></div>
			<div class="col-6 mt-3 pt-3">
				<label>Recibe:</label>
				<div class="black_border mt-1 p-2 w-100">&nbsp;</div>
				<!--label class="mt-3">Fecha de recepción:</label>
				<div class="black_border mt-1 p-2 w-100">&nbsp;</div-->
			</div>
		</div>
		<div class="foooter">
			<div *ngIf="order_info &&	order_info.order.paid_status != 'PAID' && (order_info.order.total-order_info.order.amount_paid >0.01 && order_info.order.status != 'CANCELLED')">
				A través de este pagaré yo
				<b>{{order_info?.client?.name || order_info.order.client_name }}</b>, me comprometo a pagar incondicionalmente la cantidad de
				<b>{{order_info.order.total-order_info.order.amount_paid | currency:'$'}}</b> de la venta con el folio No. {{order_info.order.id}} a la orden
				de
				<b>{{order_info?.store?.business_name | uppercase }}</b>. Dicha cantidad será pagada el día ______ de _________ del ______, de la
				forma
				en la que lo solicite el beneficiario de este documento, y en caso de que no se realice tal precisión, en el
				domicilio
				del suscriptor.
				<br>
				<b>{{order_info.store.city}} {{order_info.store.state}}</b>, a
				<b>{{order_info.order.created | date:"EEEE, MMMM d, y"}}</b>
				<br>
				<br>
			</div>
		</div>
	</div>
</div>
<div class="d-print-none py-3 text-center">
	<button type="button" (click)="printDirect()" class="my-3 btn btn-primary">Imprimir</button>
	<button type="button" (click)="goBack()" class="my-3 btn btn-primary">Regresar</button>
	<!--utton type="button" (click)="print()" class="btn btn-primary">Imprimir</button-->
</div>

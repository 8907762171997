import { Component, OnInit } from '@angular/core';
import { RestResponse, SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import { PaymentInfo} from '../../models/models';


import {Payment} from '../../models/RestModels';
import {User} from '../../models/RestModels';
import { mergeMap } from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import { forkJoin, Observable, of } from 'rxjs';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';


interface CPaymentInfo extends PaymentInfo
{
	currencies:string;
	order_id:number|null;
}


@Component({
	selector: 'app-list-payment',
	templateUrl: './list-payment.component.html',
	styleUrls: ['./list-payment.component.css']
})

export class ListPaymentComponent extends BaseComponent implements OnInit
{
	payment_search:SearchObject<Payment> = this.getEmptySearch();
	payment_info_list:CPaymentInfo[] = [];
	user_list:User[] = [];
	order_by_payment_id:Record<number,number> = {};

	fecha_inicial:string = '';
	fecha_final:string = '';

	ngOnInit()
	{
		this.path = '/list-payment';

		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((param_map)=>
			{
				let fields = [
					"id","created_by_user_id","company_id","type","payment_amount",
					"received_amount", "change_amount", "currency_id","exchange_rate",
					"received_by_user_id", "paid_by_user_id","created","updated"
				];

				let extra_keys:Array<string> = []; //['search_param1','project_id','some_id'];
				this.payment_search = this.getSearch(param_map, fields );
				this.setTitle('Lista de Pagos');
				this.current_page = this.payment_search.page;
				this.payment_search.eq.type = 'income';
				this.payment_search.sort_order=['id_DESC'];
				this.payment_info_list = [];
				this.is_loading = true;

				this.payment_search.search_extra['ares'] = this.rest.has_ares ? 1 : null;

				if( !this.payment_search.ge.created )
				{
					let d = new Date();
					d.setDate(1);
					d.setHours(0,0,0,0);
					this.payment_search.ge.created = d;
				}

				if( !this.payment_search.le.created )
				{
					let d = new Date();
					let end = Utils.getEndOfMonth( d );
					this.payment_search.le.created = end;
				}

				this.fecha_inicial = Utils.getLocalMysqlStringFromDate( this.payment_search.ge.created ).replace(' ','T');
				this.fecha_final = Utils.getLocalMysqlStringFromDate( this.payment_search.le.created ).replace(' ','T');



				return forkJoin
				({
					payment: this.rest.payment_info.search(this.payment_search),
					user: this.rest.user.search
					({
						limit:9999, eq:{type:'USER'},
						sort_order:['name_ASC'],
						search_extra:{ 'user_permission.pos': 1 }
					})
				})
			})
		)
		.subscribe((responses)=>
		{
			this.user_list = responses.user.data;

			responses.payment.data.forEach((payment_info)=>
			{
				let ids = [];

				payment_info.movements.forEach((bmi)=>
				{
					bmi.bank_movement_orders.forEach((bmo)=>
					{
						ids.push( bmo.order_id );
					})
				})

				if( ids.length > 0 )
				{
					this.order_by_payment_id[ payment_info.payment.id ] = ids[0];
				}
			});

			this.setPages( this.payment_search.page, responses.payment.total );

			this.payment_info_list = responses.payment.data.map((pi)=>
			{

				let order_id:number |null = null;

				let c = new Map<string,string>();
				for(let bmi of pi.movements)
				{
					c.set(bmi.bank_movement.currency_id,bmi.bank_movement.currency_id);
					if( !order_id )
					{
						for(let bmo of bmi.bank_movement_orders)
						{
							order_id = bmo.order_id;
							break;
						}
					}
				}

				let ca = Array.from( c.keys() );
				let currencies = ca.join(',');

				return {...pi,order_id, currencies};
			});

			this.is_loading = false;
		},(error)=>this.showError(error));
	}

	imprimir(payment_info:PaymentInfo)
	{
		let x  = window.open('/#/print-receipt/'+this.rest.local_preferences.default_print_receipt+'/'+payment_info.payment.id+'/print');
	}

	fechaIncialChange(fecha:string)
	{
		this.fecha_inicial = fecha;
		if( fecha )
		{
			this.payment_search.ge.created = Utils.getDateFromLocalMysqlString(fecha);
		}
		else
		{
			this.payment_search.ge.created = null;
		}
	}

	fechaFinalChange(fecha:string)
	{
		this.fecha_final = fecha;
		if( fecha )
		{
			this.payment_search.le.created = Utils.getDateFromLocalMysqlString(fecha);
		}
		else
		{
			this.payment_search.le.created = null;
		}
	}

	exportExcel()
	{
		let search:SearchObject<Payment> = {...this.payment_search};
		search.limit = 100;

		this.subs.sink = this.rest.payment_info
		.search( search ).pipe
		(
			mergeMap((response)=>{
				let observables:Observable<RestResponse<PaymentInfo>>[] = [ of(response) ];
				let pages= response.total/100;

				for(let i=1;i<pages;i++)
				{
					let s:SearchObject<Payment> = {...search};
					s.limit = 100;
					s.page = i;
					observables.push( this.rest.payment_info.search( s ) );
				}

				return forkJoin( observables );
			}),
			mergeMap((response)=>{
				let all:RestResponse<PaymentInfo> = { total: 0, data: []};

				response.forEach((i)=>{
					all.total = i.total;
					all.data.push(...i.data);
				})

				return of( all );
			})
		)
		.subscribe((response)=>
		{
			let rows = response.data.map((pi) => ({
                'Id': pi.payment.id,
                'Cantidad': pi.payment.payment_amount,
                'Tag': pi.payment.tag,
                'Recibio': pi?.created_by_user?.name || '',
                'Cliente': pi?.paid_by_user?.name || '',
                'Fecha': Utils.getLocalMysqlStringFromDate(pi.payment.created)
            }));

            const headers = ['Id', 'Cantidad', 'Tag', 'Recibio', 'Cliente', 'Fecha'];
            ExcelUtils.array2xlsx(rows, 'Ingresos.xlsx', headers);
		},(error)=>{this.showError(error)});
	}
}

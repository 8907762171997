<div class="container-fluid pb-3 corte_de_caja" *ngIf="cash_close_info">
	<div class="text-center">
		<img [src]="rest.getImagePath(cash_close_info.store.ticket_image_id, rest.local_preferences.default_ticket_image_id)" class="image_logo">
	</div>

	<div class="px-0 text-center">
		<div class="fw-bold">Sucursal {{store.name}}</div>
		<div class="pb-3" *ngIf="billing_data == null || billing_data?.id == 0">
			<div>{{store.business_name}}</div>
			<div>{{store.rfc}}</div>
			<span>{{store.name}}&nbsp;</span>
			<span *ngIf="store?.address">{{store.address}}&nbsp;</span>
			<span *ngIf="store?.city">,{{store.city | titlecase}}&nbsp;</span>
			<span *ngIf="store?.state">,{{store.state | titlecase}}&nbsp;</span>
			<span *ngIf="store?.zipcode">,{{store.zipcode| titlecase}}&nbsp;</span>
		</div>
		<div class="" style="text-transform:uppercase" *ngIf="billing_data">
			<div>{{billing_data.razon_social}} {{billing_data.regimen_capital}}</div>
			<div>{{billing_data.rfc}}</div>
			<div>
				<span *ngIf="billing_data.address">{{billing_data.address}}&nbsp;</span>
				<span *ngIf="billing_data?.city">,{{billing_data.city | titlecase}}&nbsp;</span>
				<span *ngIf="billing_data?.state">,{{billing_data.state| titlecase}}&nbsp;</span>
			</div>
			<div>{{billing_data.zipcode | titlecase}}&nbsp;</div>
		</div>
		<span *ngIf="store.phone">Tel: {{store.phone}}</span>
	</div>
	<h4>CORTE DE CAJA</h4>
	<div class="d-print-none">
		<button type="button" class="btn btn-primary" (click)="printDirect()">Imprimir</button>
	</div>
	<div>
		Solicitado por: <b>{{cash_close_info?.user?.name}}</b>
	</div>
	<div>
		Fecha:
		<b>{{current_date | date: 'MMMM d, y'}}</b>
	</div>

	<div>
		Hora:
		<b>{{current_date | date: 'h:mm a'}}</b>
	</div>

	<div>
		De:
		<b>{{cash_close_info.cash_close.start | date: 'MMMM d, y, h:mm a'}}</b>
	</div>

	<div>
		A:
		<b>{{cash_close_info.cash_close.end | date: 'MMMM d, y, h:mm a'}}</b>
	</div>

	<hr>

	<div class="row">
		<div class="col-6 text-end"></div>
	</div>


	<div class="row border-top">
		<div class="col-6 fw-bold">Total Ventas</div>
		<div class="col-3 text-center"></div>
		<div class="col-3 fw-bold text-end">{{total_sales| currency:'$'}}</div>
	</div>

	<ng-container *ngIf="cash_close_info.funds.length">
		<h4 class="mt-3">Fondo</h4>
		<div class="row" *ngFor="let fund of cash_close_info.funds">
			<div class="col-6">{{fund.created | shortDate: 'full'}}</div>
			<div class="col-6 text-end">{{fund.amount | currency:'$'}} {{fund.currency_id}}</div>
		</div>
	</ng-container>

	<ng-container *ngIf="expenses_paymens.length">
		<h4 class="mt-3">Gastos/Retiros</h4>
		<div class="row" *ngFor="let payment of expenses_paymens">
			<div class="col-6">{{payment.created | shortDate: 'full'}} - {{payment.concept || payment.tag }}</div>
			<div class="col-6 text-end">{{payment.payment_amount | currency:'$'}} {{payment.currency_id}}</div>
		</div>
	</ng-container>

	<h4 class="mt-3">CAJA</h4>

	<div class="row">
		<div class="col-6 fw-bold">Total Ventas</div>
		<div class="col-6 fw-bold text-end">{{total_sales| currency:'$'}}</div>
		<div class="col-6">Credito</div>
		<div class="col-6 text-end">{{total_credit | currency:'$'}}</div>
		<div class="col-6">Descuentos</div>
		<div class="col-6 text-end">{{total_discount | currency:'$'}}</div>
		<div class="col-6">Total Pagos de Ventas</div>
		<div class="col-6 text-end">{{total_income_sales | currency:'$'}}</div>
		<div class="col-6">Total Otros Pagos</div>
		<div class="col-6 text-end">{{total_income_other | currency:'$'}}</div>
		<div class="col-6">Gastos</div>
		<div class="col-6 text-end">{{total_expenses | currency:'$'}}</div>
		<div class="col-6">Cancelaciones</div>
		<div class="col-6 text-end">{{total_returns | currency:'$'}}</div>
		<div class="col-6">Total Fondos</div>
		<div class="col-6 text-end">{{total_funds|currency:'$'}}</div>
		<div class="col-6">Total Ingresos</div>
		<div class="col-6 text-end">
		{{
				total_income_sales
				+total_income_other
				-total_expenses
				-total_discount
			| currency:'$'
		}}
		</div>
	</div>
	<div class="row border-top">
		<div class="col-6 fw-bold">Total en caja</div>
		<div class="col-6 fw-bold text-end">{{
				total_income_sales
				+total_income_other
				+total_funds
				-total_expenses
				-( movements_qtys['Tarjeta Crédito'].total || 0 )
				-( movements_qtys['Tarjeta Débito'].total || 0 )
				-( movements_qtys['Transferencia'].total || 0 )
				-( movements_qtys['Cheque'].total || 0 )
				- total_returns
				| currency:'$' }}
		</div>
		<div class="col-6 fw-bold">Efectivo En Caja</div>
		<div class="col-6 fw-bold">{{cash_close_info.cash_close.cash_on_hand | currency}}</div>
		<div class="col-6 fw-bold">Diferencia</div>
		<div class="col-6 fw-bold">
			{{
			cash_close_info.cash_close.cash_on_hand -
			(
			total_income_sales
			+total_income_other
			+total_funds
			-total_expenses
			-( movements_qtys['Tarjeta Crédito'].total || 0 )
			-( movements_qtys['Tarjeta Débito'].total || 0 )
			-( movements_qtys['Transferencia'].total || 0 )
			-( movements_qtys['Cheque'].total || 0 )
			- total_returns
			) | currency
			}}
		</div>
	</div>

	<div class="tex-bold">Notas</div>
  <div class="">{{cash_close_info.cash_close.note}}</div>

	<h4 class="mt-3">Formas de ingreso</h4>
	<div class="row" *ngFor="let kv of movements_qtys | keyvalue">
		<div class="col-6">{{kv.key | titlecase}}</div>
		<div class="col-3 text-end">
			<span *ngIf="kv.value.currency_id == 'USD'">
				{{kv.value.total | currency:'$' }} ×	{{currency_rate.rate | number:'1.2-2'}}
			</span>
		</div>
		<div class="col-3 text-end">{{kv.value.equivalencia | currency:'$'}}</div>
	</div>
	<div class="row border-top">
		<div class="col-6 fw-bold">Total</div>
		<div class="col-6 fw-bold text-end">{{currencies_total | currency:'$'}}</div>
	</div>

	<div class="py-5">
		<div *ngIf="total_returns > (
			total_income_sales + total_income_other + total_funds - total_expenses
			-(movements_qtys['Tarjeta Crédito']?.total || 0)
			-(movements_qtys['Tarjeta Débito']?.total || 0)
			-(movements_qtys['Transferencia']?.total || 0)
			-(movements_qtys['Cheque']?.total || 0) - total_returns)">
			<span>El <b>"Total en caja"</b> es negativo debido a que el monto de las cancelaciones realizadas <b>es mayor que el ingreso en efectivo.</b></span>
		</div>
	</div>

</div>

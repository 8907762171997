export const environment = {
	production: true,
	firebase:
	{
		apiKey: "AIzaSyA0hDiGA_QO-THBt--Mof6iaqwErjV1Yno",
		authDomain: "notifications-c7243.firebaseapp.com",
		projectId: "notifications-c7243",
		storageBucket: "notifications-c7243.appspot.com",
		messagingSenderId: "397579425541",
		appId: "1:397579425541:web:335ff85dd2a60e241f896c",
		measurementId: "G-SWFWYGKN4M",
	},
	app_settings:{
		socket_io_url: 'https://notifications.integranet.xyz:5000',
		geolocation_key:"AIzaSyC2RzI_wHQBIysXsI-g_Miwg2J1-o14cn8",
		load_google_maps: true,
		hashids_salt: 'mCP7VhvOT3B',
		hashids_alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890',
		hashids_length: 5,
		path_api: 'api',
		test_url: '', //Si esta vacio, se toma el valor de la url actual
		pdf_service_url: 'https://pdf.integranet.xyz'
	}
};

import { Component } from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {debounceTime, mergeMap} from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import {ExcelUtils } from 'src/app/classes/ExcelUtils';
import {Utils} from 'src/app/classes/Utils';
import {ItemInfo, SerialInfo} from 'src/app/models/models';
import {Item, Stock_Record, Store} from 'src/app/models/RestModels';
import {RestResponse, SearchObject} from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

const SEARCH_LIMIT = 200;

declare function txt2html(str:string):string;
declare function printHtml(html_body:string,title:string):any;

interface StockItemInfo extends ItemInfo
{
	qty:number;
	store:Store;
}

@Component({
	selector: 'app-stock-search-report',
	templateUrl: './stock-search-report.component.html',
	styleUrls: ['./stock-search-report.component.css']
})
export class StockSearchReportComponent extends BaseComponent
{
	search_str:string = '';
	item_search = this.getEmptySearch<Item>();
	store_dictionary:Record<number,Store> = {};
	store_list:Store[] = [];
	item_info_list: ItemInfo[] = [];
	stock_item_info_list:StockItemInfo[] = [];
	availability:'' | 'ON_STOCK';
	show_serials:boolean	= false;
	selected_item_info:StockItemInfo | null = null;
	store_id:number | null = null;
	serials_to_show: SerialInfo[] = [];
	store:Store  = GetEmpty.store();

	show_add_stock: boolean = false;
	add_stock_qty: number = 1;
	add_stock_comment:string = '';
	add_stock_store_id: number | '' = '';
	show_remove_stock: boolean;
	remove_stock_qty: number | '' = '';
	remove_stock_comment: string = '';

	ngOnInit(): void
	{
		//http://127.0.0.137/PointOfSale/item_info.php?category_name=iphone%207&limit=200&_sort=
		this.path = '/stock-search-report';
		this.item_search= this.getEmptySearch();

		this.subs.sink = this.getQueryParamObservable().pipe
		(
			debounceTime(200),
			mergeMap((response)=>
			{
				//this.store_id = null;
				let param_map = response[1];
				let query_param_map = response[0];

				this.availability = query_param_map.get('availability_type') == null ? '' : 'ON_STOCK';
				let cname = query_param_map.get('category_name') || '';;
				let headers = ['name','sort_weight'];

				this.item_search = this.getSearch( query_param_map, headers );
				this.item_search.search_extra.category_name = cname;
				this.item_search.eq.availability_type = this.availability == '' ? undefined : this.availability;

				if(query_param_map.has('store_id')	&& query_param_map.get('store_id') != '')
				{
					this.store_id = parseInt( query_param_map.get('store_id') ) as number;
				}

				this.item_search.limit = SEARCH_LIMIT;
				return forkJoin
				({
					stores: this.store_list.length ?
						of({total:this.store_list.length,data:this.store_list}) :
						this.rest.store.search({limit:99999,sort_order:['name_ASC']}),
					search: this.rest.item_info.search( this.item_search )
				})
			}),
			mergeMap((response)=>
			{
				if( this.store_list.length == 0 )
				{
					this.store_dictionary = Utils.createDictionary(response.stores.data,'id');

					this.store_list = response.stores.data.filter((s)=>
					{
						if( this.rest.current_permission.global_add_stock )
							return true;

						return s.id == this.rest.current_user.store_id;
					});

				}
				return of( response );
			})
		)
		.subscribe((response)=>
		{
			let tmp_list:StockItemInfo[] = [];

			for(let ii of response.search.data )
			{
				for(let store of this.store_list)
				{
					let rr = ii.records.find((rr)=>{
						return rr.store_id == store.id
					});

					let qty = rr == undefined ? 0 : rr.qty;

					if( ii.item.has_serial_number == 'YES')
					{
						let same = ii.serials.filter((f)=>f.serial.store_id == store.id );
						qty = same.length;
					}

					if( !(this.store_id) || store.id == this.store_id )
						tmp_list.push({...ii, qty, store });
				}
			}
			this.stock_item_info_list = tmp_list;
			this.item_info_list = response.search.data;
		});
	}

	doASearch(evt:Event)
	{
		this.router.navigate(['/stock-search-report'],{queryParams:{availibility_type:this.availability,category_name:this.search_str}});
	}

	clickOnHeader(header:string)
	{
		this.sort( header, this.item_search );
	}

	exportFile()
	{
		this.is_loading = true;

		let search:SearchObject<Item> = this.getEmptySearch();
		//let search = this.getSearch(param_map,
		search.search_extra.category_name = this.search_str.trim();
		search.eq.status = 'ACTIVE';
		search.limit = SEARCH_LIMIT;
		search.sort_order = ['category.sort_weight_DESC','sort_weight_DESC','name_ASC'];

		this.subs.sink = this.rest.item_info
		.search( search )
		.pipe
		(
			mergeMap((response)=>
			{
				let observables = [ of(response) ];

				let items_promises:Observable<RestResponse<ItemInfo>>[] = [];
				let pages_needed = Math.ceil( response.total/SEARCH_LIMIT );

				for(let i=1;i<pages_needed;i++)
				{
					let s:SearchObject<Item> = this.getEmptySearch();
					s.search_extra.category_name = this.search_str.trim();
					s.eq.status = 'ACTIVE';
					s.limit = SEARCH_LIMIT;
					s.page = i;
					s.sort_order = ['category.sort_weight_DESC','sort_weight_DESC','name_ASC'];
					observables.push(	this.rest.item_info.search( s ) );
				}

				console.log('Returning promises', items_promises);
				return forkJoin(observables);
			})
		)
		.subscribe((response)=>
		{
			let items = response.reduce((p,c)=>{
				p.push(...c.data );
				return p;
			},[]);

			console.log('Cantidad de articulos'+items.length);

			let data:Record<string,string|number>[] = [];

			for(let oii of items )
			{
				if( this.store_id )
				{
					let store_record = oii.records.find((r:Stock_Record)=> r.store_id == this.store_id );
					let store = this.store_dictionary[this.store_id];
					this.appendToList(store_record, store, oii, data );
				}
				else
				{
					for(let store of this.store_list)
					{
						let store_record = oii.records.find((r:Stock_Record)=> r.store_id == store.id ) || null;
						this.appendToList(store_record, store, oii, data );
					}
					//this.appendToList(null, this.store_list[0], oii, data );
				}
			}

			let d = new Date();
			let headers = ['Codigo','Categoría','Artículo','Sucursal','Cantidad', '#Serie','Prioridad','Descripcion', 'Datos Adicionales'];
			let filename = 'Inventario'+Utils.getMysqlStringFromDate(d).substring(0,10)+'.xlsx';
			ExcelUtils.array2xlsx(data, filename , headers );
		},(error)=>this.showError(error));
	}

	appendToList(store_record:Stock_Record | null ,store:Store,oii:ItemInfo,data:Record<string,string|number>[])
	{
		let sr:Record<string,string|number> = {};

		if( !store_record )
		{
			data.push
			({
				'Id': oii.item.id,
				'Codigo': oii.item.code || '',
				'Sucursal': store.name,
				'Categoría': oii?.category?.name || '',
				'Artículo': oii.item.name,
				'Cantidad': 0,
				'#Serie' : '',
				'Prioridad': oii.item.sort_weight,
				'Descripcion':'',
				'Datos Adicionales': ''
			});
			return;
		}

		if( oii.item.has_serial_number == 'NO' )
		{
			data.push
			({
				'Id': oii.item.id,
				'Codigo': oii.item.code || '',
				'Sucursal': store.name,
				'Categoría': oii?.category?.name || '',
				'Artículo': oii.item.name,
				'Cantidad': store_record.qty,
				'#Serie'	: '',
				'Prioridad': oii.item.sort_weight,
				'Descripcion': '',
				'Datos Adicionales': '',
			})
			return;
		}

		let serials = oii.serials.filter(si=>si.serial.store_id == store.id );

		if( serials.length )
		{
			for(let s of serials )
			{
				data.push({
					'Id': oii.item.id,
					'Codigo': oii.item.code || '',
					'Sucursal': '',//store.name,
					'Categoría': oii?.category?.name || '',
					'Artículo': oii.item.name,
					'Cantidad': 1,
					'#Serie'	: s.serial.serial_number,
					'Prioridad'	: oii.item.sort_weight,
					'Descripcion':s.serial.description || '',
					'Datos Adicionales': s.serial.additional_data || '',
				})
			}
			return;
		}
		//Aqui se extraaña el forelse
		//No habia para la sucursal asi que ponemos 0
		data.push
		({
			'Id': oii.item.id,
			'Codigo': oii.item.code || '',
			'Sucursal': store.name,
			'Categoría': oii?.category?.name || '',
			'Artículo': oii.item.name,
			'Cantidad': 0,
			'#Serie'	: '',
			'Prioridad': oii.item.sort_weight,
			'Descripcion': '',
			'Datos Adicionales': '',
		})
	}

	onAvailabilityChange(evt:Event)
	{
		//this.availability = value;
		this.router.navigate(['/stock-search-report'],{queryParams:{availability_type:this.availability || undefined}});
	}

	onStoreChange(store_id:number|null)
	{
		this.store_id = store_id;
		this.router.navigate(['/stock-search-report'],{queryParams:{availability_type:this.availability || undefined,store_id}});
	}

	print()
	{
		console.log('Imprimiendo');
		let search:SearchObject<Item> = this.getEmptySearch();
		//let search = this.getSearch(param_map,
		search.search_extra.category_name = this.search_str.trim();

		if( this.availability )
			search.eq.availability_type =this.availability;

		search.limit = SEARCH_LIMIT;

		this.subs.sink = this.rest.item_info
		.search( search )
		.pipe
		(
			mergeMap((response)=>{
				let observables = [ of(response) ];

				let items_promises:Observable<RestResponse<ItemInfo>>[] = [];
				let pages_needed = Math.ceil( response.total/SEARCH_LIMIT );

				for(let i=1;i<pages_needed;i++)
				{
					let s:SearchObject<Item> = this.getEmptySearch();
					s.search_extra.category_name = this.search_str.trim();
					s.eq.status = 'ACTIVE';
					if( this.availability )
						search.eq.availability_type =this.availability;
					s.limit = SEARCH_LIMIT;
					s.page = i;
					observables.push(	this.rest.item_info.search( s ) );
				}

				console.log('Returning promises', items_promises);
				return forkJoin(observables);
			})
		)
		.subscribe((response)=>
		{
			let items = response.reduce((p,c)=>{
				p.push(...c.data );
				return p;
			},[]);

			console.log('Cantidad de articulos',items.length );

			let data:Record<string,string|number>[] = [];

			for(let oii of items )
			{
				if( this.store_id )
				{
					let store_record = oii.records.find((r:Stock_Record)=> r.store_id == this.store_id );
					let sr:Record<string,string|number> = {};

					if(store_record)
					{
						sr = {
							'Sucursal': this.store_dictionary[this.store_id].name,
							'Categoría': oii?.category?.name || '',
							'Artículo': oii.item.name,
							'Cantidad': store_record.qty
						}
					}
					else
					{
						sr = {
							'Sucursal': this.store_dictionary[this.store_id].name,
							'Categoría': oii?.category?.name || '',
							'Artículo': oii.item.name,
							'Cantidad': 0
						}
					}
					data.push( sr );
				}
				else
				{
					for(let store of this.store_list)
					{
						let store_record = oii.records.find((r:Stock_Record)=> r.store_id == store.id );
						let sr:Record<string,string|number> = {};

						if( store_record )
						{
							sr = {
								'Sucursal': store.name,
								'Categoría': oii?.category?.name || '',
								'Artículo': oii.item.name,
								'Cantidad': store_record.qty,
							}
						}
						else
						{
							sr = {
								'Sucursal': store.name,
								'Categoría': oii?.category?.name || '',
								'Artículo': oii.item.name,
								'Cantidad': 0
							}
						}

						data.push( sr );
					}
				}
			}

			let str = `<table>
				<tr>
					<th>Articulo</th>`;
			if( !this.store_id )
				str += '<th>Sucursal</th>';

			str += `<th>Cantidad</th>
				</tr>`;

			for(let d of data)
			{
				str+= `<tr>
						<td>${txt2html(''+d['Categoría']+'-'+d['Artículo'])}</td>`;

				if( !this.store_id )
				{
					console.log('Con sucursal');
					str += `<td>${txt2html(''+d['Sucursal'])}</td>`;
				}

				str+=`
						<td style="text-align:right;">${txt2html(''+d['Cantidad'] )}</td>
					</tr>`;
			}
			str += '</table>';

			printHtml( str, 'Inventario' );
		},(error)=>this.showError(error));
	}

	showSerialNumbers(ii:StockItemInfo)
	{
		this.selected_item_info = ii;
		this.serials_to_show = ii.serials.filter((s:SerialInfo)=>
		{
			console.log('Comparing ', s.serial.store_id, ii.store.id);
			return ii.store.id== s.serial.store_id;
		});

		this.show_serials = true;
	}

	showAddStock(sii: StockItemInfo)
	{
		this.selected_item_info = sii;
		//this.store = this.rest.current_permission.global_add_stock ? null : this.store_list.find(s=>this.rest.current_user.store_id ==s.id );
		//this.add_stock_store_id = this.store ? this.store.id : '';
		this.add_stock_store_id = sii.store.id
		this.show_add_stock = true;
		//this.showModal('modal-add-stock');
	}

	showRemoveStock(sii: StockItemInfo)
	{
		this.selected_item_info = sii;
		//this.store = this.rest.current_permission.global_add_stock ? null : this.store_list.find(s=>this.rest.current_user.store_id ==s.id );
		//this.add_stock_store_id = this.store ? this.store.id : '';
		this.add_stock_store_id = sii.store.id
		this.show_remove_stock = true;

		//this.showModal('modal-add-stock');
	}


	addStock(evt: Event)
	{
		evt.stopPropagation();
		evt.preventDefault();
		console.log('QUe pex', this.add_stock_store_id );

		this.rest.update
		(
			'addStock',{
				item_id: this.selected_item_info.item.id,
				comment: this.add_stock_comment,
				store_id: this.add_stock_store_id,
				qty: this.add_stock_qty
			}
		).subscribe((response:Stock_Record)=>
		{

			let form = evt.target as HTMLFormElement;
			form.reset();

			this.selected_item_info.stock_record = response;
			this.selected_item_info.qty = response.qty;
			this.show_add_stock = false;
			this.is_loading = false;

			this.showSuccess('Se agrego el inventario con exito');
		},(error)=>this.showError(error)

		);
	}

	addMerma(evt: Event)
	{
		evt.stopPropagation();
		evt.preventDefault();

		this.rest.update
		(
			'addMerma',{
				item_id: this.selected_item_info.item.id,
				comment: this.remove_stock_comment,
				store_id: this.add_stock_store_id,
				qty: this.remove_stock_qty
			}
		).subscribe((response:Stock_Record)=>
		{

			let form = evt.target as HTMLFormElement;
			form.reset();

			this.selected_item_info.stock_record = response;
			this.selected_item_info.qty = response.qty;
			this.show_add_stock = false;
			this.show_remove_stock = false;
			this.is_loading = false;

			this.showSuccess('Se removio inventario con exito');
		},(error)=>this.showError(error)

		);
	}

	/*
	showModal(id:string)
	{
		let e = document.getElementById(id) as HTMLDialogElement;

		if( e )
			e.showModal();

	}

	closeModal(id:string)
	{
		let e = document.getElementById(id) as HTMLDialogElement;
		this.show_add_stock = false;

		if( e )
			e.close();

		this.is_loading = false;
	}
	*/
}

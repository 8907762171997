import { Component } from '@angular/core';
import { ParamMap } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import { BaseComponent } from '../base/base.component';
import {SearchObject} from 'src/app/services/Rest';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';

interface BCSearch
{
  production_area_id: number;
  start: Date;
  end: Date;
}

@Component({
  selector: 'app-report-sells-by-production-area',
  templateUrl: './report-sells-by-production-area.component.html',
  styleUrls: ['./report-sells-by-production-area.component.css']
})
export class ReportSellsByProductionAreaComponent extends BaseComponent
{

  report_list: Record<string, any>[] = [];
  report_search: SearchObject<BCSearch> = this.getEmptySearch();
  startx: string = '';
  endx: string = '';
  total_sales: number;
  total_qty: number;
  last_sort: string = '';

  ngOnInit(): void 
  { 
    this.path = '/report-sells-by-production-area';
    this.route.queryParamMap.pipe
    (
      mergeMap((param_map: ParamMap) =>
      {
          this.setTitle('Reporte Ventas por área de producción');
          this.is_loading = true;

          return forkJoin
          ({
            param_map: of(param_map)
          });
      }),
      mergeMap((response) =>
      {
        let headers = ['production_area_id', 'start', 'end'];
        this.report_search = this.getSearch(response.param_map, headers, []);

        console.log('Report search:', this.report_search);

        if (!this.report_search.ge.start)
        {
          let d = new Date();
          d.setHours(0, 0, 0, 0);
          d.setDate(1);
          this.report_search.ge.start = d;
        }
        if (!this.report_search.le.end)
        {
          let x = new Date();
          let end_of_month = Utils.getEndOfMonth( x );
          end_of_month.setHours(23, 59, 0, 0);

          this.report_search.le.end = end_of_month;
        }

        this.report_search.ge.start = new Date(this.report_search.ge.start);
        this.report_search.le.end = new Date(this.report_search.le.end);
        
        this.startx = Utils.getLocalMysqlStringFromDate(this.report_search.ge.start as Date);
        this.endx = Utils.getLocalMysqlStringFromDate(this.report_search.le.end as Date);

        console.log('las fechas soooooooon start: ', this.startx);
        console.log('las fechas soooooooon end: ', this.endx);

        this.report_search.le.end.setSeconds( 59 );
        this.report_search.eq.start = this.report_search.ge.start;
				this.report_search.eq.end = this.report_search.le.end;

        return this.rest.getReportByFileName('getSalesByProductionArea', this.report_search.eq);
      })
    )
    .subscribe((response) =>
      {
        this.is_loading = false;
        this.report_list = response;

        this.total_sales = this.report_list.reduce((a, b) => a + (b.amount || 0), 0);
        this.total_qty = this.report_list.reduce((a, b) => a + (b.qty_total || 0), 0);
      },(error)=>this.showError(error))
  }

  searchReport()
  {
    this.report_search.search_extra.start = null;
    this.report_search.search_extra.end = null;

    console.log('Search:', this.report_search);
  
    this.search(this.report_search);
  }
  
  sortBy(column)
  {
    if (this.last_sort === column) 
    {
      this.report_list.reverse();
      this.last_sort = '';
      return;
    }
    this.report_list.sort((a, b) => 
    {
      if (typeof a[column] === 'string') 
      {
        return (a[column] as string).localeCompare(b[column] as string);
      }
      return (a[column] as number) - (b[column] as number);
    });
    this.last_sort = column;
  }

  print()
  {
    this.rest.hideMenu();
    setTimeout(() => window.print(), 500);
  }
  
  export()
  {
    const rows = this.report_list.map((r) => ({
      'Área de Producción': r.area_name,
      'Cantidad Vendida': r.qty_total,
      'Monto Total': r.amount
    }));

    const headers = ['Área de Producción', 'Cantidad Vendida', 'Monto Total'];
    ExcelUtils.array2xlsx(rows, 'VentasPorAreaProduccion.xlsx', headers);
  }
}

<div class="container-fluid">
	<div class="row align-items-center mt-3 mx-0">
		<div class="col">
			<h1 class="text-capitalize">Proveedores</h1>
		</div>
		<div class="col-auto">
			<a class="btn btn-secondary lift" [routerLink]="['/provider']">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M4.5 8.552c0 1.995 1.505 3.5 3.5 3.5s3.5-1.505 3.5-3.5s-1.505-3.5-3.5-3.5s-3.5 1.505-3.5 3.5zM19 8h-2v3h-3v2h3v3h2v-3h3v-2h-3zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1h2z" fill="currentColor"/></svg>
				Agregar
			</a>
		</div>
	</div>
	<form (submit)="onSearch($event)" name="search_client">
		<div class="p-3 card my-3">
			<div class="row">
				<div class="col-8 col-md-10 form-group">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="user_search.start.name" class="form-control">
				</div>
				<div class="col-4 col-md-2 form-group">
					<label>&nbsp;</label>
					<div>
						<button type="submit" class="w-100 btn btn-primary">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path _ngcontent-oxd-c76="" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"></path></svg>
							Buscar
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>

	<div  class="card p-3 mt-3">
		<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th (click)="this.sort('name',this.user_search)" class="pointer">Usuario</th>
						<th (click)="this.sort('balance',this.user_search)" class="text-end pointer">Balance</th>
						<th (click)="this.sort('updated',this.user_search)" class="text-end pointer">Actualizado</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let user of user_list">
						<td>
							<img *ngIf="user.image_id" [src]="rest.getImagePath(user.image_id)" style="max-width:30px;max-height:30px;">
							<span *ngIf="!user.image_id" class="default_image">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path _ngcontent-tvn-c138="" d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"></path></svg>
							</span>
							&nbsp;
							<a [routerLink]="['/provider',user.id]">{{user.name}}</a>
						</td>
						<td class="text-end">
							{{user.balance | currency:'$'}}
						</td>
						<td class="text-end">
							{{user.updated | date: 'MMM d, y,  h:mm a' }}
						</td>
						<td class="text-end">
							<a [routerLink]="['/list-provider-bills', user.id]" class="btn btn-primary mx-1">
								Pagar
							</a>
							<a [routerLink]="['/list-purchase']" [queryParams]="{'eq.provider_user_id':user.id}" class="btn btn-primary">
								Compras
							</a>
						</td>
					</tr>
					<tr *ngIf="user_list == null || user_list.length == 0 && !is_loading">
						<td  colspan="2">
							<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
<!-- app-modal [(show)]="show_import">
	<div class="card p-3">
		<h2>Import excel</h2>
		<div class="from-group mb-3">
			<label>Excel data</label>
			<input name="file" type="file" (change)="onFileChanged($event)" accept="*/*" class="form-control">
		</div>
		<div class="row mt-3">
			<div class="col-6">
				<button type="button" class="btn btn-secondary w-100" (click)="show_import=false">Cancel</button>
			</div>
			<div class="col-6">
				<button type="button" class="btn btn-primary w-100" (click)="uploadFile()" [disabled]="file==null">Upload</button>
			</div>
		</div>
	</div>
</app-modal -->

<ng-container *ngIf="order_info">
	<ng-container *ngFor="let i of copies">
		<div class="break_pages px-0" style="background-color:white;color:black">
			<div class="text-center">
				<img [src]="rest.getImagePath(order_info.store?.ticket_image_id, rest.local_preferences.default_ticket_image_id)" class="image_logo_ticket">
			</div>
			<div class="d-print-none">
				<button type="button" class="btn btn-primary m-3"  (click)="justPrint()">Imprimir</button>
				<button type="button" class="btn btn-primary m-3"  (click)="location.back()">Regresar</button>
			</div>
			<div class="px-0">
				<div class="fw-bold text-center">Sucursal {{order_info.store.name}}</div>
				<div class="text-center" *ngIf="billing_data == null">
					<div *ngIf="order_info.store">{{order_info.store.business_name}}</div>
					<div *ngIf="order_info.store.rfc">{{order_info.store.rfc}}</div>
					<span>{{order_info?.store?.name}}&nbsp;</span>
					<span *ngIf="order_info?.store?.address">{{order_info.store.address}}&nbsp;</span>
					<span *ngIf="order_info?.store?.city">,{{order_info.store.city | titlecase}}&nbsp;</span>
					<span *ngIf="order_info?.store?.state">,{{order_info.store.state | titlecase}}&nbsp;</span>
					<div>
						<span *ngIf="order_info?.store?.zipcode">CP: {{order_info.store.zipcode| titlecase}}&nbsp;</span>
						<span *ngIf="order_info?.store?.phone">Tel:{{order_info.store.phone}}</span>
					</div>
				</div>
				<div class="text-center" style="text-transform:uppercase" *ngIf="billing_data">
					<div>{{billing_data.razon_social}} {{billing_data.regimen_capital}}</div>
					<div>{{billing_data.rfc}}</div>
					<div>
						<span *ngIf="billing_data.address">{{billing_data.address}}&nbsp;</span>
						<span *ngIf="billing_data?.city">,{{billing_data.city | titlecase}}&nbsp;</span>
						<span *ngIf="billing_data?.state">,{{billing_data.state| titlecase}}&nbsp;</span>
					</div>
					<div>
						<span>CP: {{billing_data.zipcode | titlecase}}&nbsp;</span>
						<span *ngIf="order_info?.store?.phone">Tel:{{order_info.store.phone}}</span>
					</div>
				</div>
				<h1 *ngIf="order_info.order.status=='CANCELLED'" class="text-center">ORDEN CANCELADA</h1>
				<h2 class="text-center text-black">{{service_type_dictionary[order_info.order.service_type]}}</h2>
				<div class="" *ngIf="order_info.order.sync_id && !order_info.order.id ">
					IDOF:<span class="fw-bold">#{{order_info.order.sync_id}}</span>
				</div>
				<div class="row">
					<div class="col-6">
						<span *ngIf="order_info?.order?.id">
							ID: <span class="fw-bold">{{order_info.order.id}}</span>
						</span>
					</div>
					<div class="col-6 text-end">
						<span>
							FOLIO: <span class="fw-bold monospace">#{{order_info.order?.store_consecutive}}</span>
						</span>
					</div>
				</div>
				<div class="">
					Fecha: <span class="fw-bold monospace">{{order_info.order?.created | shortDate:'full'}}</span>
				</div>
				<div class="" *ngIf="order_info?.order?.client_name">
					Cliente: <span class="fw-bold monospace">{{order_info.order?.client_name | uppercase}}</span>
				</div>
				<div class="" *ngIf="table">
					Mesa: <span class="fw-bold monospace">{{table.name | uppercase}}</span>
				</div>
				<div class="">
					Atendio: <span class="fw-bold">{{order_info.cashier?.name | uppercase}}</span>
				</div>
				<div class="" *ngIf="order_info?.order?.quote_id">
					Cotización: <span class="fw-bold">{{order_info.order.quote_id}}</span>
				</div>
				<table class="w-100 mx-0">
					<tr class="border-bottom">
						<td class="fw-bold border-bottom">Artículos</td>
						<td class="fw-bold text-center border-bottom">Cantidad</td>
						<td class="fw-bold text-end border-bottom">Total</td>
					</tr>
					<ng-container *ngFor="let order_item_info of order_info.items">
						<tr [ngClass]="{'border-top': !(order_item_info.order_item.item_option_id)}">
							<td>
								<b>
									<span *ngIf="rest.local_preferences.display_categories_on_items == 'YES'&&order_item_info.category">
										{{order_item_info.category.name}} -
									</span>
									{{order_item_info.item.name }}
								</b>
							</td>
							<td class="text-center monospace">
								{{order_item_info.order_item.type == 'REFUND'?'-':''}}
								{{order_item_info.order_item.qty}}×{{(order_item_info?.order_item?.total || 0 )/(order_item_info.order_item?.qty || 1) | currency:'$'}}
								<ng-container *ngIf="order_item_info.order_item.discount_percent">({{order_item_info.order_item.discount_percent}}%)</ng-container>
							</td>
							<td class="text-end monospace">
								{{order_item_info.order_item.type == 'REFUND'?'-':''}}
								{{order_item_info.order_item.total | currency:'$' }}
							</td>
						</tr>
						<tr *ngIf="order_item_info.order_item.type == 'REFUND' || order_item_info.order_item.note || order_item_info.order_item.exceptions || order_item_info.serials_string">
							<td colspan=3 class="text-start monospace">
								<div *ngIf="order_item_info.order_item.type == 'REFUND'">DEVOLUCIÓN</div>
								<div *ngIf="order_item_info.order_item.note">{{order_item_info.order_item.note}}</div>
								<div *ngIf="order_item_info.order_item.exceptions">{{order_item_info.order_item.exceptions}}</div>
								<div *ngIf="order_item_info.serials_string"><pre><code>{{order_item_info.serials_string}}</code></pre></div>
							</td>
						</tr>
					</ng-container>
					<tr class="border-top">
						<td class="text-end">Subtotal</td>
						<td colspan=2 class="text-end monospace">{{(order_info.order.total-sat_ieps-tax)| currency:'$'}}</td>
					</tr>
					<tr class="border-top" *ngIf="order_info.order.discount">
						<td class="text-end monospace">Descuento</td>
						<td colspan=2 class="text-end monospace">{{order_info.order.discount | currency:'$'}}</td>
					</tr>
					<tr>
						<td class="text-end">IVA</td>
						<td colspan=2 class="text-end monospace">
							{{ tax | currency:'$'}}
						</td>
					</tr>
					<tr *ngIf="sat_ieps">
						<td class="text-end">IEPS</td>
						<td colspan=2 class="text-end monospace">{{ sat_ieps | currency:'$'}}</td>
					</tr>
					<tr class="border-top">
						<td class="text-end fw-bold">Total</td>
						<td colspan=2 class="text-end fw-bold monospace">{{(order_info.order.total-order_info.order.discount)| currency:'$'}}</td>
					</tr>
					<ng-container *ngIf="payment_info?.movements">
						<ng-container *ngFor="let bank_movement_info of payment_info.movements">
							<ng-container *ngFor="let bmo of bank_movement_info.bank_movement_orders">
								<tr>
									<td class="text-end">{{ legend[ bank_movement_info.bank_movement.transaction_type+'-'+bmo.currency_id ] }}</td>
									<td class="text-center">
										<ng-container *ngIf="bmo.currency_id != 'MXN'" >
											{{bank_movement_info.bank_movement.amount_received | number: '1.2-2'}} × {{bmo.exchange_rate}}
										</ng-container>
									</td>
									<td class="text-end monospace">
										{{
										(	bmo.currency_id !='MXN'
											? (bank_movement_info.bank_movement.amount_received || 0 ) *(bmo.exchange_rate || 1)
											: bmo.amount
										) | currency:'$' }}
									</td>
								</tr>
							</ng-container>
						</ng-container>
						<tr class="border-top">
							<td class="text-end">T. Recibido</td>
							<td class="text-center"></td>
							<td colspan=2 class="text-end monospace">{{ payment_info.payment.received_amount | currency:'$'}}</td>
						</tr>
						<tr class="mx-0">
							<td class="text-end fw-bold">Cambio</td>
							<td  colspan=2 class="text-end fw-bold monospace">{{ payment_info.payment.change_amount | currency:'$'}} MXN</td>
						</tr>
						<tr class="mx-0" *ngIf="currency_rate">
							<td class="text-end fw-bold">Cambio en {{currency_rate.currency_id}}</td>
							<td colspan=2 class="text-end fw-bold monospace">{{ ((payment_info.payment.change_amount/currency_rate.rate)>=0.01?payment_info.payment.change_amount/currency_rate.rate:0) | currency:'$'}} USD</td>
						</tr>
					</ng-container>
				</table>
			</div>
			<div *ngIf="order_info.order.status == 'CLOSED' && order_info.order.paid_status != 'PAID' && (order_info.order.total-order_info.order.amount_paid >0.01)">
				A través de este pagaré yo
				<b>{{order_info?.client?.name}}</b>, me comprometo a pagar incondicionalmente la cantidad de
				<b>{{order_info.order.total-(order_info.order.amount_paid+order_info.order.discount) | currency:'$'}}</b> de la venta con el folio No. {{order_info.order.id}} a la orden
				de
				<b>{{order_info?.store?.rfc}}</b>. Dicha cantidad será pagada el día ______ de _________ del ______, de la
				forma
				en la que lo solicite el beneficiario de este documento, y en caso de que no se realice tal precisión, en el
				domicilio
				del suscriptor.
				<br>
				<b>{{order_info.store.city}} {{order_info.store.state}}</b>, a
				<b>{{order_info.order.created | date:"EEEE, MMMM d, y"}}</b>
				<br>
				<br>
				<!--div class="text-center border-top" >
					{{ticketData.venta.cliente.nombre}}
					<br>{{ticketData.venta.cliente.direccion}}</div>
				<br-->
			</div>
			<div id="markdown" class="text-center" [innerHTML]="order_info?.store?.ticket_footer_text | marked"></div>
			<div class="text-center" *ngIf="show_bill_code">
				Código Facturación:<br>
				<code style="font-size:20px;color:#000000;text-transform:uppercase">{{order_info.order.facturacion_code}}</code>
			</div>
			<div *ngIf="show_bill_code && order_info.store.show_facturacion_qr	==	'YES'">
				<ngx-qrcode
					[elementType]="element_type"
					[value]="url_for_facturacion"
					[cssClass]="order_info.store.qr_size"
					[scale]="5"
					[errorCorrectionLevel]="error_correction_level">
				</ngx-qrcode>
			</div>
		</div>
	</ng-container>
</ng-container>

import { Component, OnInit } from '@angular/core';
import {ParamMap} from '@angular/router';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';
import {Utils} from 'src/app/classes/Utils';
import {Category, Store} from 'src/app/models/RestModels';
import {SearchObject} from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

interface BCSearch
{
	item_id:number;
	store_id:number| '';
	category_id:number | '';
	cashier_user_id: number | null;
	created:Date;
	compound_name:string;
	ares: number;
	end:Date;
	start:Date;
}

@Component({
	selector: 'app-report-sells-by-item',
	templateUrl: './report-sells-by-item.component.html',
	styleUrls: ['./report-sells-by-item.component.css']
})
export class ReportSellsByItemComponent extends BaseComponent
{
	store_list: Store[];
	report_list:Record<string,string | number>[] = [];
	report_search:SearchObject<BCSearch> = this.getEmptySearch();
	store_dictionary: Record<string, Store> | Record<number, Store>;
	startx:string = '';
	endx:string = '';
    last_sort: string = '';
    total: number;
    total_qty: number;
	category_list: Category[] = [];

	ngOnInit(): void
	{
		this.path = '/report-sells-by-item';

		this.subs.sink = this.rest.category.search({limit:1000})
		.subscribe((response)=>
		{
			this.category_list = response.data;
		}),(error)=>this.showError(error);

		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((param_map: ParamMap)=>
			{
				this.setTitle('Reporte Ventas por artículo');
				this.is_loading = true;
				return forkJoin
				({
					param_map: of(param_map),
					stores: this.rest.getStores(true)
				});
			}),
			mergeMap((response)=>
			{
				this.store_dictionary = Utils.createDictionary(response.stores.data, 'id');
				this.store_list = response.stores.data;

				let headers = ['item_id','store_id', 'category_id' ,'cashier_user_id','created','compound_name'];
				this.report_search = this.getSearch(response.param_map,headers,[] );

				if( this.rest.has_ares )
				{
					this.report_search.eq.ares = 1;
				}

				if( !this.report_search.ge.created )
				{
					let d = new Date();
					d.setHours(0,0,0,0);
					d.setDate(1);

					this.report_search.ge.created = d;// Utils.getUTCMysqlStringFromDate( d );
				}

				if( !this.report_search.le.created )
				{
					let x = new Date();
					let end_of_month = Utils.getEndOfMonth( x );
					end_of_month.setHours( 23, 59, 0, 0 );

					this.report_search.le.created = end_of_month;//Utils.getUTCMysqlStringFromDate( end_of_month );
				}

				if( !this.report_search.eq.store_id )
				{
					this.report_search.eq.store_id = '';
				}

				this.endx	= Utils.getLocalMysqlStringFromDate( this.report_search.le.created as Date);
				this.startx	= Utils.getLocalMysqlStringFromDate( this.report_search.ge.created as Date);

				this.report_search.le.created.setSeconds( 59 );
				this.report_search.eq.start = this.report_search.ge.created;
				this.report_search.eq.end = this.report_search.le.created;
				return this.rest.getReporte('getSalesByItem',this.report_search.eq);
			})
		)
		.subscribe((response)=>
		{
			this.is_loading = false;
			this.report_list = response


			this.total = this.report_list.reduce((a,b)=>a+(b['order_item_total']as number),0);
			this.total_qty = this.report_list.reduce((a,b)=>a+(b['order_item_qty']as number),0);
			//this.total = report_list.forEach(x=>x.total = x.qty*x.price);

		},(error)=>this.showError(error))
	}

	searchReport()
	{
		this.report_search.search_extra.start = null;
		this.report_search.search_extra.end = null;

		this.search( this.report_search );
	}

	print()
	{
		this.rest.hideMenu();
		setTimeout(()=>window.print(),500);
	}

	export()
	{

		this.subs.sink =this.rest
		.getReporte('getSalesByItem',this.report_search.eq)
		.subscribe((resposne)=>
		{
			let rows = [];
			for(let r of this.report_list)
			{
				let category = r.category_name || '';

				rows.push
				({
					'Id Artículo': r.item_id,
					'Id Categoría': r.category_id,
					'Categoría':category,
					'Artículo':	r.item_name,
					'Código':	r.item_code,
					'Cantidad':	r.order_item_qty,
					'Total':	r.order_item_total
				});
			}

			console.table( rows );

			let headers = [
				'Id Categoría','Categoría','Id Artículo','Artículo','Código','Cantidad','Total'
			];
			ExcelUtils.array2xlsx(rows,"VentasXArticulos.xlsx",headers );
		});
	}
	sortByName()
	{

		if( this.last_sort == 'item_name' )
		{
			this.report_list.sort((a,b)=>(''+b['item_name']).localeCompare(''+a['item_name']));
			this.last_sort = '';
			return;
		}
		this.report_list.sort((a,b)=>(''+a['item_name']).localeCompare(''+b['item_name']));
		this.last_sort = 'item_name';

	}

	sortByQty()
	{
		if( this.last_sort == 'order_item_qty' )
		{
			this.report_list.sort((a,b)=>(a['order_item_qty'] as number)-(b['order_item_qty'] as number));
			this.last_sort = '';
			return;
		}
		this.report_list.sort((a,b)=>(b['order_item_qty'] as number)-(a['order_item_qty'] as number));
		this.last_sort = 'order_item_qty';
	}

	sortByTotal()
	{
		if( this.last_sort == 'order_item_total' )
		{
			this.report_list.sort((a,b)=>
			{
				return b['order_item_total'] as number -(a['order_item_total'] as number);
			});
			this.last_sort = '';
			return;
		}

		this.report_list.sort((a,b)=>
		{
			return a['order_item_total'] as number -(b['order_item_total'] as number);
		});

		this.last_sort = 'order_item_total';
	}
}

<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-8 my-3">
			<h1 class="text-capitalize my-3">{{item.id ? 'Editar':'Agregar'}} Artículo</h1>
		</div>
		<div class="col-4 text-end" *ngIf="item.id">
			<a [routerLink]="['/edit-prices',item.id]" class="btn btn-secondary me">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3.005 3.003h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-18a1 1 0 0 1-1-1v-16a1 1 0 0 1 1-1Zm5.5 11v2h2.5v2h2v-2h1a2.5 2.5 0 0 0 0-5h-4a.5.5 0 1 1 0-1h5.5v-2h-2.5v-2h-2v2h-1a2.5 2.5 0 1 0 0 5h4a.5.5 0 0 1 0 1h-5.5Z"/></svg>
				<span class="d-none d-md-inline">Precios</span>
			</a>
			<a [routerLink]="['/adjust-stock',item.id]" class="btn btn-secondary ms-1" *ngIf="rest.current_permission.add_stock && item.has_serial_number=='NO'">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="m4.036 2.488l6.611 2.833L8 6.455L1.427 3.638c.148-.151.329-.273.535-.352l2.074-.798Zm1.338-.514l1.55-.596a3 3 0 0 1 2.153 0l4.962 1.908c.205.08.386.2.534.352l-2.656 1.138l-6.543-2.802Zm9.62 2.572L8.5 7.329v7.45a2.99 2.99 0 0 0 .577-.158l4.962-1.909a1.5 1.5 0 0 0 .961-1.4V4.686a1.3 1.3 0 0 0-.007-.14ZM7.5 14.779v-7.45L1.007 4.546a1.505 1.505 0 0 0-.007.14v6.626a1.5 1.5 0 0 0 .962 1.4l4.961 1.909c.188.072.381.125.577.158Z"/></svg>
				<span class="d-none d-md-inline">Inventario</span>
			</a>
			<a [routerLink]="['/serial',item.id]" class="btn btn-secondary ms-1" *ngIf="rest.current_permission.add_stock && item.has_serial_number=='YES'">
				<svg _ngcontent-pof-c138="" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path _ngcontent-pof-c138="" fill="currentColor" d="M419.13 96H419l-35.05.33L128 96h-.16l-36.74.33C66.93 96.38 48 116.07 48 141.2v230.27c0 25.15 19 44.86 43.2 44.86h.15l36.71-.33l255.92.33h.17l35.07-.33A44.91 44.91 0 0 0 464 371.13V140.87A44.92 44.92 0 0 0 419.13 96ZM144 320a16 16 0 0 1-32 0V192a16 16 0 0 1 32 0Zm64 32a16 16 0 0 1-32 0V160a16 16 0 0 1 32 0Zm64-16a16 16 0 0 1-32 0V176a16 16 0 0 1 32 0Zm64 16a16 16 0 0 1-32 0V160a16 16 0 0 1 32 0Zm64-32a16 16 0 0 1-32 0V192a16 16 0 0 1 32 0Z"></path></svg>
				<span class="d-none d-md-inline">#Series</span>
			</a>
		</div>
	</div>
	<form ngNativeValidate (submit)="save()">
		<div class="row">
			<div class="card">
				<div class="row p-3">
					<div class="col-127">
						<div class="row">
							<div class="col-auto">
								<app-image-uploader [can_be_deleted]="true" [(image)]="item.image_id" [max_width]="450" [max_width]="450"></app-image-uploader>
							</div>
							<div class="col">
								<label class="">Nombre*</label>
								<input type="text" name="name" [(ngModel)]="item.name" class="form-control" autocomplete="off" required>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-4" *ngIf="category_list.length > 0">
						<label class="">Categoría</label>
						<select name="category_id" [(ngModel)]="item.category_id" (ngModelChange)="updateCategory($event)" class="form-control">
							<option [ngValue]="null">Seleccionar</option>
							<option *ngFor="let c of category_list" [value]="c.id">{{c.name |titlecase}}</option>
						</select>
					</div>

					<div class="col-12 col-sm-6 col-md-4">
						<label class="">A la Venta*</label>
						<select name="on_sale" [(ngModel)]="item.on_sale" class="form-control">
							<option value="YES" [selected]="item.on_sale=='YES'">Si</option>
							<option value="NO" [selected]="item.on_sale=='NO'">No</option>
						</select>
					</div>

					<div class="col-12 col-sm-6 col-md-4 form-group">
						<label>Tags</label>
						<div class="py-1" [ngClass]="{'d-none':item.json_tags.length==0}">
							<span *ngFor="let tag of item.json_tags" class="badge bg-secondary mx-1" role="button" (click)="confirmTagElimination($event, tag)">{{tag}}</span>
						</div>
						<div class="row">
							<div class="col-8">
								<input name="the_tags" #the_tags class="form-control">
							</div>
							<div class="col-4">
								<button type="button" class="btn btn-primary" (click)="addTag(the_tags.value); the_tags.value = ''">Agregar</button>
							</div>
						</div>
					</div>


					<div class="col-12 col-sm-6 col-md-4 form-group">
						<label>
							<abbr title="Siempre: se puede vender no importa que hay inventario o no. En Inventario, esta a la venta unicamente cuando hay inventario">
								Disponibilidad
							</abbr>
						</label>
						<select class="form-control" name="availability_type" [(ngModel)]="item.availability_type">
							<option value="ON_STOCK" selected>En Inventario</option>
							<option value="ALWAYS">Siempre</option>
						</select>
					</div>

					<div class="col-12 col-sm-6 col-md-4 form-group">
						<label>En devolucion:</label>
						<select class="form-control" name="return_action" [(ngModel)]="item.return_action">
							<option value="RETURN_TO_STOCK" selected>Regresar al Inventario</option>
							<option value="ADD_TO_MERMA">Agregar a merma</option>
						</select>
					</div>

					<div class="col-12 col-sm-6 col-md-4">
						<label class="">Código</label>
						<input type="text" name="code" [(ngModel)]="item.code" class="form-control">
					</div>

					<div class="col-12 col-sm-6 col-md-4">
						<label class="">Unidad de medida
							<abbr title="Cualquier unidad inclusive personalizada como vaso ó botella, etc, la unidad en la que se vaya a manejar el inventario" style="cursor:auto!important">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M10 .4C4.697.4.399 4.698.399 10A9.6 9.6 0 0 0 10 19.601c5.301 0 9.6-4.298 9.6-9.601c0-5.302-4.299-9.6-9.6-9.6zm.896 3.466c.936 0 1.211.543 1.211 1.164c0 .775-.62 1.492-1.679 1.492c-.886 0-1.308-.445-1.282-1.182c0-.621.519-1.474 1.75-1.474zM8.498 15.75c-.64 0-1.107-.389-.66-2.094l.733-3.025c.127-.484.148-.678 0-.678c-.191 0-1.022.334-1.512.664l-.319-.523c1.555-1.299 3.343-2.061 4.108-2.061c.64 0 .746.756.427 1.92l-.84 3.18c-.149.562-.085.756.064.756c.192 0 .82-.232 1.438-.719l.362.486c-1.513 1.512-3.162 2.094-3.801 2.094z"/></svg>
							</abbr>
						</label>
						<input type="text" name="measurement_unit" placeholder="Ej: pieza,kg,litros" [(ngModel)]="item.measurement_unit" class="form-control" [required]="item.availability_type=='ON_STOCK'" list="measurement_unit">
						<datalist id="measurement_unit">
							<option>Pieza</option>
							<option>Litros</option>
							<option>Kilogramos</option>
							<option>Gramos</option>
							<option>Mililitros</option>
							<option>Onzas</option>
						</datalist>
					</div>

					<div class="col-12 col-sm-6 col-md-4">
						<label class="">En venta por fracción</label>
						<select name="tax_model" [(ngModel)]="item.partial_sale" class="form-control" required>
							<option value="NO">NO</option>
							<option value="YES">SI</option>
						</select>
					</div>

					<div class="col-6 col-md-4">
						<label class="">Costo de Referencia</label>
						<input type="text" min="0" name="reference_price" [(ngModel)]="item.reference_price" class="form-control">
					</div>

					<div class="col-6 col-md-4">
						<label class="">Moneda Referencia</label>
						<select name="reference_currency_id" [(ngModel)]="item.reference_currency_id" class="form-control" required>
							<option [ngValue]="null">Seleccionar</option>
							<option *ngFor="let c of currency_list" [value]="c.id">{{c.name}}</option>
						</select>
					</div>

					<div class="col-12 col-sm-6 col-md-4" *ngIf="item.on_sale == 'YES'">
						<label class="">Requiere Nota</label>
						<select name="note_required" [(ngModel)]="item.note_required" class="form-control">
							<option [ngValue]="null">Seleccionar</option>
							<option value="NO">NO</option>
							<option value="YES">Si</option>
						</select>
					</div>

					<div class="col-12 col-sm-6 col-md-4" *ngIf="item.on_sale == 'YES'">
						<label class="">Para rentas</label>
						<select name="for_reservation" [(ngModel)]="item.for_reservation" class="form-control">
							<option value="NO">No</option>
							<option value="YES">Si</option>
						</select>
					</div>
					<div class="col-12 col-sm-6 col-md-4" *ngIf="item.for_reservation == 'YES'">
						<label class="">Periodo de renta</label>
						<select name="period_type" [(ngModel)]="item.period_type" class="form-control">
							<option value="BY_HOUR">Hora</option>
							<option value="DAILY">Día</option>
							<option value="WEEKLY">Mes</option>
						</select>
					</div>
					<div class="col-12 col-sm-6 col-md-4">
						<label class="">Tipo de Comanda</label>
						<select name="commanda_type_id" [(ngModel)]="item.commanda_type_id" class="form-control">
							<option [ngValue]="null">No Agregar</option>
							<option *ngFor="let c of commanda_type_list" [value]="c.id">{{c.name | titlecase}}</option>
						</select>
					</div>
					<div class="col-12 col-sm-6 col-md-4" *ngIf="item.on_sale == 'YES'">
						<label class="">Requiere Numero de Serie</label>
						<select name="has_serial_number" [(ngModel)]="item.has_serial_number" class="form-control">
							<option value="NO">No</option>
							<option value="YES">Si</option>
						</select>
					</div>
					<div class="col-12 col-sm-6 col-md-4" *ngIf="item.on_sale == 'YES'">
						<label class="">Requiere Formulario</label>
						<select name="form_id" [(ngModel)]="item.form_id" class="form-control">
							<option [value]="null">No</option>
							<ng-container *ngFor="let form of form_list">
								<option [value]="form.id">{{form.title}}</option>
							</ng-container>
						</select>
					</div>
				</div>
				<div class="row p-3">
					<div class="col-8 col-lg-10">
						<app-loading [is_loading]="is_loading"></app-loading>
					</div>
					<div class="col-12 text-end">
						<button type="submit" [disabled]="is_loading" class="btn btn-primary">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
							Guardar
						</button>
					</div>
				</div>
			</div>

			<div class="col-12 col-xl-6">
				<h2 class="my-3">Facturación</h2>
				<div class="card">
					<div class="row p-3">
						<div class="col-12 col-sm-6 col-md-4">
							<label class="">IVA</label>
							<select name="applicable_tax" [ngModel]="other_tax" (ngModelChange)="updateTax($event)" class="form-control" required>
								<option value="DEFAULT">Por defecto</option>
								<option value="PORCENTAJE_0">0 %</option>
								<option value="PORCENTAJE_8">8 %</option>
								<option value="PORCENTAJE_16">16 %</option>
								<option value="EXEMPT">Exento</option>
								<!--option value="PERCENT">Porcentual</option para futuras implementacion -->
							</select>
						</div>
						<div class="col-12 col-sm-6 col-md-4">
							<label class="">Tipo IEPS</label>
							<select name="ieps_type" [(ngModel)]="item.ieps_type" class="form-control" required>
								<option value="RATE">Porcentaje</option>
								<option value="AMOUNT">Valor</option>
								<!--option value="PERCENT">Porcentual</option para futuras implementacion -->
							</select>
						</div>
						<div class="col-12 col-sm-6 col-md-4">
							<label class="">Valor IEPS</label>
							<input type="number" name="ieps_value" [(ngModel)]="item.ieps_value" class="form-control" required>
						</div>
						<div class="col-12 col-sm-6">
							<label class="">Clave de Unidad (SAT)*</label>
							<select name="unidad_medida_sat_id" [(ngModel)]="item.unidad_medida_sat_id" class="form-control" required>
								<option [ngValue]="null">Seleccionar</option>
								<option *ngFor="let c of unidad_medida_sat_list" [value]="c.id">{{c.nombre}} - {{c.id}}</option>
							</select>
						</div>
						<div class="col-12 col-sm-6">
							<label class="">Clave Producto o Servicio (SAT)
								<span *ngIf="!item.category_id">*</span>
							</label>
							<input type="text" maxlength="8" name="clave_sat" [(ngModel)]="item.clave_sat" class="form-control" list="producto_o_servicio" [required]="!item.category_id">
							<datalist id="producto_o_servicio">
								<option *ngFor="let ps of productos_servicios_list" [value]="ps.value">{{ps.option}}</option>
							</datalist>
						</div>
					</div>
				</div>
				<h2 class="my-3">Comisiones</h2>
				<div class="card">
					<div class="row p-3">
						<div class="col-12 col-xl-6">
							<label>Tipo de comisión</label>
							<select name="commission_type" [(ngModel)]="item.commission_type" class="form-control">
								<option value="NONE">Ninguna</option>
								<option value="PERCENT">Porcentaje</option>
								<option value="AMOUNT">Cantidad Fija</option>
							</select>
						</div>
						<div class="col-12 col-xl-6">
							<label>comisión</label>
							<input type="number" name="commision" step="any" [(ngModel)]="item.commission" class="form-control">
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-xl-6" *ngIf="item.on_sale == 'YES'">
				<h2 class="my-3">Otros</h2>
				<div class="card p-3">
					<div class="row">
						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label class="">Estilo de Imagen</label>
							<select name="image_style" [(ngModel)]="item.image_style" class="form-control">
								<option value="CONTAIN">Centrada</option>
								<option value="COVER">Expandida</option>
							</select>
						</div>
						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label class="">Texto</label>
							<select name="text_style" [(ngModel)]="item.text_style" class="form-control">
								<option value="NEVER">Sin Texto</option>
								<option value="CENTER">Centrado</option>
								<option value="DOWN">Abajo</option>
							</select>
						</div>

						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label class="">Color titulo sobre imagen</label>
							<input type="color" name="text_color" [(ngModel)]="item.text_color" class="form-control">
						</div>

						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label class="">Color Sombra titulo</label>
							<input type="color" name="shadow_color" [(ngModel)]="item.shadow_color" class="form-control">
						</div>

						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label class="">Orden de prioridad</label>
							<input type="text" name="sort_weight" [(ngModel)]="item.sort_weight" class="form-control" required>
						</div>

						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label>
								<input class="form-check-input mt-0" name="b_transparent" type="checkbox" value="" aria-label="Checkbox for following text input" (click)="updateBackground($event)" [checked]="item.background == 'transparent'">
									Color de Fondo Transparente
							</label>
							<div>
								<input type="color" name="background" [(ngModel)]="item.background" class="form-control w-100" aria-label="Color Fondo" *ngIf="item.background!='transparent'" >
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12" *ngIf="item.on_sale == 'YES'">
				<h2 class="my-3">Ingredientes y excepciones</h2>
				<div class="card">
					<div class="card-header">
						<div class="row">
							<div class="col-10">
								<app-search-items [search_str]="new_exception" (search_strChange)="onSearchString($event)" [reset_on_search]="true" (item_selected)="addNewItemException($event)"></app-search-items>
							</div>
							<div class="col-2 text-end px-0">
								<button type="button" class="btn btn-primary w-100 " (click)="addNewException()">+ Agregar</button>
							</div>
						</div>
					</div>
					<div class="card-body" >
						<div class="row" *ngIf="exceptions.length==0">
							<div class="col-12 text-center">
								Sin excepciones predefinidas
							</div>
						</div>
						<div class="d-none row mt-1 align-items-center d-lg-flex" *ngIf="exceptions.length">
							<div class="col-3 px-1">Artículo</div>
							<div class="col-2 px-1">Alias</div>
							<div class="col-2 px-1">Es excepción En Pedidos</div>
							<div class="col-2 px-1">Cant</div>
							<div class="col-1 px-1"></div>
							<div class="col-2 px-1">Ordenes</div>
						</div>
						<div class="row mb-3 align-items-center border-bottom-primary pb-3" *ngFor="let e of exceptions">
							<div class="col-12 col-lg-3 px-1">
								<div class="fw-bold" *ngIf="e.stock_item">
									<span *ngIf="rest.local_preferences.display_categories_on_items =='YES' && e.stock_category">{{e.stock_category.name |titlecase}} - </span>
									{{e.stock_item.name}}
								</div>
							</div>
							<div class="col-12 col-lg-2 px-1">
								<label class="d-lg-none"></label>
								<input type="text" [name]="e.form_name" [(ngModel)]="e.description" placeholder="Ej: Sin Cebolla" class="form-control">
							</div>
							<div class="col-6 col-lg-2 px-1">
								<label class="d-lg-none">Es excepción</label>
								<select class="form-control" [name]="'list-excpetion'+e.form_name" [(ngModel)]="e.list_as_exception" [disabled]="e.stock_item == null">
									<option value="YES">SI</option>
									<option value="NO">NO</option>
								</select>
							</div>
							<div class="col-6 col-lg-2 px-1">
								<label class="d-lg-none">
									Inventario en {{e.stock_item?.measurement_unit|| 'N/A'}}
									<abbr *ngIf="e.stock_item?.measurement_unit" title="Cantidad a descontar de inventario en {{e.stock_item?.measurement_unit}}" style="cursor:auto!important">
										<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M10 .4C4.697.4.399 4.698.399 10A9.6 9.6 0 0 0 10 19.601c5.301 0 9.6-4.298 9.6-9.601c0-5.302-4.299-9.6-9.6-9.6zm.896 3.466c.936 0 1.211.543 1.211 1.164c0 .775-.62 1.492-1.679 1.492c-.886 0-1.308-.445-1.282-1.182c0-.621.519-1.474 1.75-1.474zM8.498 15.75c-.64 0-1.107-.389-.66-2.094l.733-3.025c.127-.484.148-.678 0-.678c-.191 0-1.022.334-1.512.664l-.319-.523c1.555-1.299 3.343-2.061 4.108-2.061c.64 0 .746.756.427 1.92l-.84 3.18c-.149.562-.085.756.064.756c.192 0 .82-.232 1.438-.719l.362.486c-1.513 1.512-3.162 2.094-3.801 2.094z"/></svg>
									</abbr>
								</label>
								<input type="number" [name]="'stock_qty-'+e.form_name" [(ngModel)]="e.stock_qty" step="any" class="form-control" [disabled]="e.stock_item == null">
							</div>
							<div class="d-none d-lg-flex col-lg-1 px-1">
								<label class="d-none d-md-inline-block">{{e.stock_item?.measurement_unit|| 'N/A'}}</label>
							</div>
							<div class="col-10 col-lg-1 px-1">
								<label class="d-lg-none">Tipo de Orden</label>
								<div>
									<!--b>{{order_type_dic[ e.order_type ]}}</b-->
									<select [name]="'otype'+e.form_name" [(ngModel)]="e.order_type" class="form-control">
										<option value="ALL">Todas</option>
										<option value="TOGO">A Domicilio</option>
										<option value="IN_PLACE">Comer Aqui</option>
										<option value="PICK_UP">Recoger</option>
									</select>

								</div>
							</div>
							<div class="col-2 col-lg-1 px-1 form-group text-end">
								<label class="d-lg-none">&nbsp;</label>
								<div>
									<button type="button" class="btn btn-primary d-inline-block" (click)="removeException(e)">
										<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z" fill="currentColor"/></svg>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="row my-3 align-items-center" *ngIf="item.on_sale == 'YES'">
					<div class="col-12 col-md-4">
						<h2>Opciones</h2>
					</div>
					<div class="col-12 px-0 col-md-8 text-end">
						<button type="button" class="btn btn-secondary mt-1" (click)="addOption()">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M15 2.013H9V9H2v6h7v6.987h6V15h7V9h-7z" fill="currentColor"/></svg>
							Tipo de Opción
						</button>
						<button type="button" class="btn btn-secondary mt-1 ms-1" (click)="show_category_option=true">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M15 2.013H9V9H2v6h7v6.987h6V15h7V9h-7z" fill="currentColor"/></svg>
							Opción Categoría
						</button>
					</div>
				</div>
				<div class="card p-3" *ngIf="item_option_info_list.length==0">Artículo sin opciones</div>
				<ng-container *ngFor="let ioi of item_option_info_list;let io_index = index">
					<div class="card mb-3" *ngIf="item.on_sale == 'YES' && ioi.item_option.status != 'DELETED'">
						<div class="card-header">
							<div class="row">
								<div class="col-2">Nombre</div>
								<div class="col-2">Mínimo de Opciones</div>
								<div class="col-2">Máximo de Opciones</div>
								<div class="col-2">Mínimo de Selecciones</div>
								<div class="col-2">Cantidad máxima de extras</div>
							</div>
							<div class="row">
								<div class="col-2">
									<input type="text" [name]="'item_option_name'+io_index" [(ngModel)]="ioi.item_option.name" class="form-control" required>
								</div>
								<div class="col-2">
									<input type="text" [name]="'item_option_min'+io_index" [(ngModel)]="ioi.item_option.min_options" class="form-control" min="0" required>
								</div>
								<div class="col-2">
									<input type="text" [name]="'item_option_max_options_'+io_index" [(ngModel)]="ioi.item_option.max_options" class="form-control" required>
								</div>
								<div class="col-2">
									<input type="text" [name]="'item_option_min_selections'+io_index" [(ngModel)]="ioi.item_option.min_selections" class="form-control" required>
								</div>
								<div class="col-2">
									<input type="text" [name]="'item_option_max_extra_qty_'+io_index" [(ngModel)]="ioi.item_option.max_extra_qty" class="form-control" required>
								</div>
								<div class="col-12 col-lg-2 text-end my-3 my-md-1">
									<button type="button" class="btn btn-primary mx-1" (click)="removeOption(ioi)">
										<abbr title="Eliminar Opción" style="cursor:auto!important">
											<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z" fill="currentColor"/></svg>
										</abbr>
									</button>
									<button type="button" class="btn btn-primary" (click)="addOptionValue(ioi)">
										<abbr title="Agregar Opción" style="cursor:auto!important">
											<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M15 2.013H9V9H2v6h7v6.987h6V15h7V9h-7z" fill="currentColor"/></svg>
										</abbr>
									</button>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="row d-none d-md-flex">
								<div class="col-4 col-lg-4">
									Artículo <span class="d-none"></span>
								</div>
								<div class="col-2 px-1">Porcion</div>
								<div class="col-2 px-1">Cantidad máxima</div>
								<div class="col-4 px-1">Precio por artículo adicional</div>
							</div>
							<div *ngFor="let iei of ioi.values;let xtra_index = index" class="row my-3 my-md-1 align-items-center">
								<div class="col-12 col-md-4 col-lg-flex">
									<div class="row">
										<div class="col-12 fw-bold">{{iei | itemName}}</div>
										<div class="col-12">
											<app-search-items (item_selected)="onItemOptionValueSelected($event,iei)" [reset_on_search]="false"></app-search-items>
										</div>
									</div>
								</div>
								<div class="col-6 col-md-2 px-">
									<label>
										<span class="d-md-none">Porcion en </span>
										{{iei?.item?.measurement_unit}}
									</label>
									<input type="number" class="form-control" [name]="'portion_value'+io_index+'-'+xtra_index" [(ngModel)]="iei.item_option_value.portion_amount" min="0" [step]="iei?.item?.partial_sale ? 'any' : 1">
								</div>
								<div class="col-6 col-md-2 px-1">
									<label class="d-md-none">Cantidad Maxima</label>
									<input type="number" class="form-control" [name]="'item_option_value_qty'+io_index+'-'+xtra_index" [(ngModel)]="iei.item_option_value.max_extra_qty" min="1" required>
								</div>
								<div class="col-6 col-md-2 px-1">
									<label class="d-md-none">Precio por articulo adicional</label>
									<input type="number" class="form-control" [name]="'item_option_value'+io_index+'-'+xtra_index" [(ngModel)]="iei.item_option_value.extra_price" min="0">
								</div>
								<div class="col-1 px-">
								</div>
								<!--div class="col-2">
									<select [name]="'charge_type'+io_index+'-'+xtra_index" [(ngModel)]="iei.item_option_value.charge_type" class="form-control">
										<option value="OPTIONAL">Opcional</option>
										<option value="INCLUDED">Incluido</option>
										<option value="EXTRA_CHARGE">Precio extra</option>
									</select>
								</div-->
								<div class="col-1 text-end">
									<label></label>
									<button type="button" class="btn btn-primary" (click)="removeItemOptionValueInfo(ioi,iei)">
										<abbr title="Eliminar Valor" style="text-decoration:none;cursor:auto!important">✗</abbr>
									</button>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</form>
</div>
<app-modal [(show)]="show_category_option">
	<form>
		<h2>Agregar opción de categoría</h2>
		<div class="row p-3" style="min-height:500px;">
			<div class="col-6">
				<app-search-category (category)="onOptionCategorySelected($event)"></app-search-category>
			</div>
		</div>
	</form>
</app-modal>

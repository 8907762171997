<div class="px-1 px-md-3 py-3">
	<h1>Cancelaciones</h1>
	<form (ngSubmit)="save($event)" ngNativeValidate>
		<div class="row">
			<div class="col-12 col-md-6">
				<div class="card p-1 p-md-3">
					<h2>Venta</h2>
					<div class="row fw-bold">
						<div class="col-5 col-md-5">Artículo</div>
						<div class="col-2 col-md-2 text-end">Cant.</div>
						<div class="col-3 col-md-2 text-end">Total</div>
						<div class="col-3 col-md-2"></div>
					</div>
					<ng-container *ngIf="order_info">
						<div class="row align-items-center border-top" *ngFor="let oii of order_info.items">
							<div class="col-5 col-md-5">
								<div class="fw-bold">{{oii?.category?.name | titlecase}}</div>
								<div>{{oii.item.name | titlecase }}</div>
							</div>
							<div class="col-2 col-md-2 text-end">{{oii.order_item.qty | number}}</div>
							<div class="col-3 col-md-3 text-end">{{oii.order_item.total | currency:'$'}}</div>
							<div class="col-2 col-md-2 text-end">
								<button type="button" class="btn btn-primary" (click)="addItem(oii)" [disabled]="oii.order_item.type=='REFUND'">
									+
								</button>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="col-12 col-md-6" *ngIf="returns_info">
				<div class="card p-1 p-md-3 my-3">
					<h2>Cancelaciones</h2>
					<div class="row fw-bold">
						<div class="col-6 col-md-6">Artículo</div>
						<div class="col-3 col-md-3">Cant.</div>
						<div class="col-3 col-md-3">Total</div>
					</div>
					<div *ngFor="let rii of returns_info.items;let i = index" class="row align-items-center border-top">
						<div class="col-6">
							<div *ngIf="rii?.category?.name" >{{rii.category.name | titlecase}}</div>
							<div class="fw-bold">{{rii.item.name | titlecase}}</div>
						</div>
						<div class="col-3 col-md-3">
							<input type="number" [name]="'ritem'+rii.returned_item.item_id+'-'+i" class="form-control" [(ngModel)]="rii.returned_item.returned_qty" min="1" [max]="max_qty_by_item_id[rii.returned_item.item_id]" (ngModelChange)="updateTotal()" required>
						</div>
						<div class="col-3 col-md-3">{{rii.returned_item.total | currency:'$'}}</div>
					</div>
				</div>
				<div class="text-end my-3">
					<button type="button" class="btn btn-danger me-1 me-md-3" (click)="cancel()">Cancelar</button>
					<button type="submit" class="btn btn-primary me-1 me-md-3" value="RETURN_MONEY" [disabled]="is_loading">Devolver Dinero</button>
					<button type="submit" class="btn btn-primary me-1 me-md-3" value="RETURN_COUPON" [disabled]="is_loading">Generar Cupon</button>
				</div>
			</div>
		</div>
	</form>
</div>

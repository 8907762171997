import { ItemInfo, ItemOptionInfo, OrderInfo, OrderItemInfo, QuoteInfo, SerialInfo, UserBalance, StocktakeItemInfo, StocktakeInfo, AnswerInfo, ResponseInfo } from "../models/models";
import { Address, Billing_Data, Category, Currency, Item, Item_Exception, Offer, Order, Order_Item, Post, Price, Price_Type, Quote, Serial, Stock_Record, Stocktake, Stocktake_Item, Store, User, User_Attachment, User_Permission, Form, Question, Response, Answer, Requisition, Bank_Movement, FacturaMetaData } from "../models/RestModels";
import { RestService } from "../services/rest.service";
import { Utils } from "./Utils";

export class GetEmpty
{
	static requisition():Requisition
	{
		return {
			id: 0,
			note: '',
			required_by_store_id : 0,
			requested_to_store_id: null,
			approved_status: 'PENDING',
		}
	}
	static response(form_id:number):Response{
		return{
			created: new Date(),
			created_by_user_id: 0,
			form_id: form_id,
			id: 0,
			respondent_identifier: '',
			title: '',
			updated: new Date(),
			updated_by_user_id: 0,
			user_id: null,
		}
	}
	static question():Question {
		return {
			id: 0,
			form_id: 0,
			question: '',
			priority: 1,
			required: 0,
			help: '',
			type: 'text',
			status: 'ACTIVE',
			created: new Date(),
			updated: new Date()
		}
	}
	static answer():Answer
	{
		return{
			id:0,
			answer_choice_id:0,
			answer_number:0,
			answer_text:'',
			question_id:0,
			response_id:0,
		}
	}
	static form(): Form
	{
		return {
			id: 0,
			created: new Date(),
			created_by_user_id: null,
			is_response_title_required: 0,
			responses_allowed: 1,
			updated: new Date(),
			description: '',
			title: '',
			is_active: 1,
			updated_by_user_id: null
		}
	}

	static response_info(form_id:number): ResponseInfo
	{
		return {
			response: GetEmpty.response(form_id),
			form: GetEmpty.form(),
			answers: [] as AnswerInfo[],
			user:null,
		}
	}
	static currency():Currency
	{
		return {
			name: '',
			id: ''
		};
	}

	static client():UserBalance
	{
		let user = GetEmpty.user();
		user.type = 'CLIENT';
		return user;
	}

	static address():Address
	{
		return {
			name: '',
			sat_regimen_capital: '',
			sat_regimen_fiscal: '',
			type: 'SHIPPING',
			address: '',
			zipcode: '',
			email: '',
			rfc: '',
		};
	}

	static user():UserBalance
	{
		return {
			type: 'USER',
			status: 'ACTIVE',
			creation_store_id: 1,
			workshift_id: null,
			birthday: null,
			credit_limit: 0,
			credit_days: 0,
			created_by_user_id: null,
			created_by_store_id: null,
			updated_by_user_id: null,
			default_shipping_address_id: null,
			default_billing_address_id: null,
			email: '',
			id: 0,
			image_id: null,
			lat: null,
			lng: null,
			points: 0,
			password: '',
			phone: '',
			platform_client_id: null,
			username: '',
			name: '',
			payment_option: 'STORE',
			payment_address_id: null,
			price_type_id: 0,
			preferred_store_id: null,
			production_area_id: null,
			store_id: null,
			balance: 0,
			agent_name: '',
			installment_expired_qty: 0,
			installment_expired_amount: 0,
			installment_expired_since: new Date(),
			installment_last_payment: new Date(),
			last_payment_timestamp: new Date(),
			created: new Date(),
			updated: new Date(),
			job_address: '',
			job_name: '',
			job_phone: ''
		};
	}

	static user_attachment():User_Attachment
	{
		return {
			alias: '',
			created: new Date(),
			created_by_user_id: 0,
			id: 0,
			updated: new Date(),
			updated_by_user_id: 0,
			user_id: 0,
			attachment_id: 0,
			status: 'ACTIVE',
		};
	}
	static category(): Category {
		return {
			name: '',
			available_online: 'YES',
			background: 'transparent',
			display_status: 'NORMAL',
			image_style: 'CONTAIN',
			sort_weight: 10,
			text_style:'CENTER',
			text_color: '#FFFFFF',
			shadow_color: '#000000',
			image_id: null
		};
	}

	static bank_movement():Bank_Movement {
		return{
			amount_received: 0,
			bank_account_id: null,
			card_ending: '',
			client_user_id: null,
			created:new Date(),
			currency_id: 'MXN',
			id: null,
			invoice_attachment_id: null,
			note: '',
			origin_bank_name: '',
			paid_date: null,
			payment_id: null,
			provider_user_id: null,
			receipt_attachment_id: null,
			received_by_user_id: null,
			reference: '',
			status: 'ACTIVE',
			exchange_rate: 1,
			total: 0,
			transaction_type: null,
			type:'income',
			updated:new Date()
		}
	}

	static billing_data(): Billing_Data {
		return {
			address: '',
			city: '',
			created: new Date(),
			created_by_user_id: 0,
			id: 0,
			password:'',
			precision: 2,
			porcentaje_ISR: 0.0,
			razon_social:'',
			regimen_capital:'',
			remaining_credits: 9999999,
			regimen_fiscal:'',
			rfc: '',
			state: '',
			updated: new Date(),
			updated_by_user_id: null,
			usuario:'',
			zipcode: '',
		}
	}

	static order_item(item:Item):Order_Item
	{
		let order_item:Order_Item = {
			discount_percent: 0,
			discount: 0,
			id:0,
			item_option_id:null,
			exceptions: '',
			item_id: item.id,
			has_separator:'NO',
			ieps_calculated: 0,
			ieps_type: 'RATE',
			reservation_item_id: null,
			ieps_value: 0,
			qty: 1,
			offer_id: null,
			tax: 0,
			item_group: Date.now(),
			tax_included: 'YES',
			unitary_price_meta: 0,
			original_unitary_price: 0,
			stock_status: 'IN_STOCK',
			unitary_price: 0,
			status: 'ACTIVE',
			type:'NORMAL',
			total: 0,
			subtotal: 0,
			is_free_of_charge: 'NO',
			note: ''
		};
		return order_item;
	}

	static orderItemInfo(item:Item,category:Category | null = null,price:Price | null = null, exceptions:Item_Exception[] = [],prices:Price[] = [])
	{
		//let order_item:Order_Item = {
		//	discount_percent: 0,
		//	exceptions: '',
		//	item_id: item.id,
		//	qty: 1,
		//	tax: 0,
		//	item_group: Date.now(),
		//	tax_included: 'YES',
		//	unitary_price_meta: 0,
		//	original_unitary_price: price?.price ||0,
		//	stock_status: 'IN_STOCK',
		//	unitary_price: price?.price || 0,
		//	type:'NORMAL',
		//	total: price?.price || 0,
		//	is_free_of_charge: 'NO',
		//	note: ''
		//};

		let order_item = GetEmpty.order_item(item);
		order_item.unitary_price = price?.price || 0;
		order_item.total= price?.price || 0;

		let order_item_info:OrderItemInfo =
		{
			order_item,
			item: item,
			category: category,
			exceptions: exceptions,
			records: [],
			prices: prices,
			options: [],
			order_item_exceptions: [],
			created: new Date(),
			serials:[],
			serials_string:'',
			commanda_type_id: 0
		}
		return order_item_info;
	}

	static item_info():ItemInfo
	{
		let item:Item = GetEmpty.item();

		let empty:ItemInfo = {
			item,
			records:[] as Stock_Record[],
			prices:[] as Price[],
			category: null,
			options: [] as ItemOptionInfo[],
			exceptions: [] as Item_Exception[],
			serials:[]
		};

		return empty;
	}

	static orderInfo(rest:RestService, price_type_list:Price_Type[] = [], store_list:Store[] = []):OrderInfo
	{
		let price_type_id:number = (price_type_list?.length ) ? price_type_list[0].id : 1; //Posible error
		let tax_percent = 0;

		let store:Store | undefined = store_list.find(store=>store.id == rest?.current_user?.store_id )

		if( !store )
			store = GetEmpty.store();

		let version_created = rest.getVersion();

		let items:OrderItemInfo[] = [];
		let order:Order = {
			amount_paid: 0,
			ares: 0,
			billing_data_id: store.default_billing_data_id,
			cashier_user_id: rest?.current_user?.id,
			created: new Date(),
			cancellation_timestamp: null,
			closed_timestamp: null,
			currency_id: 'MXN',
			discount: 0,
			delivery_schedule: null,
			discount_calculated: 0,
			price_type_id: price_type_id,
			sat_serie: store.default_sat_serie,
			sat_exchange_rate: 1,
			service_type: 'QUICK_SALE',
			status: 'PENDING',
			cancellation_reason: '',
			cancelled_by_user_id: null,
			paid_status:'PENDING',
			facturacion_timestamp: null,
			period_id: null,
			facturado:'NO',
			guests: 1,
			lat:null,
			lng:null,
			sat_isr: 0,
			sat_ieps: 0,
			sat_forma_pago: '99',
			marked_for_billing:'NO',
			delivery_status: 'PENDING',
			sat_regimen_fiscal_receptor: '',
			sat_regimen_capital_receptor: '',
			sat_domicilio_fiscal_receptor: '',
			store_id: rest?.current_user?.store_id || store.id,
			subtotal: 0,
			sync_id: rest.getSyncId(),
			system_activated: null,
			table_id: null,
			tag:'',
			tax: 0,
			tax_percent:tax_percent,
			total: 0,
			updated: new Date(),
			version_created,
			version_updated: version_created
		};
		let empty:OrderInfo = {
			items,
			order,
			structured_items:[],
			cashier: rest.current_user,
			delivery_user: null,
			client: null,
			store: store,
			purchase: null,
			offers:[]
		};
		return empty;
	}

	static store():Store
	{
		return {
			address:'',
			accept_cash: 1,
			accept_check: 1,
			accept_credit_card: 1,
			accept_transfer: 1,
			accept_delivery_orders: 'NEVER',
			accept_pickup_orders: 'NEVER',
			autofacturacion_enabled: 'NO',
			business_name:'',
			city:'',
			lat: null,
			lng: null,
			client_user_id:null,
			created: new Date(),
			qr_size: 'PERCENT_100',
			created_by_user_id: null,
			default_billing_data_id: null,
			default_currency_id:'MXN',
			default_claveprodserv: '',
			default_sat_item_name: '',
			default_sat_serie: 'A',
			electronic_transfer_percent_fee: 0,
			autofacturacion_settings: 'DISABLED',
			autofacturacion_day_limit: 7,
			main_pc_ip:null,
			modo_facturacion: 'DESGLOSADA',
			id: 0,
			image_id: null,
			name:'',
			offline_search_enabled: 0,
			max_cash_amount:0,
			paypal_email: '',
			phone:'',
			pos_category_preferences:'DEFAULT_BY_PRODUCT',
			price_list_id: 1,
			printer_ticket_config:'',
			print_receipt_copies: 1,
			production_enabled: 0,
			rfc:'',
			sales_enabled: 1,
			show_facturacion_qr: 'NO',
			state:'',
			status:'DISABLED',
			suggested_tip: 0,
			tax_percent: 16,
			ticket_footer_text:'',
			ticket_image_id:null,
			updated: new Date(),
			updated_by_user_id : null,
			zipcode: ''
		}
	}

	static stock_take_item():Stocktake_Item
	{
		return {
			created: new Date(),
			stocktake_id:0,
			item_id: 0,
			db_qty: 0,
			real_qty: 0,
			created_by_user_id: 0,
			updated: new Date(),
			updated_by_user_id: null,
		}
	}

	static stocktake():Stocktake
	{
		return {
			created: new Date(),
			created_by_user_id: 0,
			id: 0,
			status: 'ACTIVE',
			stock_adjustment: 'DIFFERENCE',
			store_id: 0,
			updated: new Date(),
			updated_by_user_id: null,
		}

	}

	static stocktake_item_info():StocktakeItemInfo
	{
		return {
			category: null,
			item: GetEmpty.item(),
			user: GetEmpty.user(),
			stocktake_item: GetEmpty.stock_take_item()
		}
	}

	static stocktake_info():StocktakeInfo
	{
		return {
			stocktake: GetEmpty.stocktake(),
			items: [] as StocktakeItemInfo[],
			store: GetEmpty.store(),
		}
	}

	static quote(rest:RestService):Quote
	{
		return {
			approved_status:'PENDING',
			approved_time: null,
			attachment_id: null,
			client_user_id: null,
			created: new Date(),
			created_by_user_id: null,
			price_type_id: null,
			email:'',
			id: 0,
			name: '',
			note:'',
			phone:'',
			store_id: 0,
			sync_id: rest.getSyncId(),
			tax_percent: 16,
			updated:new Date(),
			valid_until: '',
			currency_id: 'MXN',
		}
	}

	static quoteInfo(rest:RestService):QuoteInfo
	{
		return {
			quote: GetEmpty.quote(rest),
			client_user:null,
			store: GetEmpty.store(),
			items: [],
			order: null,
		}
	}

	static user_permission():User_Permission
	{
		return {
			add_asistance:0,
			add_bills:0,
			add_commandas:0,
			add_credit_sales:0,
			add_items:0,
			add_form:0,
			add_marbetes:0,
			add_payments:0,
			add_payroll:0,
			add_providers:0,
			add_purchases:0,
			add_requisition:0,
			add_stock:0,
			add_user:0,
			advanced_order_search:0,
			approve_bill_payments:0,
			approve_requisition: 0,
			asign_marbetes:0,
			caldos:0,
			cancel_closed_orders:0,
			cancel_ordered_item:0,
			cancelar_factura:0,
			facturar_orden:0,
			factura_global:0,
			reiniciar_facturacion:0,
			view_reservations:0,
			change_client_prices:0,
			created:new Date(),
			created_by_user_id: null,
			currency_rates:0,
			discounts:0,
			edit_billing_data:0,
			fullfill_orders:0,
			global_add_stock:0,
			global_bills:0,
			global_order_delivery:0,
			global_pos:0,
			global_prices:0,
			global_purchases:0,
			global_receive_shipping:0,
			global_requisition:0,
			global_send_shipping:0,
			global_stats:0,
			hades:0,
			is_provider:0,
			open_cashier_box_anytime:0,
			order_delivery:0,
			pay_bills:0,
			pos:0,
			preferences:0,
			price_types:0,
			print_pre_receipt:0,
			production:0,
			purchases:0,
			pv_returns:0,
			quotes:0,
			receive_shipping:0,
			reports:0,
			return_money:0,
			shipping_receive_type: 'CAPTURE_QTY',
			show_tables:0,
			send_shipping:0,
			stocktake:0,
			store_prices:0,
			updated:new Date(),
			updated_by_user_id: null,
			user_id:0,
			view_asistance:0,
			view_commandas:0,
			view_payroll:0,
			view_responses:0,
			view_stock:0,
			view_stock_alerts:0
		};
	}
	static item():Item
	{
		let date = new Date();
		return {
			id:0,
			applicable_tax: 'DEFAULT',
			availability_type: 'ALWAYS',
			response_requirement_qty: 'ONCE_PER_CLIENT',
			background: 'transparent',
			brand_id: null,
			category_id: null,
			clave_sat: null,
			code: null,
			commanda_type_id: null,
			commission: 0,
			commission_currency_id: 'MXN',
			commission_type: 'NONE',
			created: date,
			created_by_user_id: null,
			currency_id: null,
			description: '',
			extra_name: '',
			form_id: null,
			for_reservation: 'NO',
			has_serial_number: 'NO',
			ieps_type:'RATE',
			ieps_value: 0,
			image_id: null,
			image_style:'COVER',
			json_tags:[],
			measurement_unit: null,
			name: '',
			note_required: 'NO',
			on_sale:'YES',
			partial_sale: 'NO',
			period_type: 'MONTHLY',
			product_id: null,
			provider_user_id: null,
			reference_currency_id: 'MXN',
			reference_price: 0,
			return_action: 'RETURN_TO_STOCK',
			tax_percent: 0,
			shadow_color: '#000000',
			sort_weight: 1,
			status:'ACTIVE',
			text_color: '#FFFFFF',
			text_style: 'CENTER',
			unidad_medida_sat_id: 'H87',
			updated: date,
			updated_by_user_id:null,
		};
	}

	static offer():Offer
	{
		let start = new Date();
		let end = new Date();
		end.setFullYear(end.getFullYear()+20);

		return {
			category_id:null,
			coupon_code: null,
			created:new Date(),
			created_by_user_id:0,
			hour_end:'23:59',
			hour_start: '00:00',
			discount: 0,
			description: '',
			gift_item_id: null,
			id:0,
			image_id:null,
			is_cumulative: 'NO',
			is_valid_friday:1,
			is_valid_monday:1,
			is_valid_saturday:1,
			is_valid_sunday:1,
			is_valid_thursday:1,
			is_valid_tuesday:1,
			is_valid_wednesday:1,
			item_id: null,
			m:1,
			n:1,
			name:'',
			percent_qty: null,
			price: null,
			status:'ACTIVE',
			store_id: null,
			tag: null,
			type:'N_X_M',//'PERCENT_DISCOUNT'|'N_X_M'|'AMOUNT_DISCOUNT'|'GIFT',
			updated:new Date(),
			updated_by_user_id:0,
			valid_from: Utils.getLocalMysqlStringFromDate( start ).substring(0,10),
			valid_thru: Utils.getLocalMysqlStringFromDate( end ).substring(0,10),
		};
	}
	static post():Post
	{
		return {
			created: new Date(),
			id: 0,
			title: '',
			post: '',
			images_ids: '',
			created_by_user_id: 0,
			updated_by_user_id: 0,
			updated: new Date(),
		};
	}

	static price_type():Price_Type
	{
		return {
			installments: 0,
			show_bill_code: 'NO',
			tax_model: 'ALL',
			type: 'RETAIL',
			wholesale_min_qty: 0,
			wholesale_type: 'BY_TAG',
			json_tags: [],
		};
	}

	static serial_info():SerialInfo
	{
		let serial:Serial = {
			serial_number: '',
			additional_data: '',
			available_status: 'AVAILABLE',
			description: '',
			last_order_id: null,
			last_reservation_id: null,
			id: 0,
			item_id: 0,
			store_id: 0,
			created_by_user_id: 0,
			updated_by_user_id: 0,
			created: new Date(),
			updated: new Date(),
			status: 'ACTIVE',
		};

		let serial_info:SerialInfo = {
			serial: serial,
			images: [],
			selected:false
		}

		return serial_info;
	}
	static serial(): any
	{
		throw new Error('Method not implemented.');
	}

	static emptyFacturaMetaData():FacturaMetaData
	{
		return {
			Fecha:'',
			total:'',
			noCertificado:'',
			moneda:'',
			lugarExpedicion:'',
			folio:'',
			rfcReceptor:'',
			receptor:'',
			rfcEmisor:'',
			emisor:'',
			UUID:'',
			domicilioReceptor:'',
			fechaTimbrado:'',
		} as FacturaMetaData;
	}
}

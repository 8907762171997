<div class="container-fluid">
	<div class="row align-items-center my-3">
		<div class="col-12 d-flex justify-content-between">
			<h1 class="text-capitalize">Facturación por periodo</h1>
			<button type="button" class="btn btn-secondary" (click)="showFacturar()">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true"
					focusable="false" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet"
					viewBox="0 0 24 24">
					<path _ngcontent-uxx-c124=""
						d="M17 2H7c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 4H7V4h10v2zm3 16H4c-1.1 0-2-.9-2-2v-1h20v1c0 1.1-.9 2-2 2zm-1.47-11.81A2.008 2.008 0 0 0 16.7 9H7.3c-.79 0-1.51.47-1.83 1.19L2 18h20l-3.47-7.81zM9.5 16h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm3 4h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm3 4h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5z"
						fill="currentColor"></path>
				</svg>
				&nbsp;Facturar
			</button>
		</div>
	</div>
	<div class="card my-3 p-3">
		<div class="row">
			<div class="col-4 col-lg-4 text-end">
				<b>Total</b>:<br>
				<span>{{ order_total | number: '1.2-2' }}</span>
			</div>
			<div class="col-4 col-lg-4 text-end">
				<b>IEPS Total</b>:<br>
				<span>{{ ieps_total | number: '1.2-2' }}</span>
			</div>
			<div class="col-4 col-lg-4 text-end">
				<b>IVA Total</b>:<br>
				<span>{{iva_total | number: '1.2-2' }}</span>
			</div>
		</div>
	</div>
	<div class="card my-3 p-3">
		<div class="row">
			<div class="col-4 col-lg-4 text-end">
				<b>Seleccionado</b>:<br>
				<span>{{ total_facturacion | number: '1.2-2' }}</span>
			</div>
			<div class="col-4 col-lg-4 text-end">
				<b>IEPS Seleccionado</b>:<br>
				<span>{{ ieps_seleccionado | number: '1.2-2' }}</span>
			</div>
			<div class="col-4 col-lg-4 text-end">
				<b>IVA Seleccionado</b>:<br>
				<span> ... </span>
			</div>
		</div>
	</div>
	<div class="card my-3 p-3">
		<div class="row">
			<div class="col-6 col-md-3 form-group">
				<label class="">Sucursal</label>
				<div class="fw-bold" *ngIf="!rest.current_permission.global_pos">{{ store_dictionary[
					rest.current_user.store_id ]?.name | titlecase}}</div>
				<select name="store_id" [(ngModel)]="order_search.eq.store_id" class="form-control"
					*ngIf="rest.current_permission.global_pos">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
				</select>
			</div>
			<div class="col-6 col-md-3 form-group">
				<label class="">Precio</label>
				<select name="price_type_id" [(ngModel)]="order_search.eq.price_type_id" class="form-control">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let c of price_type_list" [value]="c.id">{{c.name}}</option>
				</select>
			</div>
			<div class="col-6 col-md-3 form-group">
				<label class="">Metodo de pago</label>
				<select name="transaction_type" [(ngModel)]="order_search.search_extra.transaction_type" class="form-control">
					<option [ngValue]="null">Todas</option>
					<option value="CASH">Efectivo</option>
					<option value="CREDIT_CARD">Tarjeta Credito</option>
					<option value="DEBIT_CARD">Tarjeta Debito</option>
					<option value="CHECK">Cheque</option>
					<option value="COUPON">Coupon</option>
					<option value="TRANSFER">Transferencia</option>
					<option value="DISCOUNT">Descuento</option>
				</select>
			</div>

			<div class="col-6 col-md-3 form-group">
				<label class="">IVA</label>
				<input type="number" name="tax_percent" [(ngModel)]="order_search.eq.tax_percent" step="any" class="form-control">
			</div>

			<div class="col-6 col-md-3 form-group">
				<label class="">Moneda</label>
				<input type="text" name="currency_id" [(ngModel)]="order_search.eq.currency_id" list="currency_list" class="form-control">
				<datalist id="currency_list">
					<option value="MXN">Pesos MXN</option>
					<option value="USD">Dolares</option>
				</datalist>
			</div>

			<div class="col-6 col-md-3 form-group">
				<label class="">Cliente</label>
				<input type="text" name="client_name" [(ngModel)]="order_search.lk.client_name" class="form-control">
			</div>
			<div class="col-6 col-md-3 form-group">
		<label class="">Fecha Inicial</label>
				<input type="datetime-local" name="fecha_inicial" [(ngModel)]="fecha_inicial" class="form-control" (ngModelChange)="onDateChange($event,order_search.ge,'created')">
			</div>
			<div class="col-6 col-md-3 form-group">
				<label class="">Fecha Final</label>
				<input type="datetime-local" name="fecha_final" [(ngModel)]="fecha_final" class="form-control"	(ngModelChange)="onDateChange($event,order_search.le,'created','',59)">
			</div>
			<div class="col-6 col-md-3 form-group">
				<label class="">Solo Publico General</label>
				<div class="form-check form-switch">
					<label class="form-check-label">
						<input type="checkbox" name="publico_general" class="form-check-input" (click)="togglePublicoGeneral($event)" [checked]="order_search.search_extra.publico_general">
					</label>
				</div>
			</div>
			<div class="col-6 col-md-3 form-group">
				<label class="">Cajero:</label>
				<select name="cashier_id" [(ngModel)]="order_search.eq.cashier_user_id" class="form-control">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let c of cashier_user_list" [value]="c.id">{{c.name | titlecase}}</option>
				</select>
			</div>
			<div class="col-6 col-md-3">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="w-100 btn btn-primary" (click)="search(order_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						&nbsp;
						<span>Buscar</span>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="card p-3 my-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive d-none d-lg-block">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th style="width:30px">id</th>
						<th>Cliente</th>
						<th>Atendio</th>
						<th>Sucursal</th>
						<th>Tipo</th>
						<th>Fecha</th>
						<th class="text-center">Pago</th>
						<th class="text-end">Total</th>
						<th class="text-end" >
							Acciones
							<div class="form-check form-switch">
								<label class="form-check-label">
									<input type="checkbox" name="all_checked" class="form-check-input" [checked]="all_checked" (click)="toggleAll()">
								</label>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let oi of order_info_list">
						<td>
							<a *ngIf="oi.order.status != 'CANCELLED' && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos',oi.order.id]">{{oi.order.id}}</a>
							<a *ngIf="oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING'" [routerLink]="['/view-order',oi.order.id]">{{oi.order.id}}</a>
						</td>
						<td>
							<img *ngIf="oi?.client?.image_id" [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:10px">
							<span *ngIf="!oi?.client?.image_id" style="margin-right:10px;">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"/></svg>
							</span>
							<a *ngIf="(oi.order.status == 'ACTIVE' || oi.order.status=='PENDING' ) && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos',oi.order.id]">{{(oi?.client?.name || oi.order.client_name ) | uppercase}}</a>
							<a *ngIf="oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING'" [routerLink]="['/view-order',oi.order.id]">{{(oi?.client?.name || oi.order.client_name ) | uppercase}}</a>
						</td>
						<td>{{oi?.cashier?.name | titlecase }}</td>
						<td>{{oi?.store?.name | titlecase}}</td>
						<td>
							<span *ngIf="oi.order.service_type == 'TOGO'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M3 4a2 2 0 0 0-2 2v11h2a3 3 0 0 0 3 3a3 3 0 0 0 3-3h6a3 3 0 0 0 3 3a3 3 0 0 0 3-3h2v-5l-3-4h-3V4m-7 2l4 4l-4 4v-3H4V9h6m7 .5h2.5l1.97 2.5H17M6 15.5A1.5 1.5 0 0 1 7.5 17A1.5 1.5 0 0 1 6 18.5A1.5 1.5 0 0 1 4.5 17A1.5 1.5 0 0 1 6 15.5m12 0a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5z" fill="currentColor"/></svg>
							</span>
							<span *ngIf="oi.order.service_type == 'PICK_UP'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5z"/></g></svg>
							</span>
							<span *ngIf="oi.order.service_type == 'IN_PLACE'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M21.96 9.73l-1.43-5a.996.996 0 0 0-.96-.73H4.43c-.45 0-.84.3-.96.73l-1.43 5c-.18.63.3 1.27.96 1.27h2.2l-1.05 7.88a.989.989 0 1 0 1.96.26L6.67 15h10.67l.55 4.14a1 1 0 0 0 .98.86c.6 0 1.06-.53.98-1.12L18.8 11H21c.66 0 1.14-.64.96-1.27zM6.93 13l.27-2h9.6l.27 2H6.93z" fill="currentColor"/></svg>
							</span>
							{{price_type_dic[ oi?.order.price_type_id ].name | titlecase}}
						</td>
						<td>{{oi.order.created | date: 'MMM d, y HH:mm' }}</td>
						<td class="text-center">

							<span *ngIf="oi.paid_cash" class="text-success">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1em"
									preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512" style="margin-right: 5px;">
									<path fill="currentColor"
										d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 192c-53.02 0-96-50.15-96-112c0-61.86 42.98-112 96-112s96 50.14 96 112c0 61.87-43 112-96 112zm272 32h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z" />
								</svg>
							</span>
							<span *ngIf="oi.paid_check" class="text-warning">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.13em" height="1em"
									preserveAspectRatio="xMidYMid meet" viewBox="0 0 576 512" style="margin-right: 5px;">
									<path fill="currentColor"
										d="M64 64C28.7 64 0 92.7 0 128v256c0 35.3 28.7 64 64 64h448c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm208 128h224c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zm-16 112c0-8.8 7.2-16 16-16h224c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zm-91.9-144v6.3c6.6 1.2 16.6 3.2 21 4.4c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-3.8-1-17.4-3.7-21.7-4.3c-12.2-1.9-22.2-.3-28.6 2.6c-6.3 2.9-7.9 6.2-8.2 8.1c-.6 3.4 0 4.7.1 5c.3.5 1 1.8 3.6 3.5c6.1 4.2 15.7 7.2 29.9 11.4l.8.2c12.1 3.7 28.3 8.5 40.4 17.4c6.7 4.9 13 11.4 16.9 20.5c4 9.1 4.8 19.1 3 29.4c-3.3 19-15.9 32-31.6 38.7c-4.9 2.1-10 3.6-15.4 4.6v5.5c0 11.1-9 20.1-20.1 20.1s-20.1-9-20.1-20.1v-6.4c-9.5-2.2-21.9-6.4-29.8-9.1c-1.7-.6-3.2-1.1-4.4-1.5c-10.5-3.5-16.1-14.8-12.7-25.3s14.8-16.1 25.3-12.7c2 .7 4.1 1.4 6.4 2.1c9.5 3.2 20.2 6.9 26.2 7.9c12.8 2 22.7.7 28.8-1.9c5.5-2.3 7.4-5.3 8-8.8c.7-4 .1-5.9-.2-6.7c-.4-.9-1.3-2.2-3.7-4c-5.9-4.3-15.3-7.5-29.3-11.7l-2.2-.7c-11.7-3.5-27-8.1-38.6-16c-6.6-4.5-13.2-10.7-17.3-19.5c-4.2-9-5.2-18.8-3.4-29c3.2-18.3 16.2-30.9 31.1-37.7c5-2.3 10.3-4 15.9-5.1v-6c0-11.1 9-20.1 20.1-20.1s20.1 9 20.1 20.1z" />
								</svg>
							</span>
							<span *ngIf="oi.paid_card" class="text-danger">
								<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
									preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16" style="margin-right: 5px;">
									<path fill="currentColor"
										d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z" />
								</svg>
							</span>

							<span *ngIf="oi.paid_transfer" class="text-danger">
								<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
									preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" style="margin-right: 5px;">
									<g fill="none">
										<path d="M0 0h24v24H0z" />
										<path fill="currentColor"
											d="M8.56 11.9a1.5 1.5 0 0 1 0 2.12l-.974.976H16a1.5 1.5 0 0 1 0 3H7.586l.975.974a1.5 1.5 0 1 1-2.122 2.122l-3.535-3.536a1.5 1.5 0 0 1 0-2.121l3.535-3.536a1.5 1.5 0 0 1 2.122 0Zm6.88-9a1.5 1.5 0 0 1 2.007-.104l.114.103l3.535 3.536a1.5 1.5 0 0 1 .103 2.007l-.103.114l-3.535 3.536a1.5 1.5 0 0 1-2.225-2.008l.103-.114l.975-.974H8a1.5 1.5 0 0 1-.144-2.994L8 5.996h8.414l-.975-.975a1.5 1.5 0 0 1 0-2.122Z" />
									</g>
								</svg>
							</span>

						</td>
						<td class="text-end">
							{{(oi.order.total - oi.order.discount) | currency:'$' }} {{oi.order.currency_id}}</td>
						<td class="text-end">
							<div class="form-check form-switch">
								<label class="form-check-label">
									<input type="checkbox" [name]="'selected_order_'+oi.order.id" class="form-check-input" [checked]="oi.checked" (click)="clickedOnOrder(oi)"	[disabled]="oi.disabled">
								</label>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>


		<div class="row mb-3 border-bottom pb-2 d-lg-none" *ngFor="let oi of order_info_list">
			<div class="col-9">
				<a [routerLink]="['/view-order',oi.order.id]">
					{{oi.order.id}} -
					<img [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)"
						style="max-width:30px;max-height:30px;margin-right:20px">
					{{oi.order.client_name}}
				</a>
			</div>
			<div class="col-3 px-0 text-end align-self-center">{{oi?.order?.total | currency:'$'}}</div>
			<div class="col-6 col-sm-4 align-self-center">
				<span *ngIf="oi?.order?.delivery_status == 'PENDING'">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/></g></svg>
				</span>
				{{oi?.store?.name | titlecase}} {{(oi?.order?.delivery_status == 'DELIVERED') ? '✓':'' | titlecase}}
			</div>
			<div class="col-6 col-sm-2 col-md-2 align-self-center">{{oi.order.created | date: 'MMM d' }}</div>
			<div class="col-12 col-sm-6 col-md-6 text-end px-0">
				<a *ngIf="oi.order.status != 'CANCELLED' " [routerLink]="['/edit-order',oi.order.id]" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path class="clr-i-solid clr-i-solid-path-1" d="M4.22 23.2l-1.9 8.2a2.06 2.06 0 0 0 2 2.5a2.14 2.14 0 0 0 .43 0L13 32l15.84-15.78L20 7.4z" fill="currentColor"/><path class="clr-i-solid clr-i-solid-path-2" d="M33.82 8.32l-5.9-5.9a2.07 2.07 0 0 0-2.92 0L21.72 5.7l8.83 8.83l3.28-3.28a2.07 2.07 0 0 0-.01-2.93z" fill="currentColor"/></svg>
				</a>
				<!-- button type="button" *ngIf="oi.order.status !== 'CANCELLED' && oi.order.delivery_status == 'PENDING'" (click)="cancelOrder(oi)" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59z"	fill="currentColor"/></svg>
				</button -->
				<a *ngIf="oi.order.status !== 'CANCELLED'" [routerLink]="['/print-receipt',this.rest.local_preferences.default_print_receipt,oi.order.id]" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100"><path d="M90.443 35.141a1.825 1.825 0 0 0-1.818-1.708H75.511V11.857c0-1.012-.819-1.83-1.83-1.83H26.319c-1.011 0-1.83.818-1.83 1.83v21.576H11.377h-.002c-.969 0-1.754.755-1.818 1.708h-.012V71.91a1.83 1.83 0 0 0 1.83 1.829h13.114V58.425H75.51v15.314h13.114a1.83 1.83 0 0 0 1.83-1.829V35.141h-.011zm-19.919 6.49H29.476V16.844c0-1.012.819-1.83 1.83-1.83h37.387c1.011 0 1.83.818 1.83 1.83v24.787z" fill="currentColor"/><path d="M29.602 88.143c0 1.012.819 1.83 1.83 1.83h37.136c1.011 0 1.83-.818 1.83-1.83v-24.64H29.602v24.64z" fill="currentColor"/></svg>
				</a>
				<a *ngIf="oi.order?.sat_pdf_attachment_id" [href]="rest.getFilePath(oi.order.sat_pdf_attachment_id,true)" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.75em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 512"><path d="M181.9 256.1c-5-16-4.9-46.9-2-46.9c8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7c18.3-7 39-17.2 62.9-21.9c-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2c-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8c4.5-18.5 11.6-46.6 6.2-64.2c-4.7-29.4-42.4-26.5-47.8-6.8c-5 18.3-.4 44.1 8.1 77c-11.6 27.6-28.7 64.6-40.8 85.8c-.1 0-.1.1-.2.1c-27.1 13.9-73.6 44.5-54.5 68c5.6 6.9 16 10 21.5 10c17.9 0 35.7-18 61.1-61.8c25.8-8.5 54.1-19.1 79-23.2c21.7 11.8 47.1 19.5 64 19.5c29.2 0 31.2-32 19.7-43.4c-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9c37.1 15.8 42.8 9 42.8 9z" fill="currentColor"/></svg>
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-12" *ngIf="order_info_list == null || order_info_list.length == 0 && !is_loading">
				<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
			</div>
		</div>
	</div>
	<app-pagination [path]="'/list-order'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>

<app-modal [(show)]="show_asign_delivery_user" *ngIf="selected_order_info">
	<app-select-delivery-user (onUserSelected)="onDeliveryUserSelected($event)"
		[order_id]="selected_order_info.order.id" [delivery_store_id]="selected_order_info.order.store_id">
	</app-select-delivery-user>
</app-modal>

<app-modal [show]="show_facturar_periodo">
	<div class="p-3">
		<h2>Facturar Periodo</h2>
		<div><app-loading [is_loading]="is_loading"></app-loading></div>
		<form (submit)="facturarPeriodo($event)">
			<div class="row align-items-center my-1">
				<div class="col-6">Datos de facturación</div>
				<div class="col-6">
					<select name="tax_percent" [(ngModel)]="billing_data_id" class="form-control" required>
						<option value="">Seleccionar</option>
						<option *ngFor="let c of billing_data_list" [value]="c.id">{{c.usuario}} -- {{c.rfc}}</option>
					</select>
				</div>
			</div>
			<div class="row align-items-center my-1">
				<div class="col-6">Serie</div>
				<div class="col-6">
					<input type="text" name="sat_serie" [(ngModel)]="sat_serie" class="form-control" required>
				</div>
			</div>
			<div class="col-12 col-md-6 form-group">
				<label>Forma de pago</label>
				<select class="form-control" name="forma_de_pago" [(ngModel)]="sat_forma_de_pago">
					<option value="">No Definir</option>
					<option value="99">Por Definir</option>
					<option value="01">Efectivo</option>
					<option value="02">Cheque nominativo</option>
					<option value="03">Transferencia electrónica de fondos</option>
					<option value="04">Tarjeta de crédito</option>
					<option value="05">Monedero electrónico</option>
					<option value="06">Dinero electrónico</option>
					<option value="08">Vales de despensa</option>
					<option value="12">Dación en pago</option>
					<option value="13">Pago por subrogación</option>
					<option value="14">Pago por consignación</option>
					<option value="15">Condonación</option>
					<option value="17">Compensación</option>
					<option value="23">Novación</option>
					<option value="24">Confusión</option>
					<option value="25">Remisión de deuda</option>
					<option value="26">Prescripción o caducidad</option>
					<option value="27">A satisfacción del acreedor</option>
					<option value="28">Tarjeta de débito</option>
					<option value="29">Tarjeta de servicios</option>
					<option value="30">Aplicación de anticipos</option>
					<option value="31">Intermediario pagos</option>
				</select>
			</div>

			<div class="row align-items-center my-1">
				<div class="col-6">Periocidad</div>
				<div class="col-6">
					<select name="codigo_periocidad" [(ngModel)]="codigo_periocidad" class="form-control" required>
						<option value="">Seleccionar</option>
						<option value="01">Diario</option>
						<option value="02">Semanal</option>
						<option value="03">Quincenal</option>
						<option value="04">Mensual</option>
						<option value="05">Bimestral</option>
					</select>
				</div>
			</div>
			<div class="row align-items-center my-1">
				<div class="col-6">Mes</div>
				<div class="col-6">
					<select name="codigo_mes" [(ngModel)]="codigo_mes" class="form-control" required>
						<option value="">Seleccionar</option>
						<option value="01">Enero</option>
						<option value="02">Febrero</option>
						<option value="03">Marzo</option>
						<option value="04">Abril</option>
						<option value="05">Mayo</option>
						<option value="06">Junio</option>
						<option value="07">Julio</option>
						<option value="08">Agosto</option>
						<option value="09">Septiembre</option>
						<option value="10">Octubre</option>
						<option value="11">Noviembre</option>
						<option value="12">Diciembre</option>
						<option value="13">Enero - Febrero</option>
						<option value="14">Marzo - Abril</option>
						<option value="15">Mayo - Junio</option>
						<option value="16">Julio - Agosto</option>
						<option value="17">Septiembre - Octubre</option>
						<option value="18">Noviembre - Diciembre</option>
					</select>
				</div>
			</div>
			<div class="row align-items-center my-1">
				<div class="col-6">Año</div>
				<div class="col-6">
					<select name="anio" [(ngModel)]="anio" class="form-control" required>
						<option value="">Seleccionar</option>
						<option *ngFor="let y of years" [value]="y">{{y}}</option>
					</select>
				</div>
			</div>
			<div class="row align-items-center my-1">
				<div class="col-6">
					<button class="btn btn-primary" [disabled]="is_loading" (click)="show_facturar_periodo=false">
						Cerrar Ventana
					</button>
				</div>
				<div class="col-6">
					<button class="btn btn-primary" type="submit" [disabled]="is_loading">Facturar</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>

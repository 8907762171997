<div class="container-fluid">
	<h4 class="my-3">Editar Precios</h4>
	<div class="card p-3" *ngIf="item_info">
		<div class="py-3">

			<img [src]="rest.getImagePath(item_info.item.image_id,item_info?.category?.image_id,rest.local_preferences.default_product_image_id)" style="max-height:30px;max-width:30px;margin-right:10px">
			<ng-container *ngIf="item_info?.category && rest.local_preferences.display_categories_on_items == 'YES'">
				{{item_info.category.name}} -
			</ng-container>
			{{item_info?.category?.name}} -

      <a *NgIf="item_info && rest.current_permission.add_items; else onlyName" [routerLink]="['/edit-item',item_info.item.id]" class="btn btn-primary">
        {{item_info | itemName}}
      </a>
      <ng-container else #onlyName>
        {{item_info | itemName}}
      </ng-container>

		</div>
		<app-loading [is_loading]="is_loading"></app-loading>
		<!--form (submit)="save($event)"-->
			<div>
				<table class="table" border=1>
					<tbody *ngIf="item_info">
						<ng-container *ngFor="let sp of super_price_list">
							<ng-container *ngFor="let price of sp.prices;let i=index">
								<tr>
									<th *ngIf="i==0" [rowSpan]="sp.price_types.length" class="border">
										{{sp.price_list.name}}
									</th>
									<td class="align-middle">{{sp.price_types[i].name | titlecase}}</td>
									<td class="input-group">
										<input *ngIf="sp.price_types[i].model == 'AMOUNT'" type="number" class="form-control" [name]="'pprice_'+item_info.item.id+'-'+sp.price_types[i].id" [ngModel]="price.price" (ngModelChange)="priceChange($event,price)" min="0" step="any" required>
										<span *ngIf="sp.price_types[i].model == 'AMOUNT'" class="input-group-text">$</span>
										<input *ngIf="sp.price_types[i].model == 'PERCENT'" type="number" class="form-control" [name]="'ppercent'+item_info.item.id+'-'+sp.price_types[i].id" [ngModel]="price.percent" (ngModelChange)="percentChange($event,price,item_info)" min="0" step="any" required>
										<span *ngIf="sp.price_types[i].model == 'PERCENT'" class="input-group-text">%</span>
									</td>
									<td class="align-middle">
										<select *ngIf="sp.price_types[i].tax_model=='ALL'" [name]="'tax-'+item_info.item.id+'-'+sp.price_types[i].id" [ngModel]="price.tax_included" (ngModelChange)="taxModelChange($event,price)" class="form-control" required>
											<option value="YES">IVA Incluido</option>
											<option value="NO">Más IVA</option>
										</select>
										<span *ngIf="sp.price_types[i].tax_model != 'ALL'">
											{{sp.price_types[i].tax_model=='PLUS_TAX'?'Mas IVA':'IVA Incluido'}}
										</span>
									</td>
									<td>
										{{sp.price_types[i].model == 'PERCENT'
										? (item_info.item.reference_price * (1+(price.percent/100)) | currency )
										:''}}
									</td>
									<td *ngIf="rest.local_preferences.currency_price_preference == 'MULTIPLE_CURRENCY'">
										<!-- <input type="text" class="form-control" min="0" step="any" list="tipo_precio" minlength="3" maxlength="3" [name]="'currr'+item_info.item.id+'-'+sp.price_types[i].id" [ngModel]="price.currency_id" (ngModelChange)="currencyChange($event,price)" placeholder="moneda" required> -->
										<select [name]="'currr'+item_info.item.id+'-'+sp.price_types[i].id" [ngModel]="price.currency_id" (ngModelChange)="currencyChange($event,price)" class="form-control" required>
											<option *ngFor="let currency of currency_list" [value]="currency.id">{{currency.id + ' - ' + currency.name | uppercase}}</option>
										</select>
									</td>
								</tr>
							</ng-container>
						</ng-container>
					</tbody>
				</table>
			</div>
			<div class="text-end">
				<button type="submit" class="btn btn-primary" [disabled]="is_loading">Guardar</button>
			</div>
			<datalist id="tipo_precio">
				<option value="MXN">Pesos MXN</option>
				<option value="USD">Dolares USD</option>
			</datalist>
		<!--form-->
	</div>
</div>

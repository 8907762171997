import { Component, OnInit } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import { forkJoin } from 'rxjs';
import { Requisition } from '../../models/RestModels';
import { Store } from '../../models/RestModels';
import { RequisitionInfo } from 'src/app/models/models';
import { Utils } from 'src/app/classes/Utils';
import { filter, mergeMap } from 'rxjs/operators';


@Component({
	selector: 'app-list-requisition',
	templateUrl: './list-requisition.component.html',
	styleUrls: ['./list-requisition.component.css']
})

export class ListRequisitionComponent extends BaseComponent implements OnInit
{


	requisition_search:SearchObject<Requisition> = this.getEmptySearch();
	requisition_info_list:RequisitionInfo[] = [];
	type:'requisition'|'store' = 'requisition';
	store_list:Store[] = [];
	status_string = {
		'PENDING':'Pendiente',
		'SHIPPED':'Enviado',
		'CANCELLED':'Cancelado',
		'NOT_APPROVED':'No aprobado',
		'CLOSED':'Cerrada',
		'APPROVED':'Aprobado',
	};

	selected_requisition_info: RequisitionInfo | null = null;
	show_clone_requisition: boolean = false;

	ngOnInit()
	{
		this.path = '/list-requisition';

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			this.type = this.route.snapshot.paramMap.get('type') as ('requisition' | 'store');
			this.path = '/list-requisition/' + this.type;

			let fields = [ "id","status","required_by_store_id","requested_to_store_id","date","created_by_user_id","updated_by_user_id","created","updated" ];
			this.requisition_search = this.getSearch(queryParamMap, fields );
			this.requisition_search.sort_order = ['id_DESC'];
			this.setTitle('Requisiciones');
			this.is_loading = true;
			this.current_page = this.requisition_search.page;

			this.is_loading = true;
			this.subs.sink = forkJoin
			({
				requisition : this.rest.requisition_info.search(this.requisition_search),
				store : this.rest.getStores(true),
			})
			.subscribe((responses)=>
			{
				this.requisition_info_list = responses.requisition.data;
				this.setPages( this.requisition_search.page, responses.requisition.total );
				this.store_list = responses.store.data;
				this.is_loading = false;
			},(error)=>this.showError(error));
		});
	}

	showCancelar(ri:RequisitionInfo)
	{
		this.subs.sink = this.confirmation
		.showConfirmAlert(ri, 'Cancelar Requisicion', 'Esta seguro de querer cancelar la requisicion')
		.subscribe((r)=>
		{
			if( r.accepted )
			{
				let prev = ri.requisition.status;
				ri.requisition.status = 'CANCELLED';
				ri.requisition.approved_status = 'NOT_APPROVED';
				ri.requisition.required_by_timestamp = Utils.getUTCMysqlStringFromDate(new Date(ri.requisition.required_by_timestamp));
				this.subs.sink = this.rest.requisition_info
				.update( ri )
				.subscribe(()=>
				{
					this.showSuccess('Se canceló con éxito');
				},(error)=>
				{
					ri.requisition.status = prev;
					this.showError(error);
				});
			}
		});
	}

	showClone(ri:RequisitionInfo)
	{
		this.selected_requisition_info = ri;
		this.show_clone_requisition = true;
	}

	cloneRequistion(evt: Event)
	{
		evt.preventDefault();
		evt.stopPropagation();
	}

	confirmCopy(ri: RequisitionInfo)
	{
		this.subs.sink = this.confirmation
		.showConfirmAlert(ri,'Copiar Requisición','Esta seguro de querer copiar la requisición?')
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				let ri_copy:RequisitionInfo = {
					requisition: { ...ri.requisition, created_by_user_id: this.rest.current_user.id },
					items: ri.items.map((rii)=>
					{
						let rii_item = {requisition_item : rii.requisition_item }
						rii_item.requisition_item.id = 0;

						return {...rii, irequisition_item: rii.requisition_item};
					})
				};

				ri_copy.requisition.id = 0;
				ri_copy.requisition.status = 'PENDING';
				ri_copy.requisition.approved_status = 'PENDING';
				let date = new Date();
				date.setHours(0,0,0,0);
				ri_copy.requisition.required_by_timestamp = Utils.getUTCMysqlStringFromDate(date);

				this.subs.sink = this.rest.requisition_info.create( ri_copy )
				.subscribe((response)=>
				{
					this.showSuccess('La requisicion se copio exitosamente');
					this.router.navigate(['/edit-requisition',response.requisition.id]);
				});
			}
		});
	}

	confirmApproved(ri: RequisitionInfo)
	{
		this.subs.sink = this.confirmation
		.showConfirmAlert(ri,'Aprobar Requisición','Esta seguro de querer aprobar la requisición?')
		.pipe
		(
			filter((response)=>response.accepted),
			mergeMap((_response)=>
			{
				ri.requisition.approved_status = 'APPROVED';
				ri.requisition.required_by_timestamp = Utils.getUTCMysqlStringFromDate(new Date(ri.requisition.required_by_timestamp));
				return this.rest.requisition_info.update( ri )
			})
		)
		.subscribe
		({
			next:()=>
			{
				this.rest.sendNotification('requisition', ri.requisition.id );
				this.showSuccess('Se aprobó con éxito');
			},
			error:(error)=>
			{
				ri.requisition.approved_status = 'PENDING';
				this.showError(error);
			}
		});
	}
}
